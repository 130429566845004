import { fetchCities } from 'application/store/reducers/Statistics/ActionCreators';
import { SupportedCountriesDto } from '../types/countries';
import { useAppDispatch, useAppSelector } from './redux';
import { useMemo } from 'react';

export const useStatisticFilters = () => {
  const dispatch = useAppDispatch();

  const { cities } = useAppSelector((state) => state.statistics);
  const validCities = useMemo(
    () => cities.filter((city) => !!city.city),
    [cities],
  );

  const fetchCitiesByCounties = async (
    supportedCountries: SupportedCountriesDto[],
  ) => {
    const searchParams = {
      ...(supportedCountries.length
        ? {
            supportedCountriesIso: supportedCountries.map((item) => item.code),
          }
        : {}),
    };

    dispatch(fetchCities(searchParams));
  };
  return {
    fetchCities: fetchCitiesByCounties,
    cities: validCities,
  };
};
