import styled from 'styled-components';

export const ActionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 4px;

  > svg {
    width: 24px;
    height: 24px;
  }
  .reset {
    color: ${({ theme }) => theme.color.blue800};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;
    cursor: pointer;
  }

  .sent {
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;
    cursor: default;
  }
`;
