import { createSlice } from '@reduxjs/toolkit';
import { CountriesState } from './models';
import { getAllCountries, getSupportedCountries } from './ActionCreators';

const initialState: CountriesState = {
  supportedCountries: [],
  allCountries: [],
  isLoading: false,
  error: '',
};

const countriesSlice = createSlice({
  name: 'countriesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSupportedCountries.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSupportedCountries.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getSupportedCountries.fulfilled, (state, action) => {
      state.supportedCountries = action.payload.entities;
      state.isLoading = false;
    });
    builder.addCase(getAllCountries.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCountries.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getAllCountries.fulfilled, (state, action) => {
      state.allCountries = action.payload.entities;
      state.isLoading = false;
    });
  },
});

export default countriesSlice.reducer;
