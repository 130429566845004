import styled from 'styled-components';

export const StyledTr = styled.tr`
  .firstCol {
    font-weight: 700;
    margin-bottom: 2px;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    padding: 10px;
    border: none;
    background: none;
    cursor: pointer;

    > svg {
      width: 24px;
      height: 24px;

      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }

    &:last-child {
      margin-left: 12px;

      > svg {
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }
  }
`;
