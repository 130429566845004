import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { ReactComponent as SuccessIcon } from 'application/assets/success-icon.svg';
import { SuccessModalProps } from './models';
import { StyledModal } from './styles';

const SuccessModal: FC<SuccessModalProps> = ({ opened, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open={opened}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccessIcon />
          <p>{t('auth.change_password.changed_success')}</p>
          <SecondaryButton onClick={onClose}>{t('global.ok')}</SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessModal;
