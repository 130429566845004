import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const useCreateDoctorSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const emailError = t('errors.emailFormat');

  return yup.object({
    firstName: yup.string().required(requiredError),
    lastName: yup.string().required(requiredError),
    supportedCountry: yup.object().shape({
      id: yup.string(),
      name: yup.string(),
      code: yup.string(),
    }),
    phones: yup
      .array()
      .of(
        yup
          .string()
          .test({
            name: 'is-valid',
            test(value, ctx) {
              const isValidNumber = value?.includes('+')
                ? isValidPhoneNumber(value)
                : isValidPhoneNumber(`+${String(value)}`);

              if (!isValidNumber && value)
                return ctx.createError({
                  message: t('global_error.invalid_phone_number'),
                });
              return true;
            },
          })
          .required(requiredError),
      )
      .required(requiredError),
    emails: yup
      .array()
      .of(yup.string().email(emailError).required(requiredError)),
    specializationIds: yup.array().of(yup.string().nullable()),
    clinics: yup.array().of(
      yup.object({
        name: yup.string().required(requiredError),
        phones: yup
          .array()
          .of(
            yup
              .string()
              .test({
                name: 'is-valid',
                test(value, ctx) {
                  const isValidNumber = value?.includes('+')
                    ? isValidPhoneNumber(value)
                    : isValidPhoneNumber(`+${String(value)}`);

                  if (!isValidNumber && value) {
                    return ctx.createError({
                      message: t('global_error.invalid_phone_number'),
                    });
                  }

                  return true;
                },
              })
              .nullable(),
          )
          .nullable(),
        emails: yup
          .array()
          .of(yup.string().email(emailError).required(requiredError)),
        region: yup.string().nullable(),
        city: yup.string().nullable(),
        street: yup.string().nullable(),
        latitude: yup.string().nullable(),
        longitude: yup.string().nullable(),
        supportedCountry: yup.object().shape({
          id: yup.string(),
          name: yup.string(),
          code: yup.string(),
        }),
      }),
    ),
  });
};
