import styled from 'styled-components';

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    width: 200px;
    max-width: 220px;

    &.actions {
      width: auto;
      max-width: unset;

      > div {
        display: flex;
      }
    }

    &:first-child {
      width: auto;
    }
    &:last-child {
      width: 150px;
    }

    a {
      display: block;
      color: ${({ theme }) => theme.color.blue800};
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.08px;
      cursor: pointer;
      text-decoration: none;
      margin-left: 12px;
      white-space: nowrap;
    }
  }
`;

export const ProfessionalNameStyled = styled.div`
  display: grid;
  gap: 2px;
  margin: 0 12px;

  > p {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }
  > span {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    padding: 10px;
    border: none;
    background: none;
    cursor: pointer;

    > svg {
      width: 24px;
      height: 24px;

      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }

    &:last-child {
      margin-left: 12px;

      > svg {
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }
  }
`;
