import React from 'react';
import AuthFooter from './AuthFooter';

import {
  LoginContentWrapper,
  LoginPageContentWrapper,
  LoginPageWrapper,
} from './styles';
export const AuthPageWrapper = ({ children }: { children: JSX.Element }) => {
  return (
    <LoginPageWrapper>
      <LoginContentWrapper>
        <LoginPageContentWrapper>{children}</LoginPageContentWrapper>
      </LoginContentWrapper>
      <AuthFooter />
    </LoginPageWrapper>
  );
};
