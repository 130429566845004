import { createAsyncThunk } from '@reduxjs/toolkit';
import { MetricsActions } from 'application/constants/actions';
import { AxiosError } from 'axios';
import { MetricsAPI } from 'integration/api/metrics';
import {
  HealthMetricCreateRequest,
  HealthMetricUpdateRequest,
  UnitOfMeasurementCreateRequest,
} from '@docbay/schemas';

export const fetchMetrics = createAsyncThunk(
  MetricsActions.fetchMetrics,
  async (
    params: { page: number; limit: number; specializationIds?: string },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await MetricsAPI.fetchMetrics(params);

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchSpecializations = createAsyncThunk(
  MetricsActions.fetchSpecializations,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await MetricsAPI.fetchSpecializations();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchUnitsOfMeasurement = createAsyncThunk(
  MetricsActions.fetchUnitsOfMeasurement,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await MetricsAPI.fetchUnitsOfMeasurement();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createUnitOfMeasurement = createAsyncThunk(
  MetricsActions.createUnitOfMeasurement,
  async (params: UnitOfMeasurementCreateRequest, { rejectWithValue }) => {
    try {
      const { data } = await MetricsAPI.createUnitOfMeasurement(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteUnitOfMeasurement = createAsyncThunk(
  MetricsActions.deleteUnitOfMeasurement,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await MetricsAPI.deleteUnitOfMeasurement(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      const rejectPayload = {
        statusCode: error?.response?.status,
        message: error.message,
      };

      return rejectWithValue(rejectPayload);
    }
  },
);

export const createMetric = createAsyncThunk(
  MetricsActions.createMetric,
  async (params: HealthMetricCreateRequest, { rejectWithValue }) => {
    try {
      const { data } = await MetricsAPI.createMetric(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteMetric = createAsyncThunk(
  MetricsActions.deleteMetric,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await MetricsAPI.deleteMetric(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const editMetric = createAsyncThunk(
  MetricsActions.editMetric,
  async (
    params: { id: string; data: HealthMetricUpdateRequest },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await MetricsAPI.editMetric(params.id, params.data);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
