import styled from 'styled-components';

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

    width: 200px;
    max-width: 220px;

    &:first-child {
      width: auto;
    }
    &:last-child {
      width: 150px;
    }
  }
`;

export const PatientProfileStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 2px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${({ theme }) => theme.color.purple100};

    color: ${({ theme }) => theme.color.grey900};
    text-align: center;
    font-size: 18px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;

    > img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transition: all 0.3ms;
    }
  }

  > div:last-child {
    display: grid;
    gap: 2px;
    margin: 0 12px;

    > p {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
    }
    > span {
      color: ${({ theme }) => theme.color.grey700};
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
