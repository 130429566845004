import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Login, Registration } from 'common';
import { PathNames } from 'application/routes/constants';

const AuthPage: FC = () => {
  const { pathname } = useLocation();

  return (
    <section className="App-content">
      {pathname === PathNames.login ? <Login /> : <Registration />}
    </section>
  );
};

export default AuthPage;
