import React, { FC } from 'react';
import SideMenu from 'features/feature-aside-menu';
import { Wrapper } from './styles';

const ManagingSubscriptionsPage: FC = () => (
  <Wrapper>
    <SideMenu />
  </Wrapper>
);

export default ManagingSubscriptionsPage;
