import styled from 'styled-components';

interface DropdownStyledProps {
  isActive?: boolean;
  disabled?: boolean;
  isError?: boolean;
}

interface OptionItemProps {
  selected: boolean;
}

export const DropdownStyled = styled.div<DropdownStyledProps>`
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
  gap: 6px;
  width: 100%;
  position: relative;

  > label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: ${({ theme, isError, disabled }) =>
      isError
        ? theme.color.error2
        : disabled
        ? theme.color.grey400
        : theme.color.grey600};
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme, isError, disabled }) =>
      isError
        ? theme.color.error2
        : disabled
        ? theme.color.grey400
        : theme.color.grey600};
  }
  h2 {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    > svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      margin-right: 10px;
    }
  }
  > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    width: 100%;
    height: 44px;
    background: ${({ theme, isActive, disabled, isError }) =>
      isError
        ? theme.color.error3
        : isActive
        ? theme.color.blue50
        : theme.color[disabled ? 'grey50' : 'white']};
    border: 1px solid
      ${({ theme, isActive, isError }) =>
        isError
          ? theme.color.error2
          : isActive
          ? theme.color.blue800
          : theme.color.grey300};
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.color.grey700};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    > div {
      display: flex;
      align-items: center;
      width: calc(100% - 36px);

      > svg {
        margin-right: 8px;
        min-width: 22px;
        height: 22px;
        path {
          fill: ${({ theme, disabled }) =>
            disabled ? theme.color.grey300 : theme.color.grey500};
        }
      }
    }
    > h2 {
      margin-right: 36px;
    }
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: ${({ theme, disabled }) =>
        disabled ? theme.color.grey300 : theme.color.grey500};
    }
    > svg {
      width: 24px;
      margin-left: 12px;
      transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'unset')};
      path {
        fill: ${({ theme, disabled }) =>
          disabled ? theme.color.grey300 : theme.color.grey700};
      }
    }
  }
`;

export const OptionsList = styled.div`
  display: grid;
  padding: 8px;
  gap: 4px;
  grid-template-rows: max-content;
  position: absolute;
  z-index: 10;
  top: 100%;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  cursor: pointer;

  svg {
    z-index: 11;
  }

  div[type='search'] {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 8px 0 6px;
    background: ${({ theme }) => theme.color.white};
  }

  input[type='search'] {
    padding: 0 12px 0 42px;
  }
`;

export const OptionItem = styled.div<OptionItemProps>`
  display: flex;
  padding: 4px 6px;
  align-items: center;
  border-radius: 4px;
  background: ${({ theme, selected }) =>
    selected ? theme.color.blue100 : 'transparent'};

  color: ${({ theme }) => theme.color.grey800};
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.08px;

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }

  > svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 10px;
  }
`;

export const OptionCheckBox = styled.label<OptionItemProps>`
  display: flex;
  padding: 4px 6px;
  align-items: center;
  border-radius: 4px;
  background: ${({ theme, selected }) =>
    selected ? theme.color.blue100 : 'transparent'};

  color: ${({ theme }) => theme.color.grey800};
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }

  > svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 10px;
  }
`;
