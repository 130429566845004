import { ResetUserPasswordRequestDto } from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserResetPasswordActions } from 'application/constants/actions';
import { AxiosError } from 'axios';
import { UserResetPassword } from 'integration/api/userResetPassword';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const resetPassword = createAsyncThunk(
  UserResetPasswordActions.resetPassword,
  async (params: ResetUserPasswordRequestDto, { rejectWithValue }) => {
    try {
      const { data } = await UserResetPassword.resetPassword(params);
      await delay(1000);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
