import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { ContactsChildProps } from './models';
import { AddProfessionalDataT } from 'features/feature-users-managment/types/AddProfessionalDataT';
import AddAnother from '../AddAnother';
import { Input } from 'common/components';
import {
  Close,
  InputContainer,
  InputWrapper,
  LabelWrapper,
  WrapperContact,
} from './styles';

const Emails = ({ errors, control, register, emails }: ContactsChildProps) => {
  const { t } = useTranslation();

  const prependKey = 'emails';
  const { fields, append, remove } = useFieldArray<AddProfessionalDataT>({
    control,
    name: prependKey,
  });

  const handleAddAnother = () => {
    append('');
  };

  useEffect(() => {
    !fields.length && handleAddAnother();
  }, [fields]);

  useEffect(() => {
    emails?.length &&
      emails.map((email) => {
        append(email);
      });
  }, [emails]);

  const removeContact = (index: number) => {
    remove(index);
  };

  return (
    <WrapperContact>
      <LabelWrapper isError={errors?.emails?.length}>
        <label>{t('users_managment.doctor_create.doctor_email')}</label>
      </LabelWrapper>
      {fields.map((field, index) => {
        const id = `${prependKey}.${index}`;
        const error = errors?.[prependKey]?.[index]?.message;

        return (
          <InputWrapper key={field.id} last={index === fields.length - 1}>
            <InputContainer>
              <Input
                id={id}
                type="email"
                register={register}
                errorMessage={error}
                placeholder={
                  t('users_managment.doctor_create.email_placeholder') as string
                }
              />
            </InputContainer>
            {index !== 0 && <Close onClick={() => removeContact(index)} />}
          </InputWrapper>
        );
      })}

      <AddAnother
        title={t('users_managment.doctor_create.add_another')}
        onClick={handleAddAnother}
      />
    </WrapperContact>
  );
};

export default Emails;
