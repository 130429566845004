import React, { useEffect } from 'react';
import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { ExternalClinicResponseDto } from '@docbay/schemas';
import { useTranslation } from 'react-i18next';

import { CountryDropdown, Input } from 'common/components';
import { SupportedCountriesDto } from 'common/types/countries';

import ClinicsEmails from '../ClinicsEmails';
import ClinicsPhones from '../ClinicsPhones';

import { ClinicDataWrapper, ClinicFormWrapper } from './styles';

interface Props {
  clinic: ExternalClinicResponseDto;
  index: number;
  changeClinicData: (obj: ExternalClinicResponseDto, index: number) => void;
  watch: UseFormWatch<any>;
  getValues: UseFormGetValues<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  clearErrors: UseFormClearErrors<any>;
  setError: UseFormSetError<any>;
  errors: any;
  control: any;
}

const ClinicForm = ({
  clinic,
  index,
  watch,
  register,
  setValue,
  errors,
  clearErrors,
  setError,
  getValues,
  control,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (clinic) {
      const phones = clinic?.phones?.length ? clinic?.phones : [];
      const email = clinic?.emails?.length ? [clinic?.emails[0]] : [];

      clinic?.id && setValue('id', clinic?.id);
      clinic?.name && setValue('name', clinic?.name);
      clinic?.phones && setValue(`clinics.[${index}].phones`, phones);
      clinic?.emails && setValue('emails', email);
      clinic?.city && setValue('city', clinic?.city);
      clinic?.region && setValue('region', clinic?.region);
      clinic?.region && setValue('street', clinic?.street);
      clinic?.longitude && setValue('longitude', clinic?.longitude);
      clinic?.latitude && setValue('latitude', clinic?.latitude);
      clinic?.supportedCountry &&
        setValue(
          `clinics.[${index}].supportedCountry`,
          clinic?.supportedCountry,
        );
    }
  }, [clinic]);

  const currentClinicError = errors?.clinics ? errors?.clinics[index] : [];

  return (
    <ClinicDataWrapper>
      <p>
        {watch('name')?.length
          ? watch('name')
          : `${t('users_managment.doctor_create.clinic')} ${index + 1}`}
      </p>
      <ClinicFormWrapper key={`clinic_${index}`}>
        <Input
          id={`clinics[${index}].name`}
          label={t('users_managment.doctor_create.clinic_name') || ''}
          placeholder={t(
            'users_managment.doctor_create.clinic_name_placeholder',
          )}
          type="text"
          register={register}
          errorMessage={currentClinicError?.name?.message}
        />
        <ClinicsPhones
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          setValue={setValue}
          clearErrors={clearErrors}
          setError={setError}
          clinicIndex={index}
        />
        <ClinicsEmails
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          clinicIndex={index}
        />
        <CountryDropdown
          id={`clinic-country-dropdown_${index}`}
          label={t('country_of_practice')}
          value={watch(`clinics[${index}].supportedCountry`)}
          onChange={(country) =>
            setValue(
              `clinics[${index}].supportedCountry`,
              country as SupportedCountriesDto,
              {
                shouldValidate: !!errors[`clinics[${index}].supportedCountry`],
              },
            )
          }
        />
        <Input
          id={`clinics[${index}].street`}
          label={t('users_managment.doctor_create.clinic_street_address') || ''}
          placeholder={t(
            'users_managment.doctor_create.clinic_street_address_placeholder',
          )}
          type="text"
          register={register}
        />
        <Input
          id={`clinics[${index}].city`}
          label={t('users_managment.doctor_create.clinic_locality') || ''}
          placeholder={t(
            'users_managment.doctor_create.clinic_locality_placeholder',
          )}
          type="text"
          register={register}
        />
        <Input
          id={`clinics[${index}].region`}
          label={t('users_managment.doctor_create.clinic_region') || ''}
          placeholder={t(
            'users_managment.doctor_create.clinic_region_placeholder',
          )}
          type="text"
          register={register}
        />
        <Input
          id={`clinics[${index}].latitude`}
          label={t('users_managment.doctor_create.clinic_latitude') || ''}
          placeholder={t(
            'users_managment.doctor_create.clinic_latitude_placeholder',
          )}
          type="text"
          register={register}
        />
        <Input
          id={`clinics[${index}].longitude`}
          label={t('users_managment.doctor_create.clinic_longitude') || ''}
          placeholder={t(
            'users_managment.doctor_create.clinic_longitude_placeholder',
          )}
          type="text"
          register={register}
        />
      </ClinicFormWrapper>
    </ClinicDataWrapper>
  );
};

export default ClinicForm;
