import React, { lazy, Suspense } from 'react';
import { Loader } from 'common/loader/Loader';
import { WithHeader } from 'common/layout/WithHeader';
import { PathNames } from './constants';
import ErrorPage from 'application/pages/ErrorPage';
import StatisticsPage from 'application/pages/Statistics';
import UsersManagmentPage from 'application/pages/UsersManagment';
import DatabasesPage from 'application/pages/Databases';
import ManagingSubscriptions from 'application/pages/ManagingSubscriptions';
import BillingPage from 'application/pages/Billing';
import CustomerSupportPage from 'application/pages/CustomerSupport';
import PasswordPage from 'application/pages/Password';
import CreatePasswordPage from 'application/pages/CreatePasswordPage';
import ClinicsPage from 'application/pages/ClinicsPage';
import ClinicProfilePage from 'application/pages/ClinicProfilePage';
import SpecializationMetricsPage from 'application/pages/SpecializationMetrics';
import { getUserRole } from 'application/sessionStorage/auth';
import { UserRoles } from 'application/constants/userRoles';
import { Navigate } from 'react-router-dom';

const AuthPage = lazy(() => import('application/pages/AuthPage'));
const userRole = getUserRole();
const isAdminOwner = userRole === UserRoles.adminOwner;

export const authRouts = [
  {
    path: PathNames.home,
    title: '/',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <Navigate
            to={isAdminOwner ? PathNames.statistics : PathNames.usersManagement}
          />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: PathNames.statistics,
    title: 'Statistics',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <StatisticsPage />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: PathNames.usersManagement,
    title: 'Users Managment',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <UsersManagmentPage />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: PathNames.databases,
    title: 'Databases',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <DatabasesPage />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: PathNames.managingSubscriptions,
    title: 'Managing Subscriptions',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <ManagingSubscriptions />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: PathNames.billing,
    title: 'Billing (accounting)',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <BillingPage />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: PathNames.customerSupport,
    title: 'Customer support',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <CustomerSupportPage />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: PathNames.password,
    title: 'Password',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <PasswordPage />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: PathNames.clinics,
    title: 'Clinics',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <ClinicsPage />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: `${PathNames.clinics}/:id`,
    title: 'Clinic management',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <ClinicProfilePage />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: PathNames.specializationMetrics,
    title: 'Specialization Metrics',
    component: (
      <Suspense fallback={<Loader />}>
        <WithHeader>
          <SpecializationMetricsPage />
        </WithHeader>
      </Suspense>
    ),
  },
  {
    path: '/*',
    title: 'Error',
    component: (
      <WithHeader>
        <ErrorPage />
      </WithHeader>
    ),
  },
];

export const publicRouts = [
  {
    path: PathNames.login,
    title: 'Login',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.registration,
    title: 'Registration',
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.createPassword,
    title: 'Create Password',
    component: (
      <Suspense fallback={<Loader />}>
        <CreatePasswordPage />
      </Suspense>
    ),
  },
];
