import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useAddMetricSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');

  return yup.object().shape({
    metric: yup.array().of(
      yup.object().shape({
        name: yup.string().required(requiredError),
        lang: yup.string().required(requiredError),
      }),
    ),
    unit: yup.array().of(
      yup.object().shape({
        id: yup.string().required(requiredError),
        lang: yup.string().required(requiredError),
      }),
    ),
    specializationIds: yup
      .array()
      .of(yup.string())
      .min(1, requiredError!)
      .required(requiredError),
  });
};
