import styled from 'styled-components';

export const HeaderStyled = styled.header`
  display: flex;
  margin-bottom: 20px;

  a {
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.08px;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      path {
        fill: ${({ theme }) => theme.color.grey600};
      }
    }
  }
`;
