import styled from 'styled-components';

export const StyledTr = styled.tr`
  background: ${({ theme }) => theme.color.white};
  > td {
    vertical-align: middle;
    text-align: start;
    padding: 40px 0;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    border-radius: 12px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;

      > svg {
        margin-bottom: 24px;
      }
      p {
        color: ${({ theme }) => theme.color.grey900};
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;

        span {
          cursor: pointer;
          font-weight: 700;
          color: ${({ theme }) => theme.color.blue800};
        }
      }
    }
  }
`;
