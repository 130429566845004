import * as React from 'react';
import { ReactComponentElement } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/system';
import { Box } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';

export const LoaderStyled = styled(Box)`
  width: 100%;
  height: 100vh;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.dark};

  & svg {
    width: 100px;
    height: 100px;
  }
}
`;

type Props = {
  icon?: ReactComponentElement<any>;
};

export const Loader = ({ icon = <LoopIcon /> }: Props) => {
  const isVisible = useSelector((state: any) => state.loading);
  const theme = useTheme();

  return isVisible ? (
    <LoaderStyled theme={theme}>
      <div className={classNames({ rotation: isVisible })}>{icon}</div>
    </LoaderStyled>
  ) : null;
};
