import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from 'application/assets/logo.svg';
import { ReactComponent as DocBayName } from 'application/assets/doc-bay-name.svg';
import { Wrapper, LogoWrapper } from './styles';

const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <LogoWrapper>
        <DocBayName className="name" /> <Logo className="logo" />
      </LogoWrapper>
      <h1>{t('create_password.title')}</h1>
      <h2>{t('create_password.sub_title')}</h2>
    </Wrapper>
  );
};

export default Header;
