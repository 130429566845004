import React, { FC, useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { authService, Loader } from 'common';
import { PathNames } from './constants';
import { authRouts, publicRouts } from './routes';
import { loginAllTabs, logoutAllTabs } from 'common/auth/authService';
import CommonErrorModal from 'common/components/CommonErrorModal';
import { useAppSelector } from 'common/hooks/redux';
import { getUserRole } from 'application/sessionStorage/auth';
import { UserRoles } from 'application/constants/userRoles';

export const AppRouter: FC = () => {
  const { isErrorModalVisible } = useAppSelector((state) => state.error);
  const isAuth = authService.checkAuth();
  const userRole = getUserRole();

  useEffect(() => {
    logoutAllTabs();
    loginAllTabs();
  }, []);

  const routes = useMemo(() => {
    const isAdminOwner = userRole === UserRoles.adminOwner;
    if (isAdminOwner) {
      return authRouts;
    }
    return authRouts.filter((route) => route.path !== PathNames.statistics);
  }, [authRouts, userRole]);

  return (
    <BrowserRouter>
      <Routes>
        {isAuth &&
          routes.map(({ path, component }) => (
            <Route path={path} element={component} key={path} />
          ))}
        {publicRouts.map(({ path, component }) => (
          <Route path={path} element={component} key={path} />
        ))}
        <Route path="/*" element={<Navigate to={PathNames.login} />} />
      </Routes>
      <Loader />
      {isErrorModalVisible && <CommonErrorModal isOpen={isErrorModalVisible} />}
    </BrowserRouter>
  );
};
