import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PathNames } from 'application/routes';
import { ReactComponent as BackIcon } from 'application/assets/chevron-left.svg';

import { HeaderStyled } from './styles';

const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <HeaderStyled>
      <Link to={PathNames.clinics}>
        <BackIcon />
        {t('clinics_management.back_to_clinics')}
      </Link>
    </HeaderStyled>
  );
};

export default Header;
