import { createSlice } from '@reduxjs/toolkit';
import {
  fetchInsurances,
  deleteInsurances,
  addInsurances,
  editInsurances,
} from './ActionCreators';
import { InsurancesState } from './models';

const initialState: InsurancesState = {
  insurances: [],
  deleted: false,
  created: false,
  edited: false,
  total: 0,
  page: 0,
  totalPages: 0,
  isLoading: false,
  error: '',
};

const insurancesSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setEdited: (state, action) => {
      state.edited = action.payload;
    },
  },
  extraReducers: (builder) => {
    //insurances fetch
    builder.addCase(fetchInsurances.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInsurances.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchInsurances.fulfilled, (state, action) => {
      state.insurances = action.payload.entities;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    });

    //add insurances
    builder.addCase(addInsurances.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addInsurances.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(addInsurances.fulfilled, (state, action) => {
      state.created = true;
      state.isLoading = false;
    });

    //delete insurances
    builder.addCase(deleteInsurances.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteInsurances.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteInsurances.fulfilled, (state, action) => {
      state.deleted = true;
      state.isLoading = false;
    });

    // edit insurance
    builder.addCase(editInsurances.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editInsurances.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(editInsurances.fulfilled, (state, action) => {
      state.isLoading = false;
      state.edited = true;
    });
  },
});

export const { setCreated, setDeleted, setEdited } = insurancesSlice.actions;

export default insurancesSlice.reducer;
