import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
  height: fit-content;
  margin-top: 20px;
  background: ${({ theme }) => theme.color.white};
`;

export const TableStyled = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  > table {
    width: 100%;
    overflow: scroll;
    thead {
      th {
        border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
        background: ${({ theme }) => theme.color.grey50};
        padding: 12px 24px;
        text-align: left;
        color: ${({ theme }) => theme.color.grey600};
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.07px;

        &:nth-of-type(2) {
          width: 400px;
        }
      }

      .actionsColumn {
        width: 100px;
      }
    }
  }
`;
