import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { Input } from 'common/components';
import { ReactComponent as CheckCircleIcon } from 'application/assets/check-circle.svg';
import { RuleStyled, RulesSectionStyled } from './styles';
import { usePasswordRules } from './hooks';
import { createPasswordValidation } from './helpers';

interface CreatePasswordProps {
  watch: UseFormWatch<any>;
  getValues: UseFormGetValues<any>;
  register: UseFormRegister<any>;
  errors?: string;
  label?: string;
  placeholder?: string;
}

const CreatePassword: FC<CreatePasswordProps> = ({
  watch,
  getValues,
  register,
  errors,
  label,
  placeholder,
}) => {
  const { t } = useTranslation();
  const passwordRules = usePasswordRules();
  const [passwordValidationField, setPasswordValidationField] = useState({
    validLength: false,
    includeUppercase: false,
    includeLowercase: false,
  });

  useEffect(() => {
    const passwordField = createPasswordValidation(getValues('password'));
    setPasswordValidationField(passwordField);
  }, [watch('password')]);

  return (
    <div>
      <Input
        id="password"
        label={label || t('auth.create_password.new_password')!}
        type="password"
        placeholder={placeholder}
        errorMessage={
          (errors && t('auth.create_password.password_may_contain')) || ''
        }
        register={register}
      />
      <RulesSectionStyled>
        {!errors && (
          <RuleStyled
            key={t('auth.create_password.password_may_contain')}
            isValid={!!errors}
          >
            <span>{t('auth.create_password.password_may_contain')}</span>
          </RuleStyled>
        )}
        {passwordRules.map((item) => (
          <RuleStyled key={item.id} isValid={passwordValidationField[item.id]}>
            <CheckCircleIcon />
            <span>{item.name}</span>
          </RuleStyled>
        ))}
      </RulesSectionStyled>
    </div>
  );
};

export default CreatePassword;
