import { SaasAdminCreationDto, SaasAdminUpdateDto } from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalUsersActions } from 'application/constants/actions';
import { AxiosError } from 'axios';
import { InternalUsersAPI } from 'integration/api/internalUsers';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchInternalUsers = createAsyncThunk(
  InternalUsersActions.fetchInternalUsers,
  async (params: any, { rejectWithValue }) => {
    try {
      const { data } = await InternalUsersAPI.fetchInternalUsers(params);
      await delay(1000);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
export const createInternalUser = createAsyncThunk(
  InternalUsersActions.createInternalUser,
  async (data: SaasAdminCreationDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await InternalUsersAPI.createInternalUser(
        data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const editInternalUser = createAsyncThunk(
  InternalUsersActions.editInternalUser,
  async (
    { id, data }: { id: string; data: SaasAdminUpdateDto },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await InternalUsersAPI.editInternalUser(
        id,
        data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteInternalUser = createAsyncThunk(
  InternalUsersActions.editInternalUser,
  async ({ id, type }: { id: string; type: string }, { rejectWithValue }) => {
    try {
      const { data: responseData } = await InternalUsersAPI.deleteInternalUser(
        id,
        type,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
