import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { UserChangePasswordDto } from '@docbay/schemas';
import { useTranslation } from 'react-i18next';
import { getProfilesFromSessionStorage } from 'application/sessionStorage/auth';
import { changePasswordSaas } from 'application/store/reducers/Users/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  CreatePassword,
  Input,
  Loader,
  PrimaryButton,
} from 'common/components';
import { useChangePasswordSchema } from './hooks/useChangePasswordSchema';
import { Profile } from './models';
import {
  FormStyled,
  Wrapper,
  DividerStyled,
  FieldsSection,
  ButtonSection,
} from './styles';
import { SuccessModal } from './components';

const ChangePassword: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.users);
  const changePasswordSchema = useChangePasswordSchema();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const fullName = `${profile?.firstName} ${profile?.lastName}`;

  const {
    register,
    watch,
    getValues,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<UserChangePasswordDto>({
    resolver: yupResolver(changePasswordSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    const profiles = getProfilesFromSessionStorage();
    if (profiles?.length) {
      setProfile(profiles[0]);
    }
  }, []);

  const onSubmit = async (data: UserChangePasswordDto) => {
    const response = await dispatch(changePasswordSaas(data));
    if (response.meta.requestStatus === 'rejected') {
      setError('oldPassword', { message: 'Incorrect password' });
    }
    if (response.meta.requestStatus === 'fulfilled') {
      setShowSuccessModal(true);
      reset();
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h1>{fullName}</h1>
          <h2>{profile?.userTypes}</h2>
          <DividerStyled />
          <FieldsSection>
            <Input
              id="oldPassword"
              label={t('auth.change_password.old_password') || ''}
              type="password"
              errorMessage={errors.oldPassword?.message}
              register={register}
            />
            <CreatePassword
              watch={watch}
              getValues={getValues}
              register={register}
              errors={errors.password?.message}
            />
          </FieldsSection>
        </div>
        <ButtonSection>
          <PrimaryButton type="submit">{t('global.save')}</PrimaryButton>
        </ButtonSection>
      </FormStyled>
      <SuccessModal
        opened={showSuccessModal}
        onClose={handleCloseSuccessModal}
      />
    </Wrapper>
  );
};

export default ChangePassword;
