import React from 'react';
import { useAppSelector } from 'common/hooks/redux';
import { metricsList } from '../../constants';
import { Option } from 'application/store/reducers/Statistics/models';
import { useTranslation } from 'react-i18next';

const MetricTableName = ({
  metricName,
  key,
}: {
  metricName: Option;
  key: string;
}) => {
  const { t } = useTranslation();
  const { metrics } = useAppSelector((state) => state.statistics);

  const currentMetricValue = metrics?.find(
    (metric) => metric === metricName.value,
  );

  const metricLabel = metricsList?.find(
    (metric) => metric.value === currentMetricValue,
  );

  if (!metricLabel) return <></>;

  const metricText = t(`metricsList.${metricLabel.value}`);
  const textArr = metricText?.split('\n');

  return (
    <React.Fragment key={key}>
      <th key={key}>
        {textArr.map((item) => {
          return <span key={item}>{item}</span>;
        })}
      </th>
    </React.Fragment>
  );
};

export default MetricTableName;
