import React from 'react';
import { Wrapper } from './styles';
import Main from 'features/feature-specialization-metrics/components/Main';

const SpecializationMetrics = () => {
  return (
    <Wrapper>
      <Main />
    </Wrapper>
  );
};

export default SpecializationMetrics;
