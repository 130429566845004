import styled from 'styled-components';

export const FormStyled = styled.form`
  display: grid;
  grid-template-rows: max-content;
  gap: 20px;
  margin-top: 32px;

  input {
    min-width: 400px;
  }

  > button {
    margin-top: 8px;
  }
`;
