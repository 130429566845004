import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setClinicsSuggestions } from 'application/store/reducers/ClinicsManagement/ClinicsManagementSlice';
import { getClinicsSuggestions } from 'application/store/reducers/ClinicsManagement/ActionCreators';

import { Input } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useDebounce } from 'common/hooks/useDebounce';

import {
  SearchStyled,
  SuggestionItem,
  SuggestionsList,
  Wrapper,
} from './styles';
import { PathNames } from 'application/routes';
import useOnClickOutside from 'common/hooks/useClickOutside';

const ClinicsSuggestions: FC = () => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clinicsSuggestions } = useAppSelector(
    (state) => state.clinicsManagement,
  );
  const [isActive, setIsActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchItem = useDebounce(searchValue, 500);

  const showList = !!clinicsSuggestions.length && isActive;

  useOnClickOutside(ref, () => setIsActive(false));

  useEffect(() => {
    if (searchItem) {
      dispatch(getClinicsSuggestions(searchItem));
    }
  }, [searchItem]);

  useEffect(() => {
    return () => {
      dispatch(setClinicsSuggestions([]));
    };
  }, [searchItem]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (!value) {
      dispatch(setClinicsSuggestions([]));
    }
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  return (
    <Wrapper ref={ref}>
      <SearchStyled onMouseDown={() => setIsActive(true)}>
        <Input
          id={'clinicName'}
          type="search"
          value={searchValue}
          onClear={clearSearch}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder={t('clinics_management.clinics_search_placeholder')}
          autoComplete="off"
        />
      </SearchStyled>
      {showList && (
        <SuggestionsList>
          {clinicsSuggestions.map((clinic) => (
            <SuggestionItem
              key={clinic.id}
              to={`${PathNames.clinics}/${clinic.id}`}
            >
              {clinic.name}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}
    </Wrapper>
  );
};

export default ClinicsSuggestions;
