import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';

interface DeleteModalProps {
  isOpen: boolean;
  text: string;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteModal: FC<DeleteModalProps> = ({
  isOpen,
  text,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <h1>{text}</h1>
        <section>
          <SecondaryButton onClick={onClose}>
            {t('delete_modal.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSubmit} styleType={'error'}>
            {t('delete_modal.confirm')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default DeleteModal;
