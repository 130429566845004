import React, { FC } from 'react';
import SideMenu from 'features/feature-aside-menu';
import Statistics from 'features/feature-statistics';
import { Wrapper } from './styles';

const StatisticsPage: FC = () => (
  <Wrapper>
    <SideMenu />
    <Statistics />
  </Wrapper>
);

export default StatisticsPage;
