import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'application/assets/add.svg';
import { Loader, PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { FilterSectionStyled, Wrapper } from './styles';
import TableSupportedCountries from '../TableSupportedCountries';
import {
  getSupportedCountries,
  addToSupportedCountries,
  deleteSupportedCountry,
} from 'application/store/reducers/Countries/ActionCreators';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import { SupportedCountriesDto } from 'common/types/countries';
import AddSupportedCountryModal from '../AddSupportedCountryModal';
import SuccessModal from '../SuccessModal';
import ConfirmDeleteSupportedCountry from '../ConfirmDeleteSupportedCountry';

const InsuranceTable: FC = () => {
  const { t, i18n } = useTranslation();
  const { localizeNameKey } = useLocalizeKey();
  const dispatch = useAppDispatch();
  const { supportedCountries, allCountries, isLoading } = useAppSelector(
    (state) => state.countries,
  );
  const [deletedItem, setDeletedItem] = useState<SupportedCountriesDto | null>(
    null,
  );
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenSuccessAddModal, setIsOpenSuccessAddModal] = useState(false);
  const [isOpenSuccessDeleteModal, setIsOpenSuccessDeleteModal] =
    useState(false);

  const columns = useMemo(() => {
    return [t('databases_feature.country'), t('databases_feature.actions')];
  }, [t]);

  const data = useMemo(() => {
    return supportedCountries.map((item) => ({
      name: item[localizeNameKey as keyof SupportedCountriesDto]!,
      id: item.id,
      code: item.code,
    }));
  }, [supportedCountries, i18n.language]);

  const getCurrentData = () => {
    dispatch(getSupportedCountries());
  };

  useEffect(() => {
    getCurrentData();
  }, []);

  const handleDelete = (id: string) => {
    const currentCountry = supportedCountries.find(
      (country) => country.id === id,
    );

    setDeletedItem(currentCountry!);
  };
  //
  const handleDeleteClose = () => {
    setDeletedItem(null);
  };
  //
  const submitDeleteCountry = () => {
    dispatch(deleteSupportedCountry(deletedItem?.code!)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setIsOpenSuccessDeleteModal(true);
      }
    });
  };

  const handleCloseSuccessDeleteModal = () => {
    setDeletedItem(null);
    setIsOpenSuccessDeleteModal(false);
    getCurrentData();
  };

  const handleAddCountryToSupported = (id: string) => {
    const currentCountry = allCountries?.find((country) => country.id === id);

    dispatch(addToSupportedCountries({ code: currentCountry?.code! })).then(
      (data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          setIsOpenSuccessAddModal(true);
        }
      },
    );
  };

  const handleCloseAddCountryToSupported = () => {
    setIsOpenAddModal(false);
  };

  const handleCloseSuccessAddModal = () => {
    setIsOpenSuccessAddModal(false);
    getCurrentData();
  };

  return (
    <>
      <Wrapper>
        {isLoading && <Loader />}
        <FilterSectionStyled>
          <div>
            <h1>Geographies</h1>
            <PrimaryButton onClick={() => setIsOpenAddModal(true)}>
              <AddIcon />
              {t('databases_feature.add_new')}
            </PrimaryButton>
          </div>
        </FilterSectionStyled>
        <TableSupportedCountries
          columns={columns}
          data={data}
          handleDelete={handleDelete}
        />
        <AddSupportedCountryModal
          isOpen={isOpenAddModal}
          onClose={handleCloseAddCountryToSupported}
          onAdd={handleAddCountryToSupported}
        />
        {isOpenSuccessAddModal && (
          <SuccessModal
            onClose={handleCloseSuccessAddModal}
            typeName={t('databases_feature.success_country_added')}
          />
        )}
        <ConfirmDeleteSupportedCountry
          isOpen={!!deletedItem}
          onClose={handleDeleteClose}
          countryName={
            deletedItem?.[localizeNameKey as keyof SupportedCountriesDto] || ''
          }
          onSubmit={submitDeleteCountry}
        />
        {isOpenSuccessDeleteModal && (
          <SuccessModal
            onClose={handleCloseSuccessDeleteModal}
            typeName={t('databases_feature.success_country_deleted')}
          />
        )}
      </Wrapper>
    </>
  );
};

export default InsuranceTable;
