import { ExternalClinicResponseDto } from '@docbay/schemas/dist/external/external-clinic/dto/external-clinic-response.dto';

export enum TabName {
  Patients = 'Patients',
  Professionals = 'Professionals',
  InternalUsers = 'InternalUsers',
  ImportedDoctors = 'ImportedDoctors',
}

export enum UserTypes {
  Admin = 'Admin',
  Secretary = 'Secretary',
  ClinicOwner = 'ClinicOwner',
  Professional = 'Professional',
}

export const ProfessionalsTypesList = [
  { label: 'All', value: '' },
  { label: 'Admin', value: 'Admin' },
  { label: 'Secretary', value: 'Secretary' },
  { label: 'Clinic Owner', value: 'ClinicOwner' },
  { label: 'Professional', value: 'Professional' },
];

export const TypesList = [
  { label: 'All', value: '' },
  { label: 'Admin Owner', value: 'AdminOwner' },
  { label: 'Admin Customer Tech', value: 'AdminCustomerTech' },
  { label: 'Admin Tech', value: 'AdminTech' },
];

export const AddProfessionalData: ExternalClinicResponseDto = {
  id: '',
  name: '',
  latitude: '',
  longitude: '',
  city: '',
  street: '',
  region: '',
  phones: [],
  emails: [],
};
