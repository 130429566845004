import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PaginatedSpecializationsActions } from 'application/constants/actions';
import { SpecializationsAPI } from 'integration/api/specializations';
import { fetchPaginatedSpecializationProps } from 'common/types/paginatedSpecializations';
import {
  SpecializationCreationDto,
  SpecializationUpdateDto,
} from '@docbay/schemas';

export const fetchPaginatedSpecializations = createAsyncThunk(
  PaginatedSpecializationsActions.fetchPaginatedSpecializations,
  async (params: fetchPaginatedSpecializationProps, { rejectWithValue }) => {
    try {
      const { data } = await SpecializationsAPI.fetchPaginatedSpecializations(
        params,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const addSpecialization = createAsyncThunk(
  PaginatedSpecializationsActions.addSpecialization,
  async (params: SpecializationCreationDto, { rejectWithValue }) => {
    try {
      const { data } = await SpecializationsAPI.addSpecialization(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deactivateSpecialization = createAsyncThunk(
  PaginatedSpecializationsActions.deactivateSpecialization,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await SpecializationsAPI.deactivateSpecialization(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const activateSpecialization = createAsyncThunk(
  PaginatedSpecializationsActions.activateSpecialization,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await SpecializationsAPI.activateSpecialization(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const editSpecializations = createAsyncThunk(
  PaginatedSpecializationsActions.editSpecializations,
  async (
    params: { data: SpecializationUpdateDto; id: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await SpecializationsAPI.editSpecialization(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
