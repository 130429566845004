import React, { FC, useState } from 'react';
import { PatientProfileStyled, StyledTr } from './styles';
import { Box } from '@mui/system';
import ResetPasswordAction from 'features/feature-users-managment/components/ResetPasswordAction';
import { PatientItemProps } from './modules';
import { useAppDispatch } from 'common/hooks/redux';
import { resetPassword } from 'application/store/reducers/UserResetPassword/ActionCreators';

const PatientItem: FC<PatientItemProps> = ({ patient }) => {
  const dispatch = useAppDispatch();
  const [isPasswordSent, setIsPasswordSent] = useState(false);

  const handleResetPassword = () => {
    if (!isPasswordSent) {
      dispatch(resetPassword({ phone: patient.phone })).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          setIsPasswordSent(true);
        }
      });
    }
  };

  const patientInitials = patient?.firstName
    ? `${patient?.firstName!.slice(0, 1)}${patient?.lastName!.slice(0, 1)}`
    : '';

  return (
    <StyledTr>
      <td>
        <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
          <PatientProfileStyled>
            <div className="image">{patientInitials}</div>
            <div>
              <p>{patient.firstName + ' ' + patient.lastName}</p>
              <span>{patient.email}</span>{' '}
            </div>{' '}
          </PatientProfileStyled>
        </Box>
      </td>
      <td>
        <ResetPasswordAction
          handleResetPassword={handleResetPassword}
          isPasswordReseted={isPasswordSent}
        />
      </td>
    </StyledTr>
  );
};
export default PatientItem;
