import {
  ClinicSuggestionResponseDto,
  CommonEntitiesListResponse,
  SaasClinicResponseDto,
} from '@docbay/schemas';
import {
  GetClinicsParams,
  UpdateClinicManagementData,
} from 'common/types/clinicsManagement';
import axiosInstance from './axiosInstance';

export const ClinicsManagementAPI = {
  getClinics: (params: GetClinicsParams) =>
    axiosInstance.get<CommonEntitiesListResponse<SaasClinicResponseDto>>(
      '/saas/clinics-management',
      {
        params,
      },
    ),
  getClinicsSuggestions: (search: string) =>
    axiosInstance.get<ClinicSuggestionResponseDto[]>(
      '/saas/clinics-management/suggestions',
      { params: { search } },
    ),
  getClinicById: (clinicId: string) =>
    axiosInstance.get<SaasClinicResponseDto>(
      `/saas/clinics-management/${clinicId}`,
    ),
  updateClinic: (data: UpdateClinicManagementData) => {
    const { clinicId, billingApiKey } = data;
    return axiosInstance.put(
      `/saas/clinics-management/${clinicId}/billing-api-key`,
      {
        billingApiKey,
      },
    );
  },
  deleteClinic: (clinicId: string) =>
    axiosInstance.delete(
      `/saas/clinics-management/${clinicId}/billing-api-key`,
    ),
};
