import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExpandMoreIcon } from 'application/assets/expand-more.svg';
import useOnClickOutside from 'common/hooks/useClickOutside';
import Input from '../Input';
import Checkbox from '../Checkbox';
import { DropdownProps, Option } from './models';
import {
  DropdownStyled,
  List,
  OptionCheckBox,
  OptionItem,
  OptionsList,
} from './styles';

const Dropdown: FC<DropdownProps> = ({
  id,
  label,
  placeholder,
  searchPlaceholder,
  value,
  onChange,
  hint,
  disabled,
  options,
  isMulti,
  errorMessage,
  withSearch,
  LeftIcon,
  sortByChosen,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchItem, setSearchItem] = useState('');

  useOnClickOutside(ref, () => setIsActive(false));

  const optionList = useMemo(() => {
    if (value.length && sortByChosen) {
      (value as string[])?.map((val) => {
        const indexOfObject = options!.findIndex(
          (object) => object.value === val,
        );
        options.splice(0, 0, options.splice(indexOfObject, 1)[0]);
      });
    }

    if (!searchItem) return options;
    return options!.filter((item) =>
      item.label.toLocaleLowerCase().includes(searchItem.toLocaleLowerCase()),
    );
  }, [options, searchItem, sortByChosen, value]);

  const handleChange = (selectedItem: string) => {
    if (!Array.isArray(value)) return;
    const valueAlreadyAdded = value.some((item) => item === selectedItem);
    if (valueAlreadyAdded) {
      const filtredValue = value.filter((item) => item !== selectedItem);
      onChange(filtredValue);
    } else {
      onChange([...value, selectedItem]);
    }
    return;
  };

  const IsValueChecked = (item: Option) => {
    //checking if checkbox checked for dropdown multiple
    const isValueArray = Array.isArray(value);
    return isValueArray && value.some((i) => i === item.value);
  };

  const getValue = () => {
    if (isMulti) return `${value.length} ${t('selected')}`;
    const currentOption = optionList.find(
      (item) => String(item.value) === String(value),
    );
    return currentOption?.label;
  };

  const isListNotEmpty = isActive && !!options.length;

  return (
    <DropdownStyled
      isActive={isActive}
      disabled={disabled}
      ref={ref}
      isError={!!errorMessage}
      datatype={id}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <section onMouseDown={() => !disabled && setIsActive(!isActive)}>
        {LeftIcon ? (
          <div>
            <LeftIcon />
            {!value.length ? <span>{placeholder}</span> : <h2>{getValue()}</h2>}
          </div>
        ) : (
          <>
            {!value.length ? <span>{placeholder}</span> : <h2>{getValue()}</h2>}
          </>
        )}
        <ExpandMoreIcon />
      </section>
      {isListNotEmpty && !isMulti ? (
        <OptionsList className="optionList">
          {withSearch && (
            <Input
              id={'search'}
              type="search"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target?.value || '')}
              placeholder={searchPlaceholder}
              onClear={() => setSearchItem('')}
            />
          )}
          {optionList.map((item) => (
            <OptionItem
              key={item.value}
              onClick={() => {
                onChange(item);
                setIsActive(false);
              }}
              selected={value === String(item.value)}
            >
              {t(item.label)}
            </OptionItem>
          ))}
        </OptionsList>
      ) : (
        ''
      )}
      {isListNotEmpty && isMulti ? (
        <OptionsList className="option-list-multi">
          {withSearch && (
            <Input
              id={'search'}
              type="search"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target?.value || '')}
              placeholder={searchPlaceholder}
              onClear={() => setSearchItem('')}
            />
          )}
          <List>
            {optionList.map((item, index) => (
              <OptionCheckBox
                key={`${item.value}_${index}`}
                htmlFor={`${item.label}_${index}`}
                selected={IsValueChecked(item)}
              >
                <Checkbox
                  id={`${item.label}_${index}`}
                  checked={IsValueChecked(item)}
                  onChange={() => handleChange(String(item.value))}
                />
                {t(item.label)}
              </OptionCheckBox>
            ))}
          </List>
        </OptionsList>
      ) : (
        ''
      )}
      {(hint || errorMessage) && (
        <span>{errorMessage ? errorMessage : hint}</span>
      )}
    </DropdownStyled>
  );
};

export default Dropdown;
