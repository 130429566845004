import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'application/assets/add.svg';
import { Input, Loader, PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useDebounce } from 'common/hooks/useDebounce';
import {
  activateSpecialization,
  addSpecialization,
  deactivateSpecialization,
  editSpecializations,
  fetchPaginatedSpecializations,
} from 'application/store/reducers/Specializations/ActionCreators';
import TableSpecializations from '../TableSpecializations';
import { FilterSectionStyled, Wrapper } from './styles';
import AddSpecializationModal from 'features/feature-database/components/AddSpecializationModal';
import SuccessModal from 'features/feature-database/components/SuccessModal';
import {
  setActivated,
  setCreated,
  setDeleted,
} from 'application/store/reducers/Specializations/PaginatedSpecializationsSlice';
import {
  SpecializationCreationDto,
  SpecializationUpdateDto,
} from '@docbay/schemas';
import { UseFormReset } from 'react-hook-form';
import DeleteModal from 'features/feature-database/components/DeleteModal';
import { setEdited } from '../../../../application/store/reducers/Insurances/InsurancesSlice';

const defaultLimit = 10;

const SpecializationTable: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const {
    specializations,
    isLoading,
    totalPages,
    created,
    edited,
    activated,
    deleted,
  } = useAppSelector((state) => state.paginatedSpecializations);
  const [searchItem, setSearchItem] = useState('');
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [onRemove, setOnRemove] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState<string | null>(null);
  const searchResult = useDebounce(searchItem, 500);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [specializationToUpdate, setspecializationToUpdate] =
    useState<SpecializationUpdateDto | null>(null);
  // Get specialization id separately as we don't have it in dto
  const [specializationToUpdateId, setspecializationToUpdateId] = useState<
    string | null
  >(null);

  const columns = useMemo(() => {
    return [
      t('databases_feature.specializations.all_specialization'),
      t('databases_feature.actions'),
    ];
  }, [t]);

  const data = useMemo(() => {
    return specializations.map((item) => ({
      active: item.active,
      name_EN: item.name_EN,
      name_PT: item.name_PT,
      name_FR: item.name_FR,
      id: item.id,
    }));
  }, [specializations]);

  const getCurrentData = (page: number) => {
    dispatch(
      fetchPaginatedSpecializations({
        text: searchResult,
        page: page,
        limit: defaultLimit,
      }),
    );

    setCurrentPage(page);
  };

  useEffect(() => {
    getCurrentData(1);
  }, []);

  useEffect(() => {
    getCurrentData(1);
  }, [searchResult]);

  useEffect(() => {
    (created || edited) &&
      dispatch(
        fetchPaginatedSpecializations({
          text: searchResult,
          page: 1,
          limit: defaultLimit,
        }),
      );
  }, [created, edited]);

  const handleCloseAddModal = () => {
    setIsOpenAddModal(false);
  };

  const handleCloseSuccessAddModal = () => {
    dispatch(setCreated(false));
    setIsOpenAddModal(false);
  };

  const handleCloseSuccessDeleteModal = () => {
    dispatch(setDeleted(false));
    setOnRemove(false);

    dispatch(
      fetchPaginatedSpecializations({
        text: searchResult,
        page: 1,
        limit: defaultLimit,
      }),
    );
  };

  const handleCloseSuccessActivateModal = async () => {
    dispatch(setActivated(false));

    await dispatch(
      fetchPaginatedSpecializations({
        text: searchResult,
        page: 1,
        limit: defaultLimit,
      }),
    );
  };

  const handleAddSpecialization = (
    data: SpecializationCreationDto,
    reset: UseFormReset<any>,
  ) => {
    dispatch(addSpecialization(data)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        reset();
      }
    });
  };

  const handleEditSpecialization = (
    data: SpecializationUpdateDto,
    reset: UseFormReset<any>,
  ) => {
    if (specializationToUpdateId) {
      dispatch(
        editSpecializations({ id: specializationToUpdateId, data }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          reset();
        }
      });
    } else {
      console.log('Cant get id for editing specialization');
    }
  };

  const handleRemoveClose = () => {
    setOnRemove(false);
  };

  const handleRemoveSpecialization = () => {
    deletedItemId && dispatch(deactivateSpecialization(deletedItemId));
  };

  const handleActivateSpecialization = (id: string) => {
    dispatch(activateSpecialization(id));
  };

  const handleCloseSuccessEditModal = () => {
    dispatch(setEdited(false));
    setIsOpenEditModal(false);
  };

  const handleOpenEditSpecializationModal = (
    id: string,
    isOpen: boolean,
    data: SpecializationUpdateDto,
  ) => {
    setIsOpenEditModal(isOpen);
    setspecializationToUpdate(data);
    setspecializationToUpdateId(id);
  };

  return (
    <>
      <Wrapper>
        {isLoading && <Loader />}
        <FilterSectionStyled>
          <h1>{t('databases_feature.specializations.specialization')}</h1>
          <div>
            <Input
              id={'search'}
              type="search"
              placeholder={
                t(
                  'databases_feature.specializations.specializations_search_placeholder',
                ) || ''
              }
              value={searchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              onClear={() => setSearchItem('')}
            />
            <PrimaryButton onClick={() => setIsOpenAddModal(true)}>
              <AddIcon />
              {t('databases_feature.add_new')}
            </PrimaryButton>
          </div>
        </FilterSectionStyled>
        <TableSpecializations
          data={data}
          columns={columns}
          currentPage={currentPage}
          totalPages={totalPages || 0}
          setCurrentPage={(page) => getCurrentData(page)}
          handleDelete={(id) => {
            setDeletedItemId(id);
            setOnRemove(true);
          }}
          handleActivate={(id) => {
            handleActivateSpecialization(id);
          }}
          handleEditModalOpen={handleOpenEditSpecializationModal}
        />
      </Wrapper>
      <AddSpecializationModal
        isOpen={isOpenAddModal}
        onClose={handleCloseAddModal}
        onAdd={handleAddSpecialization}
        isEdit={false}
      />
      <AddSpecializationModal
        isOpen={isOpenEditModal && !!specializationToUpdate}
        onClose={() => setIsOpenEditModal(false)}
        onEdit={handleEditSpecialization}
        isEdit={true}
        initialData={specializationToUpdate}
        specializationId={specializationToUpdateId}
      />
      {created && (
        <SuccessModal
          onClose={handleCloseSuccessAddModal}
          typeName={`${t('modal_success.title_specialization')} ${t(
            'modal_success.title_part2',
          )}`}
        />
      )}
      {edited && (
        <SuccessModal
          onClose={handleCloseSuccessEditModal}
          typeName={`${t('modal_success.title_specialization')} ${t(
            'modal_success.title_part2',
          )}`}
        />
      )}
      {deleted && (
        <SuccessModal
          onClose={handleCloseSuccessDeleteModal}
          typeName={`${t('modal_success.title_specialization')} ${t(
            'modal_success.title_part2_deleted',
          )}`}
        />
      )}
      {activated && (
        <SuccessModal
          onClose={handleCloseSuccessActivateModal}
          typeName={`${t('modal_success.title_specialization')} ${t(
            'modal_success.title_part2_activated',
          )}`}
        />
      )}
      <DeleteModal
        isOpen={onRemove}
        text={`${t('delete_modal.titlePart1')} specialization?`}
        onClose={handleRemoveClose}
        onSubmit={handleRemoveSpecialization}
      />
    </>
  );
};

export default SpecializationTable;
