import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton, PrimaryButton, Input } from 'common/components';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { ButtonWrapper, MainText, StyledModal } from './styles';
import { Modal } from '@mui/material';
import { AddInsuranceModalProps } from './models';
import { useAddInsurance } from 'features/feature-database/hooks/useAddInsurance';

const AddInsuranceModal: FC<AddInsuranceModalProps> = ({
  isOpen,
  onClose,
  onAdd,
  onEdit,
  isEdit,
  initialData,
  insuranceId,
}) => {
  const { t } = useTranslation();
  const actionPrefix = isEdit ? 'edit' : 'add';

  const { register, errors, handleSubmit, getValues, reset, setValue } =
    useAddInsurance({
      requiredError: t('errors.required'),
    });

  const handleAddInsurance = () => {
    onAdd && onAdd({ name: getValues('name'), code: getValues('code') }, reset);
  };

  const handleEditInsurance = () => {
    if (onEdit && insuranceId) {
      onEdit({ name: getValues('name'), code: getValues('code') }, reset);
    }
  };

  const handleActionOnSubmit = isEdit
    ? handleEditInsurance
    : handleAddInsurance;

  if (initialData?.name) setValue('name', initialData.name);
  if (initialData?.code) setValue('code', initialData.code);

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <MainText>{t(`${actionPrefix}_insurance_modal.title`)}</MainText>
          <Input
            register={register}
            id={'name'}
            label={t(`${actionPrefix}_insurance_modal.name`)}
            type={'text'}
            placeholder={t(`${actionPrefix}_insurance_modal.name_placeholder`)}
            errorMessage={errors?.name?.message}
          />
          <Input
            register={register}
            id={'code'}
            label={t(`${actionPrefix}_insurance_modal.code`)}
            type={'text'}
            placeholder={t(`${actionPrefix}_insurance_modal.code_placeholder`)}
            errorMessage={errors?.code?.message}
          />
          <ButtonWrapper>
            <SecondaryButton onClick={onClose}>
              {t('global.cancel')}
            </SecondaryButton>
            <PrimaryButton
              type="button"
              onClick={handleSubmit(handleActionOnSubmit)}
            >
              {t('global.save')}
            </PrimaryButton>
          </ButtonWrapper>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default AddInsuranceModal;
