import { createAsyncThunk } from '@reduxjs/toolkit';
import { LanguagesActions } from 'application/constants/actions';
import { AxiosError } from 'axios';
import { LanguagesAPI } from 'integration/api/languages';

export const getSupportedLanguages = createAsyncThunk(
  LanguagesActions.getSupportedLanguages,
  async (_, { rejectWithValue }) => {
    try {
      const { data: responseData } = await LanguagesAPI.getSupportedLanguages();

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
