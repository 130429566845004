import { createSlice } from '@reduxjs/toolkit';
import { LanguagesState } from './models';
import { getSupportedLanguages } from './ActionCreators';

const initialState: LanguagesState = {
  isLoading: false,
  error: '',
  supportedLanguages: [],
  total: 0,
  page: 0,
  totalPages: 0,
};

const languagesSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Fetch cities
    builder.addCase(getSupportedLanguages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSupportedLanguages.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getSupportedLanguages.fulfilled, (state, action) => {
      state.supportedLanguages = action.payload.entities;
      state.isLoading = false;
    });
  },
});

// export const {} = languagesSlice.actions;

export default languagesSlice.reducer;
