import React from 'react';
import 'reset-css';
import ReactDOM from 'react-dom/client';
import App from './application/App';
import reportWebVitals from './reportWebVitals';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
