import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PickIcon } from 'application/assets/pick.svg';
import { StyledTr } from './styles';

const NoDataElement: FC = () => {
  const { t } = useTranslation();
  return (
    <StyledTr>
      <td colSpan={5}>
        <div>
          <PickIcon />
          <p>{t('users_managment.no_data')}</p>
        </div>
      </td>
    </StyledTr>
  );
};
export default NoDataElement;
