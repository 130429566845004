import {
  CommonEntitiesListResponse,
  Specialization,
  SpecializationCreationDto,
  SpecializationResponseDto,
  SpecializationUpdateDto,
} from '@docbay/schemas';
import axiosInstance from 'integration/api/axiosInstance';
import { fetchPaginatedSpecializationProps } from 'common/types/paginatedSpecializations';

export const SpecializationsAPI = {
  fetchSpecializations: () =>
    axiosInstance.get<Specialization[]>('/specializations'),
  fetchSpecializationsById: (id: string) =>
    axiosInstance.get<Specialization>(`/specializations/${id}`),
  editSpecialization: ({
    data,
    id,
  }: {
    data: SpecializationUpdateDto;
    id: string;
  }) =>
    axiosInstance.patch<SpecializationResponseDto>(
      `/specializations/${id}`,
      data,
    ),
  fetchPaginatedSpecializations: ({
    text,
    page,
    limit,
  }: fetchPaginatedSpecializationProps) => {
    const params = {
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(text ? { text } : {}),
      withDeactivated: true,
    };
    return axiosInstance.get<CommonEntitiesListResponse<Specialization>>(
      '/specializations/paginated-specializations',
      { params },
    );
  },
  addSpecialization: (params: SpecializationCreationDto) =>
    axiosInstance.post<SpecializationResponseDto>('/specializations', params),
  deactivateSpecialization: (id: string) =>
    axiosInstance.patch(`/specializations/${id}/deactivate`),
  activateSpecialization: (id: string) =>
    axiosInstance.patch(`/specializations/${id}/reactivate`),
};
