import React, { useEffect } from 'react';
import { CountryDropdown, Input } from 'common/components';
import {
  ClinicDataWrapper,
  ClinicFormWrapper,
  TitleWrapper,
  DeleteClinic,
} from './styles';
import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExternalClinicResponseDto } from '@docbay/schemas/dist/external';
import { ReactComponent as ExpandLessIcon } from 'application/assets/expand-less.svg';
import { ReactComponent as ExpandMoreIcon } from 'application/assets/expand-more.svg';
import ClinicsPhones from '../ClinicsPhones';
import ClinicsEmails from '../ClinicsEmails';
import { SupportedCountriesDto } from 'common/types/countries';

interface Props {
  clinic: ExternalClinicResponseDto;
  index: number;
  changeClinicData: (obj: ExternalClinicResponseDto, index: number) => void;
  watch: UseFormWatch<any>;
  getValues: UseFormGetValues<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<any>;
  clearErrors: UseFormClearErrors<any>;
  setError: UseFormSetError<any>;
  errors: any;
  showDeleteButton: boolean;
  deleteClinic: (id: string, index: number) => void;
  control?: any;
}

const ClinicForm = ({
  clinic,
  index,
  watch,
  register,
  setValue,
  errors,
  clearErrors,
  setError,
  control,
  getValues,
  showDeleteButton,
  deleteClinic,
}: Props) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(true);

  const handleDeleteClinic = () => {
    deleteClinic(clinic.id!, index);
  };

  useEffect(() => {
    if (clinic) {
      const phones = clinic?.phones?.length ? clinic?.phones : [''];
      const emails = clinic?.emails?.length ? clinic?.emails : [''];

      clinic?.id && setValue('id', clinic?.id);
      clinic?.name && setValue(`clinics[${index}].name`, clinic?.name);
      clinic?.city && setValue(`clinics[${index}].city`, clinic?.city || '');
      clinic?.region &&
        setValue(`clinics[${index}].region`, clinic?.region || '');
      clinic?.street &&
        setValue(`clinics[${index}].street`, clinic?.street || '');
      clinic?.latitude &&
        setValue(`clinics[${index}].latitude`, clinic?.latitude || '');
      clinic?.longitude &&
        setValue(`clinics[${index}].longitude`, clinic?.longitude || '');

      setValue(`clinics[${index}].phones`, phones);

      setValue(`clinics[${index}].emails`, emails);
      setValue(`clinics[${index}].supportedCountry`, clinic?.supportedCountry);
    }
  }, [clinic.id]);

  const currentClinicError = errors?.clinics ? errors?.clinics[index] : [];

  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <ClinicDataWrapper>
      <TitleWrapper>
        <div onClick={handleExpand}>
          {watch(`clinics[${index}].name`)?.length
            ? watch(`clinics[${index}].name`)
            : `${t('users_managment.doctor_create.clinic')} ${index + 1}`}
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
        {showDeleteButton && (
          <DeleteClinic onClick={handleDeleteClinic}>
            {t('users_managment.doctor_create.delete_clinic') || ''}
          </DeleteClinic>
        )}
      </TitleWrapper>
      {expanded && (
        <ClinicFormWrapper key={`clinic_${index}`}>
          <Input
            id={`clinics[${index}].name`}
            label={t('users_managment.doctor_create.clinic_name') || ''}
            placeholder={t(
              'users_managment.doctor_create.clinic_name_placeholder',
            )}
            type="text"
            register={register}
            errorMessage={currentClinicError?.name?.message}
          />
          <ClinicsPhones
            errors={errors}
            control={control}
            register={register}
            getValues={getValues}
            setValue={setValue}
            clearErrors={clearErrors}
            setError={setError}
            clinicIndex={index}
          />
          <ClinicsEmails
            errors={errors}
            control={control}
            register={register}
            getValues={getValues}
            clinicIndex={index}
          />
          <CountryDropdown
            id={`clinic-country-dropdown_${index}`}
            label={t('country_of_practice')}
            value={watch(`clinics[${index}].supportedCountry`)}
            onChange={(country) =>
              setValue(
                `clinics[${index}].supportedCountry`,
                country as SupportedCountriesDto,
                {
                  shouldValidate:
                    !!errors[`clinics[${index}].supportedCountry`],
                },
              )
            }
          />
          <Input
            id={`clinics[${index}].street`}
            label={
              t('users_managment.doctor_create.clinic_street_address') || ''
            }
            placeholder={t(
              'users_managment.doctor_create.clinic_street_address_placeholder',
            )}
            type="text"
            register={register}
          />
          <Input
            id={`clinics[${index}].city`}
            label={t('users_managment.doctor_create.clinic_locality') || ''}
            placeholder={t(
              'users_managment.doctor_create.clinic_locality_placeholder',
            )}
            type="text"
            register={register}
          />
          <Input
            id={`clinics[${index}].region`}
            label={t('users_managment.doctor_create.clinic_region') || ''}
            placeholder={t(
              'users_managment.doctor_create.clinic_region_placeholder',
            )}
            type="text"
            register={register}
          />
          <Input
            id={`clinics[${index}].latitude`}
            label={t('users_managment.doctor_create.clinic_latitude') || ''}
            placeholder={t(
              'users_managment.doctor_create.clinic_latitude_placeholder',
            )}
            type="text"
            register={register}
          />
          <Input
            id={`clinics[${index}].longitude`}
            label={t('users_managment.doctor_create.clinic_longitude') || ''}
            placeholder={t(
              'users_managment.doctor_create.clinic_longitude_placeholder',
            )}
            type="text"
            register={register}
          />
        </ClinicFormWrapper>
      )}
    </ClinicDataWrapper>
  );
};

export default ClinicForm;
