import { createAsyncThunk } from '@reduxjs/toolkit';
import { PatientsActions } from 'application/constants/actions';
import { AxiosError } from 'axios';
import { PatientsApi } from 'integration/api/patients';
import { fetchPatientsProps } from 'common/types/patients';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchPatients = createAsyncThunk(
  PatientsActions.fetchPatients,
  async (params: fetchPatientsProps, { rejectWithValue }) => {
    try {
      const { data: responseData } = await PatientsApi.fetchPatients(params);

      await delay(1000);

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
