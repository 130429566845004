import { getToken } from 'application/sessionStorage/auth';
import axios from 'axios';

import { ApplicationType, x_app_type } from '../../application';

const axiosTokenInstance = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      [x_app_type]: ApplicationType.AdminWeb,
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'Application/json',
    },
  });

  axiosInstance.interceptors.request.use(
    async function (config) {
      return {
        ...config,
        ...(getToken()
          ? {
              headers: {
                Authorization: `Bearer ${getToken()}`,
              },
            }
          : {}),
      };
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

export default axiosTokenInstance;
