import React, { FC, useState } from 'react';
import { ProfessionalNameStyled, StyledTr, ActionWrapper } from './styles';
import { Box } from '@mui/system';
import { ProfessionalItemProps } from './modules';
import { ReactComponent as EditIcon } from 'application/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'application/assets/delete.svg';
import { useAppDispatch } from 'common/hooks/redux';
import { setEditedUser } from 'application/store/reducers/InternalUsers/InternalUsersSlice';
import { UserType } from '@docbay/schemas/dist/user/user.types';
import DeleteModal from 'features/feature-users-managment/components/DeleteModal';
import { deleteInternalUser } from 'application/store/reducers/InternalUsers/ActionCreators';
import { SuccessModal } from 'common/components';
import { useTranslation } from 'react-i18next';

const InternalUsersItem: FC<ProfessionalItemProps> = ({
  user,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  //TODO: change when will have types
  //@ts-ignore
  const userTypes = user?.user?.userTypes
    ?.map((item: string) => String(item))
    .join(', ');

  const editUser = () => {
    onEdit();
    dispatch(setEditedUser(user));
  };

  const isAdminOwner = user.user?.userTypes?.includes(UserType.AdminOwner);

  const handleRemoveClose = () => {
    setIsShowDeleteModal(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteInternalUser({ id: user.id, type: user?.user?.userTypes[0]! }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        handleRemoveClose();
        setIsShowSuccessModal(true);
        onDelete();
      }
    });
  };

  return (
    <StyledTr>
      <td>
        <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
          <ProfessionalNameStyled>
            <p>{user.firstName + ' ' + user.lastName}</p>
            <span>{user.user?.email}</span>{' '}
          </ProfessionalNameStyled>
        </Box>
      </td>
      <td>{userTypes}</td>
      <td>
        {!isAdminOwner && (
          <ActionWrapper>
            <button onClick={editUser}>
              <EditIcon />
            </button>
            <button onClick={() => setIsShowDeleteModal(true)}>
              <DeleteIcon />
            </button>
          </ActionWrapper>
        )}
      </td>
      <DeleteModal
        isOpen={isShowDeleteModal}
        text={t('users_managment.delete_user')}
        onClose={handleRemoveClose}
        onSubmit={handleDelete}
      />
      <SuccessModal
        title={t('users_managment.success_deleted_modal_title')}
        opened={isShowSuccessModal}
        onClose={() => setIsShowSuccessModal(false)}
      />
    </StyledTr>
  );
};

export default InternalUsersItem;
