import { styled } from '@mui/system';

export const Wrapper = styled('main')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  overflow-y: auto;
  padding: 32px;
`;
