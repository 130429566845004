import { createSlice } from '@reduxjs/toolkit';
import {
  getProfilesFromSessionStorage,
  setProfilesInSessionStorage,
} from 'application/sessionStorage/auth';
import {
  usersLogin,
  signOut,
  usersRefreshToken,
  changePasswordSaas,
  createPassword,
} from './ActionCreators';
import { authService } from 'common';

const initialState = {
  isLoading: false,
  error: '',
  isAuth: false,
  isSuccessResetPassword: false,
  isValidCode: false,
  isCreateNewPassword: false,
  isPasswordChanged: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setAuthError: (state, action) => {
      state.error = action.payload;
    },
    setIsSuccessResetPassword: (state, actions) => {
      state.isSuccessResetPassword = actions.payload!;
    },
    setIsValidCode: (state, actions) => {
      state.isValidCode = actions.payload!;
    },
    setIsCreateNewPassword: (state, actions) => {
      state.isCreateNewPassword = actions.payload!;
    },
    setIsPasswordChanged: (state, actions) => {
      state.isPasswordChanged = false;
    },
    clearStore: () => {
      authService.cleanStorage();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(usersLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(usersLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuth = false;
      state.error = action.payload as string;
    });
    builder.addCase(usersLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuth = true;

      const { token, refreshToken, user } = action.payload;

      setProfilesInSessionStorage([
        {
          id: user?.id!,
          email: user?.email!,
          firstName: user?.firstName!,
          lastName: user?.lastName!,
          photo: user?.photo!,
          token,
          refreshToken,
        },
      ]);
    });
    // user sign out
    builder.addCase(signOut.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signOut.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuth = false;
    });
    // refresh token
    builder.addCase(usersRefreshToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(usersRefreshToken.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuth = false;
      state.error = action.payload as string;
      authService.cleanStorage();
      clearStore();
    });
    builder.addCase(usersRefreshToken.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuth = true;

      const profiles = getProfilesFromSessionStorage();
      const filteredProfiles = profiles?.map((item, index) =>
        index !== 0! ? { ...item, token: action.payload.token } : item,
      );
      setProfilesInSessionStorage(filteredProfiles || []);
    });
    // change password saas
    builder.addCase(changePasswordSaas.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePasswordSaas.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(changePasswordSaas.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    // create password
    builder.addCase(createPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setAuthError,
  setIsSuccessResetPassword,
  setIsValidCode,
  setIsCreateNewPassword,
  setIsPasswordChanged,
  clearStore,
} = usersSlice.actions;

export default usersSlice.reducer;
