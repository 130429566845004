import React, { FC, useEffect } from 'react';
import { Wrapper, GroupSection } from './styles';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'common/hooks/redux';
import { useFieldArray } from 'react-hook-form';
import { AddMetrics, FieldType } from '../../types';
import { Props } from './models';
import UnitOfMeasurementDropdown from 'common/components/UnitOfMeasurementDropdown';

const UnitsSection: FC<Props> = ({
  control,
  errors,
  setValue,
  watch,
  isEdit,
}) => {
  const { t } = useTranslation();
  const { supportedLanguages } = useAppSelector((state) => state.languages);
  const { editedMetric } = useAppSelector(
    (state) => state.specializationMetrics,
  );

  const prependKey = 'unit';
  const { fields, append } = useFieldArray<AddMetrics>({
    control,
    name: prependKey,
  });

  useEffect(() => {
    if (supportedLanguages) {
      supportedLanguages.forEach((lang) => {
        const currentUnit = editedMetric?.localizations?.find(
          (item) => item.languageIsoCode === lang.isoCode,
        );

        if (isEdit && supportedLanguages && editedMetric) {
          append({
            type: 'UnitName' as FieldType,
            id: currentUnit?.unitOfMeasurement?.id,
            lang: lang.isoCode,
          });
        } else if (supportedLanguages && !isEdit) {
          append({
            type: 'UnitName' as FieldType,
            id: '',
            lang: lang.isoCode,
          });
        }
      });
    }
  }, [supportedLanguages, isEdit, editedMetric]);

  return (
    <Wrapper>
      <p>{t('specialization_metrics.unit_of_measurement_title')}</p>
      <GroupSection>
        {fields.map((item, index) => {
          const id = `${prependKey}.${index}.id`;
          const error = errors?.[prependKey]?.[index]?.id?.message;

          const currentLang = supportedLanguages.find(
            (lang) => lang.isoCode === item.lang,
          );
          const label = `${t(
            'specialization_metrics.unit_of_measurement_inp',
          )} (${currentLang?.name} ${t('specialization_metrics.lang')})`;

          const placeholder = `${t(
            'specialization_metrics.unit_of_measurement_placeholder',
          )} ${currentLang?.name} ${t('specialization_metrics.lang')}`;

          return (
            <UnitOfMeasurementDropdown
              key={item.id}
              id={id}
              placeholder={placeholder}
              // @ts-ignore  will be fixed in the next task
              value={String(watch(id))}
              onChange={(value) => {
                // @ts-ignore
                setValue(String(id), String(value.id), {
                  shouldValidate: true,
                });
              }}
              label={label}
              withSearch
              errorMessage={error}
            />
          );
        })}
      </GroupSection>
    </Wrapper>
  );
};

export default UnitsSection;
