import { styled } from '@mui/system';

export const Wrapper = styled('article')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.common.grey200};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
  min-height: 100%;
  margin-top: 20px;
  background: #fff;
`;
