import React, { FC, useEffect, useState } from 'react';
import { StyledTr, ActionWrapper } from './styles';
import { ProfessionalItemProps } from './modules';
import { ReactComponent as EditIcon } from 'application/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'application/assets/delete.svg';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import ConfirmDeleteProfessional from '../ConfirmDeleteProfessional';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { deleteProfessionals } from 'application/store/reducers/ImportProfessionals/ActionCreators';
import { setSuccessDeleted } from 'application/store/reducers/ImportProfessionals/ImportProfessionalsSlice';

const ImportedDoctorsItem: FC<ProfessionalItemProps> = ({
  doctor,
  setSelectedProfessionalId,
}) => {
  const dispatch = useAppDispatch();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { localizeNameKey } = useLocalizeKey();
  const { isDeleted } = useAppSelector((state) => state.importProfessionals);

  const getSpecializations = () => {
    const specializationsList = doctor.specializations?.map(
      (specialization) => specialization[localizeNameKey],
    );
    return specializationsList?.join(', ');
  };

  const handleDeleteProfessional = () => {
    dispatch(deleteProfessionals(doctor.id)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        dispatch(setSuccessDeleted(doctor));
      }
    });
  };

  const handleEditProfessional = () => {
    setSelectedProfessionalId(doctor.id);
  };

  useEffect(() => {
    isDeleted && setShowConfirmDelete(false);
  }, [isDeleted]);

  const getAllPhones = () => {
    return doctor?.phones?.map((phone) => {
      return <p key={phone}>{phone}</p>;
    });
  };

  const getAllEmails = () => {
    return doctor?.emails?.map((email) => {
      return <p key={email}>{email}</p>;
    });
  };

  return (
    <StyledTr>
      <td>
        <p>
          {doctor.firstName} {doctor.lastName}
        </p>
      </td>
      <td>{getAllPhones()}</td>
      <td>{getAllEmails()}</td>
      <td>{getSpecializations()}</td>
      <td>
        <ActionWrapper>
          <button>
            <EditIcon onClick={() => handleEditProfessional()} />
          </button>
          <button onClick={() => setShowConfirmDelete(true)}>
            <DeleteIcon />
          </button>
        </ActionWrapper>
      </td>
      <ConfirmDeleteProfessional
        doctorName={`${doctor.firstName} ${doctor.lastName}`}
        onClose={() => setShowConfirmDelete(false)}
        isOpen={showConfirmDelete}
        onSubmit={handleDeleteProfessional}
      />
    </StyledTr>
  );
};

export default ImportedDoctorsItem;
