import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  border-radius: 12px;
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  border: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const FilterSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  background: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  > h1 {
    width: 100%;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: 0.1px;
    margin-bottom: 20px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div:first-of-type {
      width: 100%;
      max-width: 375px;
    }

    button {
      text-decoration: none;
      color: ${({ theme }) => theme.color.grey900};
      margin-left: 10px;
      width: fit-content;
      min-width: 152px;
      svg {
        path {
          fill: ${({ theme }) => theme.color.grey900};
        }
      }
    }
  }
`;
