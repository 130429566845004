import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { ContactsChildProps } from './models';
import { AddProfessionalDataT } from 'features/feature-users-managment/types/AddProfessionalDataT';
import AddAnother from '../AddAnother';
import { InputPhone } from 'common/components';
import {
  Close,
  InputContainer,
  InputWrapper,
  LabelWrapper,
  WrapperContact,
} from './styles';
import { isValidPhoneNumber } from 'libphonenumber-js';

const Phones = ({
  errors,
  control,
  register,
  setValue,
  clearErrors,
  setError,
  getValues,
}: ContactsChildProps) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray<AddProfessionalDataT>({
    control,
    name: 'phones',
  });

  const handleAddAnother = () => {
    append('');
  };

  useEffect(() => {
    !fields.length && handleAddAnother();
  }, [fields]);

  const removeContact = (index: number) => {
    remove(index);
  };

  const handlePhoneChange = (val: string, dialCode: string, index: number) => {
    const path = 'phones';
    setValue(`${path}.${index}` as any, val, {
      shouldValidate: val !== dialCode,
    });
    if (val === dialCode || isValidPhoneNumber(val)) {
      clearErrors(`${path}.${index}` as any);
    } else {
      setError(`${path}.${index}` as any, {
        message: t('global_error.invalid_phone_number') || '',
      });
    }
  };

  return (
    <WrapperContact>
      <LabelWrapper
        isError={errors?.phones?.length || errors?.primaryPhone?.message}
      >
        <label>{t('users_managment.doctor_create.doctor_phone')}</label>
      </LabelWrapper>
      {fields.map((field, index) => {
        const path = 'phones';
        const id = `${path}.${index}`;
        const error =
          errors?.phones?.[index]?.message || errors?.primaryPhone?.message;

        return (
          <InputWrapper key={field.id} last={index === fields.length - 1}>
            <InputContainer>
              <InputPhone
                id={id}
                register={register}
                errorMessage={error}
                value={String(getValues(`phones.${index!}`))}
                isValid={true}
                hideCountryCode
                onChange={(val, dialCode) =>
                  handlePhoneChange(val, dialCode, index)
                }
              />
            </InputContainer>
            {index !== 0 && <Close onClick={() => removeContact(index)} />}
          </InputWrapper>
        );
      })}

      <AddAnother
        title={t('users_managment.doctor_create.add_another')}
        onClick={handleAddAnother}
      />
    </WrapperContact>
  );
};

export default Phones;
