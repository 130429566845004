import styled from 'styled-components';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    font-family: Mulish;
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    letter-spacing: 0.2px;
  }
`;

export const GroupSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.grey100};
  border: 1px solid ${({ theme }) => theme.color.grey300};

  > div {
    width: 100%;
  }
`;
