import React from 'react';
import { platformMetricsList } from '../../constants';
import { useTranslation } from 'react-i18next';
import { PlatformMetricTableNameProps } from './models';

const PlatformMetricTableName = ({
  metricName,
  key,
}: PlatformMetricTableNameProps) => {
  const { t } = useTranslation();

  const metricLabel = platformMetricsList?.find(
    (metric) => metric.value === metricName.value,
  );

  if (!metricLabel) return <></>;

  const metricText = t(`platformMetricsList.${metricLabel.value}`);
  const textArr = metricText?.split('\n');

  return (
    <th key={key}>
      {textArr.map((item) => {
        return <span key={item}>{item}</span>;
      })}
    </th>
  );
};

export default PlatformMetricTableName;
