import React, { FC, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '@mui/material/usePagination';
import { ButtonStyled, PageButton, Wrapper } from './styles';
import { ReactComponent as ArrowBackIcon } from 'application/assets/arrow-back.svg';
import { ReactComponent as ArrowForwardIcon } from 'application/assets/arrow-forward.svg';
import { PaginationProps } from './modules';

const Pagination: FC<PaginationProps> = ({
  pages,
  setCurrentPage,
  itemCount,
  currentPage,
}) => {
  const { t } = useTranslation();
  const { items } = usePagination({
    count: pages,
    page: currentPage,
    onChange: (e, page) => {
      setCurrentPage(page);
    },
  });

  useEffect(() => {
    const selectedPage = items.find((item) => item.selected);
    if (selectedPage && selectedPage.page !== currentPage) {
      setCurrentPage(selectedPage.page!);
    }
  }, [items, currentPage]);

  useEffect(() => {
    if (itemCount === 0) {
      setCurrentPage(currentPage === 1 ? 1 : currentPage! - 1);
    }
  }, [itemCount, currentPage]);

  const prevButton = useMemo(
    () => items.find((item) => item.type === 'previous'),
    [items, currentPage],
  );
  const nextButton = useMemo(
    () => items.find((item) => item.type === 'next'),
    [items, currentPage],
  );
  const pageList = useMemo(
    () =>
      items.filter((item) => item.type !== 'next' && item.type !== 'previous'),
    [items, currentPage],
  );
  return (
    <Wrapper>
      <ButtonStyled {...prevButton!} type="button">
        <ArrowBackIcon /> {t('global.back')}
      </ButtonStyled>{' '}
      <div>
        {pageList.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <PageButton currentPage={selected} type="button" {...item}>
                {page}
              </PageButton>
            );
          }
          return <div key={index}>{children}</div>;
        })}
      </div>
      <ButtonStyled {...nextButton} type="button">
        {t('global.next')} <ArrowForwardIcon />
      </ButtonStyled>
    </Wrapper>
  );
};

export default memo(Pagination);
