import React, { FC } from 'react';
import { Wrapper } from './styles';
import Main from './components/Main';

const UsersManagment: FC = () => {
  return (
    <Wrapper>
      <Main />
    </Wrapper>
  );
};

export default UsersManagment;
