import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ClinicsManagementActions } from 'application/constants/actions';
import { ClinicsManagementAPI } from 'integration/api/clinicsManagement';
import {
  GetClinicsParams,
  UpdateClinicManagementData,
} from 'common/types/clinicsManagement';

export const getClinics = createAsyncThunk(
  ClinicsManagementActions.getClinics,
  async (params: GetClinicsParams, { rejectWithValue }) => {
    try {
      const { data } = await ClinicsManagementAPI.getClinics(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getClinicsSuggestions = createAsyncThunk(
  ClinicsManagementActions.getClinicsSuggestions,
  async (search: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicsManagementAPI.getClinicsSuggestions(search);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getClinicById = createAsyncThunk(
  ClinicsManagementActions.getClinicById,
  async (clinicId: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicsManagementAPI.getClinicById(clinicId);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateClinic = createAsyncThunk(
  ClinicsManagementActions.updateClinic,
  async (data: UpdateClinicManagementData, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ClinicsManagementAPI.updateClinic(
        data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteClinic = createAsyncThunk(
  ClinicsManagementActions.deleteClinic,
  async (clinicId: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ClinicsManagementAPI.deleteClinic(
        clinicId,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
