import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useCreatePasswordSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const minLengthError = t('errors.min_length_error');
  const passwordRulesError = t('errors.password_rules_error');

  return yup.object({
    password: yup
      .string()
      .required(requiredError)
      .min(10, minLengthError)
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.{10,})/, {
        message: passwordRulesError,
      }),
    confirmPassword: yup
      .string()
      .required(requiredError)
      .min(10, minLengthError)
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.{10,})/, {
        message: passwordRulesError,
      }),
  });
};
