import {
  CommonEntitiesListResponse,
  SupportedLanguageResponse,
} from '@docbay/schemas';
import axiosInstance from 'integration/api/axiosInstance';

export const LanguagesAPI = {
  getSupportedLanguages: async () => {
    return await axiosInstance.get<
      CommonEntitiesListResponse<SupportedLanguageResponse>
    >('/languages/supported');
  },
};
