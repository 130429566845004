import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

    > p {
      color: ${({ theme }) => theme.color.grey700};
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
    }
  }

  &:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    & > td {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
`;

export const ClinicNameStyled = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  > p {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    margin-left: 12px;
  }
`;

export const IconStyled = styled.div`
  display: flex;
  min-width: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.blue800};
  border-radius: 50%;
`;

export const ActionButton = styled(Link)`
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;

  > svg {
    width: 24px;
    height: 24px;
    path {
      fill: ${({ theme }) => theme.color.blue700};
    }
  }
`;
