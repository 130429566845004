import React, { FC, useEffect } from 'react';
import { Wrapper, GroupSection } from './styles';
import { Input } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'common/hooks/redux';
import { useFieldArray } from 'react-hook-form';
import { AddMetrics, FieldType } from '../../types';
import { Props } from './models';

const MetricNamesSection: FC<Props> = ({
  control,
  errors,
  register,
  isEdit,
}) => {
  const { t } = useTranslation();
  const { supportedLanguages } = useAppSelector((state) => state.languages);
  const { editedMetric } = useAppSelector(
    (state) => state.specializationMetrics,
  );

  const prependKey = 'metric';
  const { fields, append } = useFieldArray<AddMetrics>({
    control,
    name: prependKey,
  });

  useEffect(() => {
    if (supportedLanguages) {
      supportedLanguages.forEach((lang) => {
        const currentMetric = editedMetric?.localizations?.find(
          (item) => item.languageIsoCode === lang.isoCode,
        );

        if (isEdit && supportedLanguages && editedMetric) {
          append({
            type: 'MetricName' as FieldType,
            name: currentMetric?.translation,
            lang: lang.isoCode,
          });
        } else if (supportedLanguages && !isEdit) {
          append({
            type: 'MetricName' as FieldType,
            name: '',
            lang: lang.isoCode,
          });
        }
      });
    }
  }, [supportedLanguages, editedMetric, isEdit]);

  return (
    <Wrapper>
      <p>{t('specialization_metrics.metric_name_title')}</p>
      <GroupSection>
        {fields.map((item, index) => {
          const id = `${prependKey}.${index}.name`;
          const error = errors?.[prependKey]?.[index]?.name?.message;

          const currentLang = supportedLanguages.find(
            (lang) => lang.isoCode === item.lang,
          );
          const label = `${t('specialization_metrics.metric_name_inp')} (${
            currentLang?.name
          } ${t('specialization_metrics.lang')})`;

          const placeholder = `${t(
            'specialization_metrics.metric_name_placeholder',
          )} ${currentLang?.name} ${t('specialization_metrics.lang')}`;

          return (
            <Input
              key={item.id}
              type={'text'}
              label={label}
              placeholder={placeholder}
              register={register}
              id={id}
              errorMessage={error}
            />
          );
        })}
      </GroupSection>
    </Wrapper>
  );
};

export default MetricNamesSection;
