import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SaasAdminCreationDto, UserType } from '@docbay/schemas';
import {
  SecondaryButton,
  PrimaryButton,
  Input,
  Loader,
  SuccessModal,
} from 'common/components';
import Dropdown from 'common/components/Dropdown';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  createInternalUser,
  editInternalUser,
} from 'application/store/reducers/InternalUsers/ActionCreators';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { useInternalUserSchema } from 'features/feature-users-managment/hooks/useInternalUserSchema';
import { useUsersTypesList } from 'features/feature-users-managment/hooks/useUsersTypes';
import { InternalUserAddModalProps } from './models';
import {
  StyledModal,
  Header,
  FormStyled,
  FieldsSection,
  ButtonSection,
} from './styles';
import {
  setSuccessCreated,
  setSuccessEdited,
} from 'application/store/reducers/InternalUsers/InternalUsersSlice';

const InternalUserActionModal: FC<InternalUserAddModalProps> = ({
  title,
  opened,
  onClose,
  isEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const internalUserSchema = useInternalUserSchema();
  const { isLoading, successCreated, successEdited } = useAppSelector(
    (state) => state.internalUsers,
  );
  const usersTypesList = useUsersTypesList();
  const { editedUser } = useAppSelector((state) => state.internalUsers);

  const {
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SaasAdminCreationDto>({
    resolver: yupResolver(internalUserSchema),
  });

  useEffect(() => {
    if (editedUser) {
      setValue('firstName', editedUser?.firstName!);
      setValue('lastName', editedUser?.lastName!);
      setValue('email', editedUser?.user?.email!);
      setValue('userType', editedUser?.user?.userTypes[0]!);
    }
  }, [editedUser]);

  const handleSelectUserType = (type: UserType) => {
    setValue('userType', type, { shouldValidate: !!errors.userType?.message });
  };

  const onSubmit = async (data: SaasAdminCreationDto) => {
    const editData = {
      ...(editedUser?.firstName !== watch('firstName')
        ? { firstName: watch('firstName') }
        : {}),
      ...(editedUser?.lastName !== watch('lastName')
        ? { lastName: watch('lastName') }
        : {}),
      ...(editedUser?.user?.email !== watch('email')
        ? { email: watch('email') }
        : {}),
      userType: watch('userType'),
    };
    const response = isEdit
      ? await dispatch(
          editInternalUser({ id: editedUser?.id!, data: editData }),
        )
      : await dispatch(createInternalUser(data));

    if (!isEdit && response.meta.requestStatus === 'rejected') {
      setError('email', { message: t('errors.create_admin') });
    }
  };

  const handleCloseModal = () => {
    reset();
    onClose();
  };

  const handleCloseSuccessModal = () => {
    dispatch(setSuccessCreated(false));
    dispatch(setSuccessEdited(false));
    handleCloseModal();
  };

  return (
    <Modal open={opened}>
      <>
        {isLoading && <Loader />}
        {successCreated || successEdited ? (
          <SuccessModal
            title={
              successCreated
                ? t('users_managment.internal_users_create.success_modal_title')
                : t(
                    'users_managment.internal_users_create.success_edit_modal_title',
                  ) || ''
            }
            opened={successCreated || successEdited}
            onClose={handleCloseSuccessModal}
          />
        ) : (
          <StyledModal>
            <Header>
              <h1>{title}</h1>
              <button type="button" onClick={handleCloseModal}>
                <CloseIcon />
              </button>
            </Header>
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
              <FieldsSection>
                <Input
                  id={'firstName'}
                  label={t('global.first_name') || ''}
                  placeholder={t(
                    'users_managment.internal_users_create.first_name_placeholder',
                  )}
                  type="text"
                  register={register}
                  errorMessage={errors.firstName?.message}
                />
                <Input
                  id={'lastName'}
                  label={t('global.last_name') || ''}
                  placeholder={t(
                    'users_managment.internal_users_create.last_name_placeholder',
                  )}
                  type="text"
                  register={register}
                  errorMessage={errors.lastName?.message}
                />
                <Input
                  id={'email'}
                  label={t('global.email') || ''}
                  placeholder={t(
                    'users_managment.internal_users_create.email_placeholder',
                  )}
                  type="email"
                  register={register}
                  errorMessage={errors.email?.message}
                />
                <Dropdown
                  id={'userType'}
                  value={watch('userType') || ''}
                  label={t('global.user_type') || ''}
                  placeholder={
                    t(
                      'users_managment.internal_users_create.user_type_placeholder',
                    ) || ''
                  }
                  onChange={(value) => {
                    !Array.isArray(value) &&
                      handleSelectUserType(value.value as UserType);
                  }}
                  options={usersTypesList}
                  isMulti={false}
                  errorMessage={errors.userType?.message}
                />
              </FieldsSection>
              <ButtonSection>
                <SecondaryButton type="button" onClick={handleCloseModal}>
                  {t('global.cancel')}
                </SecondaryButton>
                <PrimaryButton type="submit">{t('global.save')}</PrimaryButton>
              </ButtonSection>
            </FormStyled>
          </StyledModal>
        )}
      </>
    </Modal>
  );
};

export default InternalUserActionModal;
