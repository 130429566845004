import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'application/assets/delete.svg';
import { TableProps } from './models';
import {
  StyledTr,
  TableStyled,
  ActionButtonStyled,
  ActionButtonsGroupStyled,
} from './styles';

const TableSupportedCountries: FC<TableProps> = ({
  columns,
  data,
  handleDelete,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TableStyled>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={column + index}>
                <span>{column}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <StyledTr key={item.id}>
              <td>{item.name}</td>
              <td>
                <ActionButtonsGroupStyled>
                  <ActionButtonStyled
                    type="button"
                    onClick={() => handleDelete(item.id)}
                  >
                    {t('databases_feature.delete')}
                    <DeleteIcon />
                  </ActionButtonStyled>
                </ActionButtonsGroupStyled>
              </td>
            </StyledTr>
          ))}
        </tbody>
      </TableStyled>
    </>
  );
};

export default TableSupportedCountries;
