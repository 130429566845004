import { styled } from '@mui/system';

interface StatusProps {
  isStatus: boolean;
}

export const Wrapper = styled('article')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.common.grey200};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  min-height: 100%;
  margin-top: 20px;
  background: ${({ theme }) => theme.palette.common.white};
`;

export const FiltersSectionStyled = styled('section')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;

  & > button {
    min-width: 150px;
    height: 44px;
    width: fit-content;
    color: ${({ theme }) => theme.palette.common.grey900};
    svg {
      path {
        fill: ${({ theme }) => theme.palette.common.grey900};
      }
    }
  }
`;

export const StatusWrapper = styled('div')<StatusProps>`
  display: flex;
  align-items: center;
  height: ${({ isStatus }) => (isStatus ? '44px' : 'auto')};
`;

export const StatusLine = styled('div')`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.common.blue800};

  span {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: ${({ theme }) => theme.palette.common.grey700};
  }
`;

export const ImportButton = styled('button')`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.common.blue800};
  cursor: pointer;
  margin-right: 4px;
  border: none;
  background: ${({ theme }) => theme.palette.common.white};
  text-wrap: nowrap;

  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.palette.common.grey700};
  }
`;

export const ButtonsWrapper = styled('div')<StatusProps>`
  display: flex;
  flex-direction: ${({ isStatus }) => (isStatus ? 'column' : 'row')};
  align-items: ${({ isStatus }) => (isStatus ? 'flex-end' : 'center')};
  justify-content: space-between;
  padding: ${({ isStatus }) => (isStatus ? '20px 0 20px 20px' : '0')};
  gap: ${({ isStatus }) => (isStatus ? '0' : '20px')};

  & > button {
    min-width: 150px;
    height: 44px;
    width: fit-content;
    color: ${({ theme }) => theme.palette.common.grey900};
    svg {
      path {
        fill: ${({ theme }) => theme.palette.common.grey900};
      }
    }
  }
`;
