import styled from 'styled-components';

export const Wrapper = styled.aside`
  display: flex;
  position: relative;
  width: 76px;
  height: 100vh;
  overflow-y: auto;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 32px 16px;
  background: ${({ theme }) => theme.color.menu};
  transition: width 0.3s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }

  .profile-root {
    color: ${({ theme }) => theme.color.white};

    &.active {
      background: ${({ theme }) => theme.color.green100};
      color: ${({ theme }) => theme.color.menu};
      & .profile-title {
        color: ${({ theme }) => theme.color.menu};
        > svg {
          path {
            fill: ${({ theme }) => theme.color.menu};
          }
        }
      }
    }
  }

  .profile-list {
    button.active {
      background: transparent;
      color: ${({ theme }) => theme.color.white};
    }
  }

  > hr {
    margin: 16px 0;
    width: 100%;
    background-color: ${({ theme }) => theme.color.navigator};
  }

  .logo-text {
    height: 44px;
    position: absolute;
    visibility: hidden;
    padding: 0 16px;
    opacity: 0;
    transition: visibility 0s, opacity 1s linear;
  }

  .logo {
    width: 44px;
    height: 44px;
    visibility: visible;
    opacity: 1;
    position: relative;
    transition: visibility 0s, opacity 1s linear;
  }

  .menu_item {
    > p {
      position: relative;
      overflow: hidden;
      height: 22px;
    }
  }

  .item-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    margin-top: 8px;
  }

  .language {
    margin-left: 2px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    margin-top: 16px;

    > svg {
      margin-right: 0px;
      margin-left: -2px;
      transition: margin 0.5s ease-out;
    }
    > p,
    span {
      cursor: pointer;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.08px;
      border-radius: 4px;
      height: 100%;
      padding: 7px 0;
      transition: padding 0.3s linear;
      :hover {
        background: ${({ theme }) => theme.color.navigatorHover};
      }
    }
    > p {
      color: ${({ theme }) => theme.color.white};
      font-weight: 700;
    }
    > hr {
      visibility: hidden;
      opacity: 0;
      height: 16px;
      margin: 0 1px;
      background: ${({ theme }) => theme.color.navigator};
    }
    > span {
      visibility: hidden;
      opacity: 0;
      color: ${({ theme }) => theme.color.grey400};
      font-weight: 500;
    }
  }

  .logout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 12px;
    cursor: pointer;
    border-radius: 4px;
    height: fit-content;
    color: ${({ theme }) => theme.color.white};

    > svg {
      height: 24px;
      width: 24px;
    }

    :hover {
      background: ${({ theme }) => theme.color.navigatorHover};
    }

    > span {
      height: 22px;
      width: 60px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.08px;
      display: none;
      overflow: hidden;
    }
  }

  &.active {
    width: 312px;
    .logo {
      visibility: hidden;
      opacity: 0;
    }
    .logo-text {
      visibility: visible;
      opacity: 1;
    }

    .language {
      margin-left: 0px;
      > svg {
        margin-right: 1px;
      }
      > p,
      span {
        padding: 7px;
      }
      > span,
      hr {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity 0.3s linear;
      }
    }

    .logout {
      > span {
        display: block;
      }
    }
    .profile-root {
      &.active {
        background: transparent;
        & .profile-title {
          color: ${({ theme }) => theme.color.white};
          > svg {
            path {
              fill: ${({ theme }) => theme.color.white};
            }
          }
        }
      }
      :hover {
        background: ${({ theme }) => theme.color.navigatorHover};
      }
    }

    .profile-list {
      button.active {
        background: ${({ theme }) => theme.color.green100};
        color: ${({ theme }) => theme.color.menu};
      }
    }
    .profile-expand {
      > svg {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity 0.3s linear;
      }
    }
    .profile-info {
      .image {
        left: unset;
      }
    }
  }
`;

export const Box = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
  height: 44px;
  margin-bottom: 24px;

  &.expanded {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      position: absolute;
      margin-bottom: 0;
      right: 0;
    }
  }
`;

export const ButtonStyled = styled.button`
  all: unset;
  cursor: pointer;
  margin-bottom: 24px;
  svg {
    width: 44px;
    height: 44px;
  }

  &:hover {
    svg {
      path {
        stroke: ${({ theme }) => theme.color.blue300};
      }
    }
  }
`;
