export enum SupportedCountries {
  Portugal = 'PT',
  France = 'FR',
}

export interface SupportedCountriesDto {
  code: string;
  id: string;
  name: string;
  name_EN?: string;
  name_FR?: string;
  name_PT?: string;
}
