import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { DeleteModalProps } from './modules';

const ConfirmDeleteSupportedCountry: FC<DeleteModalProps> = ({
  isOpen,
  countryName,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <h1>
          {t('databases_feature.confirm_delete_country')}{' '}
          <span>{countryName}</span>?
        </h1>
        <section>
          <SecondaryButton onClick={onClose}>
            {t('global.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSubmit} styleType={'error'}>
            {t('global.confirm')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default ConfirmDeleteSupportedCountry;
