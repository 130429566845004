import { createSlice } from '@reduxjs/toolkit';
import { StatisticsState } from './models';
import {
  fetchCities,
  fetchClinics,
  fetchPerCityMetrics,
  fetchPerClinicsMetrics,
  fetchPerDoctorsMetrics,
  fetchSpecializations,
  fetchPlatformStatistics,
} from './ActionCreators';
import { metricsArray } from 'features/feature-statistics/constants';

const initialState: StatisticsState = {
  isLoading: false,
  error: '',
  cities: [],
  clinics: [],
  metrics: metricsArray,
  perCitiesMetricsData: null,
  perClinicsMetricsData: null,
  perDoctorsMetricsData: null,
  platformStatistics: null,
  specializations: [],
  total: 0,
  page: 0,
  totalPages: 0,
  perCitiesTabFilters: {},
  perClinicsTabFilters: {},
  perDoctorsTabFilters: {},
};

const statisticsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setMetricsData: (state, action) => {
      state.metrics = action.payload;
    },

    setPerCitiesFilters: (state, action) => {
      state.perCitiesTabFilters = action.payload;
    },

    setPerClinicsFilters: (state, action) => {
      state.perClinicsTabFilters = action.payload;
    },

    setPerDoctorsFilters: (state, action) => {
      state.perDoctorsTabFilters = action.payload;
    },

    startLoading: (state) => {
      state.isLoading = true;
    },

    endLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    //Fetch cities
    builder.addCase(fetchCities.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCities.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.cities = action.payload;
      state.isLoading = false;
    });
    //Fetch clinics
    builder.addCase(fetchClinics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchClinics.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchClinics.fulfilled, (state, action) => {
      state.clinics = action.payload.entities;
      state.isLoading = false;
    });
    //Fetch per cities data
    builder.addCase(fetchPerCityMetrics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPerCityMetrics.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchPerCityMetrics.fulfilled, (state, action) => {
      state.perCitiesMetricsData = action.payload;
      state.isLoading = false;
    });
    //Fetch per clinics data
    builder.addCase(fetchPerClinicsMetrics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPerClinicsMetrics.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchPerClinicsMetrics.fulfilled, (state, action) => {
      state.perClinicsMetricsData = action.payload;
      state.isLoading = false;
    });
    //Fetch per clinics data
    builder.addCase(fetchPerDoctorsMetrics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPerDoctorsMetrics.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchPerDoctorsMetrics.fulfilled, (state, action) => {
      state.perDoctorsMetricsData = action.payload;
      state.isLoading = false;
    });
    //Fetch specializations
    builder.addCase(fetchSpecializations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSpecializations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchSpecializations.fulfilled, (state, action) => {
      state.specializations = action.payload;
      state.isLoading = false;
    });
    //Fetch platform statistics
    builder.addCase(fetchPlatformStatistics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPlatformStatistics.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchPlatformStatistics.fulfilled, (state, action) => {
      state.platformStatistics = action.payload;
      state.isLoading = false;
    });
  },
});

export const {
  setMetricsData,
  setPerCitiesFilters,
  setPerDoctorsFilters,
  setPerClinicsFilters,
  startLoading,
  endLoading,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;
