import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useParams } from 'react-router-dom';

import { ReactComponent as HouseIcon } from 'application/assets/home.svg';
import { ReactComponent as InfoIcon } from 'application/assets/medical-information.svg';
import { ReactComponent as EmailIcon } from 'application/assets/email-clinic.svg';
import {
  getClinicById,
  updateClinic,
} from 'application/store/reducers/ClinicsManagement/ActionCreators';

import { Input, Loader, PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import { useProfileInfoSchema } from 'features/feature-clinic-profile/hooks/useProfileInfoSchema';
import ErrorModal from '../ErrorModal';

import {
  ButtonSection,
  ClinicNameStyled,
  Divider,
  FormStyled,
  IconStyled,
  LabelStyled,
  Wrapper,
} from './styles';
import { encrypt } from 'common/helpers/encryption';

const ProfileInfo: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { currentClinic, isLoading } = useAppSelector(
    (state) => state.clinicsManagement,
  );
  const profileInfoSchema = useProfileInfoSchema();
  const [showErrorModal, setShowErrorModal] = useState(false);

  const clinicOwnerFullName = `${currentClinic?.owner?.firstName} ${currentClinic?.owner?.lastName}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<{ billingApiKey: string }>({
    resolver: yupResolver(profileInfoSchema),
  });

  useEffect(() => {
    dispatch(getClinicById(String(id)));
  }, [id]);

  const onSave = async ({ billingApiKey }: { billingApiKey: string }) => {
    if (currentClinic) {
      const encryptedKey = encrypt(
        billingApiKey,
        process.env.REACT_APP_BILLING_SECRET_KEY || '',
        process.env.REACT_APP_BILLING_IV || '',
      );
      const response = await dispatch(
        updateClinic({
          clinicId: currentClinic?.id,
          billingApiKey: encryptedKey,
        }),
      );
      if (response.payload.includes('409')) {
        setShowErrorModal(true);
      } else if (response.meta.requestStatus === 'fulfilled') {
        dispatch(getClinicById(String(id)));
        setValue('billingApiKey', '');
      }
    }
  };

  const onCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <ClinicNameStyled>
        {currentClinic?.mainPhoto ? (
          <img
            src={currentClinic.mainPhoto.thumbnailUrl}
            alt={currentClinic.mainPhoto.id}
          />
        ) : (
          <IconStyled>
            <HouseIcon />
          </IconStyled>
        )}
        <h1>{currentClinic?.name}</h1>
      </ClinicNameStyled>
      <Divider />
      <FormStyled onSubmit={handleSubmit(onSave)}>
        <LabelStyled>
          <InfoIcon />
          <p>{t('clinics_management.clinic_owner_name')}:</p>
          <h2>{clinicOwnerFullName}</h2>
        </LabelStyled>
        <LabelStyled>
          <EmailIcon />
          <p>{t('clinics_management.clinic_owner_email')}:</p>
          <h2>{currentClinic?.owner?.user?.email}</h2>
        </LabelStyled>
        <Input
          id="billingApiKey"
          label={t('clinics_management.fact_key') || ''}
          type="text"
          errorMessage={errors.billingApiKey?.message}
          register={register}
          placeholder={currentClinic?.billingConfigured ? '********' : ''}
        />
        <Divider />
        <ButtonSection>
          <PrimaryButton type="submit">{t('global.save')}</PrimaryButton>
        </ButtonSection>
      </FormStyled>
      {showErrorModal && (
        <ErrorModal opened={showErrorModal} onClose={onCloseErrorModal} />
      )}
    </Wrapper>
  );
};

export default ProfileInfo;
