import React, { FC } from 'react';

import { CountryDropdown } from 'common/components';
import { SupportedCountriesDto } from 'common/types/countries';

import { PerSpecializationProps } from './models';
import { Wrapper } from './styles';

const PerSpecialization: FC<PerSpecializationProps> = ({
  supportedCountries,
  setSupportedCountries,
}) => {
  return (
    <Wrapper>
      <CountryDropdown
        id="country-dropdown"
        value={supportedCountries}
        onChange={(value) => {
          setSupportedCountries(value as SupportedCountriesDto[]);
        }}
        isMulti={true}
      />
    </Wrapper>
  );
};

export default PerSpecialization;
