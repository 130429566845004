import styled from 'styled-components';

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 20px;

  > div {
    width: 200px;
  }
`;
