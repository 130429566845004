import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader, PrimaryButton } from 'common/components';
import { useExportStatistics } from 'common/hooks/useExportStatistics';
import { useAppSelector } from 'common/hooks/redux';
import { SupportedCountriesDto } from 'common/types/countries';

import { TabName } from 'features/feature-statistics/constants';

import PerCity from '../PerCity';
import PerClinics from '../PerClinics';
import PerDoctors from '../PerDoctors';
import PlatformStatistics from '../PlatformStatistics';
import PerSpecialization from '../PerSpecialization';
import {
  ButtonStyled,
  TabsSectionStyled,
  TabsSectionTopButtonStyled,
  TabsSectionTopStyled,
  Wrapper,
} from './styles';

const Main: FC = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(TabName.PerCities);
  const [supportedCountries, setSupportedCountries] = useState<
    SupportedCountriesDto[]
  >([]);

  const handleChangeTab = (tabName: TabName) => {
    setCurrentTab(tabName);
    setSupportedCountries([]);
  };

  const {
    perCitiesTabFilters,
    perDoctorsTabFilters,
    perClinicsTabFilters,
    isLoading,
  } = useAppSelector((state) => state.statistics);
  const filtersMap = {
    [TabName.PerClinics]: perClinicsTabFilters,
    [TabName.PerDoctors]: perDoctorsTabFilters,
    [TabName.PerCities]: perCitiesTabFilters,
    [TabName.PlatformStatistics]: {},
    [TabName.SpecializationPerCity]: {
      ...(supportedCountries.length
        ? { supportedCountriesIso: supportedCountries.map((item) => item.code) }
        : {}),
    },
  };

  const exportStatistics = useExportStatistics(currentTab);
  const handleExportStatistics = async () => {
    await exportStatistics(filtersMap[currentTab] || {});
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <TabsSectionStyled>
        <TabsSectionTopStyled>
          <h1>{t('statistics.title')}</h1>
          <TabsSectionTopButtonStyled>
            <PrimaryButton onClick={handleExportStatistics}>
              {t('statistics.export_to_excel')}
            </PrimaryButton>
          </TabsSectionTopButtonStyled>
        </TabsSectionTopStyled>
        <div>
          <ButtonStyled
            isActive={currentTab === TabName.PerCities}
            type="button"
            onClick={() => handleChangeTab(TabName.PerCities)}
          >
            <p>{t('statistics.tabs.per_city')}</p>
          </ButtonStyled>
          <ButtonStyled
            isActive={currentTab === TabName.PerClinics}
            type="button"
            onClick={() => handleChangeTab(TabName.PerClinics)}
          >
            <p>{t('statistics.tabs.per_clinics')}</p>
          </ButtonStyled>
          <ButtonStyled
            isActive={currentTab === TabName.PerDoctors}
            type="button"
            onClick={() => handleChangeTab(TabName.PerDoctors)}
          >
            <p>{t('statistics.tabs.per_doctors')}</p>
          </ButtonStyled>
          <ButtonStyled
            isActive={currentTab === TabName.PlatformStatistics}
            type="button"
            onClick={() => handleChangeTab(TabName.PlatformStatistics)}
          >
            <p>{t('statistics.tabs.platform_statistics')}</p>
          </ButtonStyled>
          <ButtonStyled
            isActive={currentTab === TabName.SpecializationPerCity}
            type="button"
            onClick={() => handleChangeTab(TabName.SpecializationPerCity)}
          >
            <p>{t('statistics.tabs.per_specialization')}</p>
          </ButtonStyled>
        </div>
      </TabsSectionStyled>
      {currentTab === TabName.PerCities && (
        <PerCity
          supportedCountries={supportedCountries}
          setSupportedCountries={setSupportedCountries}
        />
      )}
      {currentTab === TabName.PerClinics && (
        <PerClinics
          supportedCountries={supportedCountries}
          setSupportedCountries={setSupportedCountries}
        />
      )}
      {currentTab === TabName.PerDoctors && (
        <PerDoctors
          supportedCountries={supportedCountries}
          setSupportedCountries={setSupportedCountries}
        />
      )}
      {currentTab === TabName.PlatformStatistics && (
        <PlatformStatistics
          supportedCountries={supportedCountries}
          setSupportedCountries={setSupportedCountries}
        />
      )}
      {currentTab === TabName.SpecializationPerCity && (
        <PerSpecialization
          supportedCountries={supportedCountries}
          setSupportedCountries={setSupportedCountries}
        />
      )}
    </Wrapper>
  );
};

export default Main;
