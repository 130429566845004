import axiosBlobInstance from './axiosBlobInstance';
import {
  PlatformStatisticsQueryParamsDto,
  StatsPerCityQueryParamsDto,
  StatsPerClinicsQueryParamsDto,
  StatsPerProfessionalsQueryParamsDto,
} from '@docbay/schemas';

export const StatisticsExportApi = {
  downloadPerCityExcelStats: (params: StatsPerCityQueryParamsDto) =>
    axiosBlobInstance.post('/statistics/per-city/export', params),

  downloadPerClinicExcelStats: (params: StatsPerClinicsQueryParamsDto) =>
    axiosBlobInstance.post('/statistics/per-clinic/export', params),

  downloadPerDoctorExcelStats: (params: StatsPerProfessionalsQueryParamsDto) =>
    axiosBlobInstance.post('/statistics/per-professional/export', params),

  downloadPlatformExcelStats: (params: PlatformStatisticsQueryParamsDto) =>
    axiosBlobInstance.post('/statistics/platform/export'),

  downloadSpecializationPerCityExcelStats: (
    params: PlatformStatisticsQueryParamsDto,
  ) =>
    axiosBlobInstance.post(
      '/statistics/per-city/specializations/export',
      params,
    ),
};
