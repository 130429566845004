import styled from 'styled-components';

interface LoadingProps {
  isLoading?: boolean;
}

interface ErrorProps {
  isError?: boolean;
}

export const ModalStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 32px;
  gap: 32px;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  .content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }
  h6 {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    padding: 12px 24px;
    white-space: nowrap;

    &:last-of-type {
      margin-left: 16px;
    }
  }
`;

export const AddDocument = styled.div<LoadingProps>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  width: 100%;
  padding: 8px;
  border-radius: 8px;

  > div {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => theme.color.grey500};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;

    > label {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 8px;
      cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
    }
    > input {
      display: none;
    }
  }

  .disabled {
    opacity: 0.5;
    &:hover {
      cursor: default;
    }
  }
`;

export const UploadingProgress = styled.div`
  display: flex;
  align-items: center;
`;

export const UploadingText = styled.div<ErrorProps>`
  width: 100%;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${({ theme, isError }) =>
    isError ? theme.color.error1 : theme.color.grey900};

  & p:last-of-type {
    font-weight: 400;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error1 : theme.color.grey700};
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    min-width: 20px;
    min-height: 20px;
    margin-right: 8px !important;
  }
`;

export const DocumentLoader = styled.div<ErrorProps>`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ theme, isError }) =>
    isError ? theme.color.error3 : theme.color.blue100};

  svg {
    animation: ${({ isError }) =>
      isError ? 'none' : 'rotation 3s infinite linear'};
    color: ${({ theme, isError }) =>
      isError ? theme.color.error1 : theme.color.grey700};

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
`;
