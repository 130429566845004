import { Divider, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { styledTheme } from 'application/theme';

export const StyledTypography = styled(Typography)`
  color: ${styledTheme.color.grey900};
  font: 400 14px/19.6px 'Rubik';
`;

export const DividerStyled = styled(Divider)`
  color: ${styledTheme.color.grey300};
  height: 1px;
  width: calc((100% - 40px) / 2);
`;

export const DividerGroupStyled = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
  width: 100%;
`;
