import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export enum LocalizeNameKey {
  name_EN = 'name_EN',
  name_PT = 'name_PT',
}
export const useLocalizeKey = () => {
  const { i18n } = useTranslation();
  const localizeNameKey = useMemo(() => {
    return i18n.language.toLowerCase() === 'en'
      ? LocalizeNameKey.name_EN
      : LocalizeNameKey.name_PT;
  }, [i18n.language]);
  return {
    localizeNameKey,
  };
};
