import React from 'react';
import {
  TableStyled,
  TableWrapper,
  ProfessionalNameStyled,
  AvatarStyled,
} from './styles';
import { useAppSelector } from 'common/hooks/redux';
import { metricsList } from 'features/feature-statistics/constants';
import { Option } from 'application/store/reducers/Statistics/models';
import { ReactComponent as PersonIcon } from 'application/assets/person.svg';
import { CommonCountsDto } from '@docbay/schemas';
import MetricTableName from '../MetricTableName';
import MetricTableCol from '../MetricTableCol';
import { useTranslation } from 'react-i18next';

const PerDoctorsMetricsTable = () => {
  const { t } = useTranslation();
  const { perDoctorsMetricsData } = useAppSelector((state) => state.statistics);

  return (
    <TableWrapper>
      <TableStyled>
        <table>
          <thead>
            <tr>
              <th>
                <div className={'firstColumn'}>
                  <span>{t('statistics.doctors')}</span>
                </div>
              </th>
              {metricsList?.map((metricName: Option, ind) => {
                return (
                  <MetricTableName
                    key={`${metricName.value}_${ind}`}
                    metricName={metricName}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {perDoctorsMetricsData?.total && (
              <tr>
                <td className={'firstColItem'}>{t('statistics.total')}</td>
                {perDoctorsMetricsData?.total &&
                  metricsList?.map((metricName: Option) => {
                    const total = perDoctorsMetricsData?.total!;

                    return (
                      <td key={`total_${metricName.value}`}>
                        <span>
                          {total[metricName.value as keyof CommonCountsDto]}
                        </span>
                      </td>
                    );
                  })}
              </tr>
            )}

            {perDoctorsMetricsData?.perProfessional?.entities?.map(
              (item, ind) => {
                return (
                  <tr key={`${item.professional?.nameToDisplay}_${ind}`}>
                    <td className={'firstColItem'}>
                      <ProfessionalNameStyled>
                        {item.professional?.mainPhoto ? (
                          <img
                            src={item.professional?.mainPhoto.thumbnailUrl}
                            alt={item.professional?.mainPhoto.id}
                          />
                        ) : (
                          <AvatarStyled>
                            <PersonIcon />
                          </AvatarStyled>
                        )}
                        <div>
                          <p>{item.professional?.nameToDisplay || ''}</p>
                          <span>{item.professional?.user?.email}</span>
                        </div>
                      </ProfessionalNameStyled>
                    </td>
                    {metricsList?.map((metricName: Option) => {
                      return (
                        <MetricTableCol
                          value={item}
                          item={metricName.value}
                          key={`${metricName.value}_${ind}`}
                          metricsName={metricName.label}
                        />
                      );
                    })}
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </TableStyled>
    </TableWrapper>
  );
};

export default PerDoctorsMetricsTable;
