import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { ReactComponent as WarningIcon } from 'application/assets/warning.svg';

import { PrimaryButton } from 'common/components';

import { ErrorModalProps } from './models';
import { StyledModal } from './styles';

const ErrorModal: FC<ErrorModalProps> = ({ opened, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal open={opened} onClose={onClose}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <WarningIcon />
          <h1>{t('clinics_management.fact_error_modal.title')}</h1>
          <p>{t('clinics_management.fact_error_modal.sub_title')}</p>
          <PrimaryButton type="button" onClick={onClose}>
            {t('global.ok')}
          </PrimaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default ErrorModal;
