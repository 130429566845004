import { ColorsPallet } from 'application/theme';
import styled from 'styled-components';

interface StyledButtonProps {
  styleType: 'default' | 'color' | 'error';
  disabled?: boolean;
  theme: { color: ColorsPallet };
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 10px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.08px;
  color: ${({ theme, styleType }) =>
    styleType === 'color'
      ? theme.color.blue800
      : theme.color[styleType === 'error' ? 'error2' : 'grey700']};
  background-color: ${({ theme, styleType }) =>
    styleType === 'error' ? theme.color.white : 'transparent'};
  border: 1px solid
    ${({ theme, styleType }) =>
      styleType === 'color'
        ? theme.color.blue800
        : theme.color[styleType === 'error' ? 'error2' : 'grey400']};
  box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme, styleType }) =>
      styleType === 'color'
        ? theme.color.blue50
        : theme.color[styleType === 'error' ? 'error3' : 'grey100']};
    color: ${({ theme, styleType }) =>
      styleType === 'color'
        ? theme.color.blue800
        : theme.color[styleType === 'error' ? 'error2' : 'grey900']};
  }

  :disabled {
    background-color: ${({ theme, styleType }) =>
      styleType === 'color' ? theme.color.blue50 : theme.color.white};
    border-color: ${({ theme, styleType }) =>
      styleType === 'color' ? theme.color.blue200 : theme.color.grey300};
    color: ${({ theme, styleType }) =>
      styleType === 'color' ? theme.color.blue200 : theme.color.grey300};
  }
`;
