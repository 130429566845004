import {
  SaasAdminSetPasswordDto,
  UserChangePasswordDto,
  UserLoginDto,
  UserRefreshTokenDto,
} from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UsersActions } from 'application/constants/actions';
import {
  setUsersAuthSessionStorage,
  setUsersRefreshTokenSessionStorage,
} from 'application/sessionStorage/auth';
import { AxiosError } from 'axios';
import { authService } from 'common';
import { UsersAPI } from 'integration/api/users';

export const usersLogin = createAsyncThunk(
  UsersActions.usersLogin,
  async (loginData: UserLoginDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersLogin(loginData);
      setUsersAuthSessionStorage(data);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const usersRefreshToken = createAsyncThunk(
  UsersActions.usersRefreshToken,
  async (refreshTokenData: UserRefreshTokenDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersRefreshToken(refreshTokenData);
      setUsersRefreshTokenSessionStorage(data);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const usersRefreshExpiredToken = async (
  refreshTokenData: UserRefreshTokenDto,
) => {
  try {
    const data = await UsersAPI.usersRefreshToken(refreshTokenData);
    setUsersRefreshTokenSessionStorage(data.data);
    return data.data;
  } catch (e) {
    console.error(e);
    authService.cleanStorage();
  }
};

export const signOut = createAsyncThunk(
  UsersActions.signOut,
  async (_, { rejectWithValue }) => {
    try {
      const { data: responseData } = await UsersAPI.signOut();
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const changePasswordSaas = createAsyncThunk(
  UsersActions.changePasswordSaas,
  async (data: UserChangePasswordDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await UsersAPI.changePasswordSaas(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createPassword = createAsyncThunk(
  UsersActions.createPassword,
  async (data: SaasAdminSetPasswordDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await UsersAPI.createPassword(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
