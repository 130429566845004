import {
  PlatformStatisticsQueryParamsDto,
  StatsPerCityQueryParamsDto,
  StatsPerClinicsQueryParamsDto,
  StatsPerProfessionalsQueryParamsDto,
} from '@docbay/schemas';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from './redux';

import { StatisticsExportApi } from 'integration/api/statistics-export';
import { TabName } from 'features/feature-statistics/constants';
import {
  endLoading,
  startLoading,
} from 'application/store/reducers/Statistics/StatisticsSlice';

const statsExportRequestMap = {
  [TabName.PerCities]: StatisticsExportApi.downloadPerCityExcelStats,
  [TabName.PerDoctors]: StatisticsExportApi.downloadPerDoctorExcelStats,
  [TabName.PerClinics]: StatisticsExportApi.downloadPerClinicExcelStats,
  [TabName.PlatformStatistics]: StatisticsExportApi.downloadPlatformExcelStats,
  [TabName.SpecializationPerCity]:
    StatisticsExportApi.downloadSpecializationPerCityExcelStats,
};
export const useExportStatistics = (statsType: TabName) => {
  const dispatch = useAppDispatch();
  const exportStatistics = async (
    params:
      | StatsPerCityQueryParamsDto
      | StatsPerClinicsQueryParamsDto
      | StatsPerProfessionalsQueryParamsDto
      | PlatformStatisticsQueryParamsDto,
  ) => {
    try {
      dispatch(startLoading());
      const exportRequest = statsExportRequestMap[statsType];
      const response = (await exportRequest(params)) as AxiosResponse;
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'downloaded_file';

      if (contentDisposition) {
        const matches = /filename="([^"]+)"/.exec(contentDisposition);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.log('Download document error: ', e);
    } finally {
      dispatch(endLoading());
    }
  };

  return exportStatistics;
};
