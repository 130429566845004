import styled from 'styled-components';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  margin-top: 20px;
  background: #fff;
`;

export const FilterSection = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
`;

export const LeftFilters = styled('div')`
  margin-right: 16px;

  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    &:first-child {
      margin-bottom: 16px;
    }

    > div {
      width: 220px;
    }
  }
`;

export const MetricsFilter = styled('div')`
  width: 312px;
`;

export const DateWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 456px !important;

  > div {
    width: 416px !important;

    &:last-child {
      margin-left: 16px;
    }
  }
`;

export const AgeWrapper = styled('div')`
  display: flex;
  align-items: center;

  > div {
    width: 200px;

    &:last-child {
      margin-left: 16px;
    }
  }
`;
