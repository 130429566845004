import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ExpandMoreIcon } from 'application/assets/expand-more.svg';
import { ReactComponent as PortugalIcon } from 'application/assets/countries-flags/portugal.svg';
import { ReactComponent as FranceIcon } from 'application/assets/countries-flags/france.svg';
import { getSupportedCountries } from 'application/store/reducers/Countries/ActionCreators';

import useOnClickOutside from 'common/hooks/useClickOutside';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  SupportedCountries,
  SupportedCountriesDto,
} from 'common/types/countries';

import Input from '../Input';
import Checkbox from '../Checkbox';

import { CountryDropdownProps } from './models';
import {
  DropdownStyled,
  OptionCheckBox,
  OptionItem,
  OptionsList,
} from './styles';

const getCountryFlag = (iso: string) => {
  switch (iso) {
    case SupportedCountries.Portugal: {
      return <PortugalIcon />;
    }
    case SupportedCountries.France: {
      return <FranceIcon />;
    }
    default:
      return '';
  }
};

const CountryDropdown: FC<CountryDropdownProps> = ({
  id,
  label,
  value,
  onChange,
  disabled,
  isMulti,
  withSearch,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { supportedCountries } = useAppSelector((state) => state.countries);

  const [isActive, setIsActive] = useState(false);
  const [searchItem, setSearchItem] = useState('');

  useOnClickOutside(ref, () => setIsActive(false));

  useEffect(() => {
    dispatch(getSupportedCountries());
  }, []);

  const countriesList = useMemo(() => {
    if (!searchItem) return supportedCountries;
    return supportedCountries.filter((item) =>
      item.name.toLocaleLowerCase().includes(searchItem.toLocaleLowerCase()),
    );
  }, [supportedCountries, searchItem]);

  const handleChange = (country: SupportedCountriesDto) => {
    if (!Array.isArray(value)) return;
    const valueAlreadyAdded = value.some((item) => item.code === country.code);
    if (valueAlreadyAdded) {
      const filteredValue = value.filter((item) => item.code !== country.code);
      onChange(filteredValue);
    } else {
      onChange([...value, country]);
    }
    return;
  };

  const IsValueChecked = (code: string) => {
    const isValueArray = Array.isArray(value);

    return isValueArray && value.some((i) => i.code === code);
  };

  const getValue = () => {
    if (isMulti && Array.isArray(value))
      return `${value.length} ${t('selected')}`;

    return (value as SupportedCountriesDto)?.name;
  };

  const isListNotEmpty = isActive && (!!countriesList.length || !!searchItem);

  const hasValue =
    (Array.isArray(value) && value.length) ||
    (!Array.isArray(value) && value?.code);

  return (
    <DropdownStyled isActive={isActive} disabled={disabled} ref={ref}>
      {label && <label htmlFor={id}>{label}</label>}
      <section onMouseDown={() => !disabled && setIsActive(!isActive)}>
        {!hasValue ? (
          <span>{t('countries')}</span>
        ) : (
          <h2>
            {!Array.isArray(value) ? getCountryFlag(value.code as string) : ''}
            {getValue()}
          </h2>
        )}
        <ExpandMoreIcon />
      </section>
      {isListNotEmpty && !isMulti ? (
        <OptionsList className="optionList">
          {withSearch && (
            <Input
              id={'search'}
              type="search"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target?.value || '')}
              placeholder={t('Search country')}
              onClear={() => setSearchItem('')}
            />
          )}
          {countriesList.map((item) => (
            <OptionItem
              key={item.id}
              onClick={() => {
                onChange({
                  code: item.code,
                  id: String(item.id),
                  name: item.name,
                });
                setIsActive(false);
              }}
              selected={
                (value as SupportedCountriesDto)?.code === String(item.code)
              }
            >
              {getCountryFlag(item.code)}
              {t(item.name)}
            </OptionItem>
          ))}
        </OptionsList>
      ) : (
        ''
      )}
      {isListNotEmpty && isMulti ? (
        <OptionsList className="country-list">
          {withSearch && (
            <Input
              id={'search'}
              type="search"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target?.value || '')}
              placeholder={t('Search country')}
              onClear={() => setSearchItem('')}
            />
          )}
          {countriesList.map((item) => (
            <OptionCheckBox
              key={item.id}
              htmlFor={item.code}
              selected={IsValueChecked(item.code)}
            >
              <Checkbox
                id={item.code}
                checked={IsValueChecked(item.code)}
                onChange={() =>
                  handleChange({
                    code: item.code,
                    id: String(item.id),
                    name: item.name,
                  })
                }
              />
              {getCountryFlag(item.code)}
              {t(item.name)}
            </OptionCheckBox>
          ))}
        </OptionsList>
      ) : (
        ''
      )}
    </DropdownStyled>
  );
};

export default CountryDropdown;
