const compareArrays = (
  firstArray: any[],
  secondArray: any[],
  checkOrder: boolean,
) => {
  if (checkOrder) {
    const valueFirstObj = JSON.stringify(firstArray);
    const valueSecondObj = JSON.stringify(secondArray);
    if (valueFirstObj !== valueSecondObj) {
      return true;
    }
  }
  const filteredArray = firstArray.filter((item) => {
    const valueNotChanged = secondArray?.some(
      (data: any) => JSON.stringify(data) === JSON.stringify(item),
    );
    return valueNotChanged;
  });
  if (firstArray.length !== filteredArray.length) {
    return true;
  }
  if (firstArray.length !== secondArray.length) {
    return true;
  }
  return false;
};
const deepComparison: any = (
  firstObj: any,
  secondObj: any,
  checkOrder: boolean,
) => {
  if (!firstObj || !secondObj) return true;
  if (Array.isArray(firstObj)) {
    return compareArrays(firstObj, secondObj, checkOrder);
  }
  return Object.keys(firstObj).some((property) => {
    if (typeof firstObj[property] === 'object' && firstObj[property] !== null) {
      return deepComparison(
        firstObj[property],
        secondObj[property],
        checkOrder,
      );
    } else {
      if (firstObj && !secondObj) return true;
      const valueFirstObj = JSON.stringify(firstObj[property]);
      const valueSecondObj = JSON.stringify(secondObj[property]);
      if (valueFirstObj !== valueSecondObj) {
        return true;
      }
    }
    return false;
  });
};

const compareObjectsChanges = (
  firstObj: any,
  secondObj: any,
  checkOrder = false,
) => {
  const isChanged = deepComparison(firstObj, secondObj, checkOrder);
  return isChanged;
};

export default compareObjectsChanges;
