import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AddSpecializationDataT } from '../types';

export const useAddSpecialization = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const addSpecializationSchema = yup
    .object({
      name_en: yup.string().required(requiredError!),
      name_pt: yup.string().required(requiredError!),
      name_fr: yup.string().required(requiredError!),
    })
    .required();

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm<AddSpecializationDataT>({
    resolver: yupResolver(addSpecializationSchema),
    defaultValues: { name_en: '', name_pt: '', name_fr: '' },
  });

  return {
    register,
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
    reset,
  };
};
