import styled from 'styled-components';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 665px;
  max-height: 95vh;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
`;

export const Header = styled('header')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
  button {
    all: unset;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }
  }
`;

export const FormStyled = styled('form')`
  display: flex;
  flex-direction: column;
  height: calc(95vh - 204px);
  overflow-y: auto;
`;

export const FieldsSection = styled('div')`
  display: grid;
  gap: 16px;
  padding: 32px;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      font-family: Mulish;
      color: ${({ theme }) => theme.color.grey600};
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
      letter-spacing: 0.2px;
    }
  }

  > div[datatype='specializationIds'] {
    .option-list-multi {
      top: unset;
      bottom: 44px;
    }
  }
`;

export const ButtonSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px;
  button {
    min-width: 150px;
    width: fit-content;
    &:last-of-type {
      margin-left: 20px;
    }
  }
`;
