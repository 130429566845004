import styled from 'styled-components';

export const ProfileStyled = styled.div`
  width: 100%;
  position: relative;

  .profile-root {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 61px;
    width: 100%;
    padding: 8px 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08px;
    border-radius: '4px';
    border: 'none'};
    :hover {
      background: ${({ theme }) => theme.color.navigatorHover};
    }
  }
  .profile-info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    color: ${({ theme }) => theme.color.white};
    cursor: pointer;

    .image {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 2px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.purple100};

      color: ${({ theme }) => theme.color.grey900};
      text-align: center;
      font-size: 18px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-transform: uppercase;

      > img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all 0.3ms;
      }
    }

    > p,
    span {
      margin-left: 52px;
      position: relative;
      overflow: hidden;
    }

    > span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.07px;
      white-space: initial;
    }
  }
`;
