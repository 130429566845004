import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AddInsuranceDataT } from '../types';

export const useAddInsurance = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const addInsuranceSchema = yup
    .object({
      name: yup.string().required(requiredError!),
      code: yup.string().required(requiredError!),
    })
    .required();

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm<AddInsuranceDataT>({
    resolver: yupResolver(addInsuranceSchema),
    defaultValues: { name: '', code: '' },
  });

  return {
    register,
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
    reset,
  };
};
