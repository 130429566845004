import styled from 'styled-components';

export const Wrapper = styled.main`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 32px;
  flex-direction: column;
  overflow-y: auto;
`;

export const TableWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
`;
