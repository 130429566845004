import React, { FC, useMemo } from 'react';
import { ProfileStyled } from './styles';
import { useTranslation } from 'react-i18next';
import {
  getProfilesFromSessionStorage,
  getUserRole,
} from 'application/sessionStorage/auth';
import { UserRoles } from 'application/constants/userRoles';

const Avatar: FC = () => {
  const { t } = useTranslation();
  const userRole = getUserRole();

  const currentProfile = useMemo(() => {
    const profiles = getProfilesFromSessionStorage();

    const currentProfile = profiles?.length ? profiles[0] : null;

    if (currentProfile) {
      return {
        id: currentProfile.id,
        firstName: currentProfile.firstName,
        lastName: currentProfile.lastName,
        photo: currentProfile.photo,
      };
    }
  }, []);

  const currentProfileInitials = currentProfile?.firstName
    ? `${currentProfile?.firstName!.slice(
        0,
        1,
      )}${currentProfile?.lastName!.slice(0, 1)}`
    : '';

  const formattedUserRoles = userRole
    ?.replace(UserRoles.adminOwner, t('user_roles.admin_owner'))
    .replace(UserRoles.customerSupport, t('user_roles.customer_support'))
    .replace(UserRoles.techSupport, t('user_roles.tech_support'))
    .replace(',', ', ');

  return (
    <ProfileStyled>
      <div className="profile-root">
        <div className="profile-info">
          <div className="image">
            {currentProfile?.photo ? (
              <img
                src={currentProfile?.photo?.thumbnailUrl}
                alt={currentProfile?.id}
              />
            ) : (
              <>{currentProfileInitials}</>
            )}
          </div>
          <p>{`${currentProfile?.firstName} ${currentProfile?.lastName}`}</p>
          <span>{formattedUserRoles}</span>
        </div>
      </div>
    </ProfileStyled>
  );
};

export default Avatar;
