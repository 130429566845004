import styled from 'styled-components';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  margin-top: 20px;
  background: #fff;
`;

export const FilterSection = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  width: 100%;

  > div {
    width: 260px;
  }

  > button {
    width: fit-content;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.color.grey900};
    }
  }
`;
