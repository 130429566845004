import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SecondaryButton, PrimaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';

import { AddMetricsModalProps } from './models';
import {
  StyledModal,
  Header,
  FormStyled,
  FieldsSection,
  ButtonSection,
} from './styles';
import { useAddMetricSchema } from '../../hooks/useAddMetricSchema';
import { Specialization } from '@docbay/schemas';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  createMetric,
  editMetric,
  fetchSpecializations,
  fetchUnitsOfMeasurement,
} from 'application/store/reducers/SpecializationMetrics/ActionCreators';
import Dropdown from 'common/components/Dropdown';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import MetricNamesSection from '../MetricNamesSection';
import UnitsSection from '../UnitsSection';
import { AddMetrics } from 'features/feature-specialization-metrics/types';
import { setEditedMetric } from 'application/store/reducers/SpecializationMetrics/SpecializationMetricsSlice';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';

const AddMetricsModal: FC<AddMetricsModalProps> = ({
  title,
  opened,
  onClose,
  isEdit,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();

  const [specializationIds, setSpecializationIds] = useState<string[]>([]);

  const { specializations, editedMetric } = useAppSelector(
    (state) => state.specializationMetrics,
  );

  useEffect(() => {
    !specializations.length && dispatch(fetchSpecializations());
    dispatch(fetchUnitsOfMeasurement());
  }, [specializations]);

  const specializationsOptions = useMemo(() => {
    const options = specializations.map((item) => {
      const specializationName = item[localizeNameKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });
    return options;
  }, [specializations, i18n.language]);

  const addMetricSchema = useAddMetricSchema();

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<AddMetrics>({
    resolver: yupResolver(addMetricSchema),
  });

  useEffect(() => {
    if (editedMetric) {
      const specializationIds = editedMetric.specializations.map(
        (item) => item.id,
      );

      setSpecializationIds(specializationIds);
      setValue('specializationIds', specializationIds);
    }
  }, [editedMetric]);

  const handleCloseModal = () => {
    reset();
    setSpecializationIds([]);
    onClose();
    dispatch(setEditedMetric(null));
  };

  const getParamsData = () => {
    const { specializationIds, metric, unit } = getValues();

    const localizations = metric?.map((item) => {
      const currentUnit = unit?.find((u) => u.lang === item.lang);

      return {
        languageIsoCode: item.lang,
        translation: item.name!,
        unitOfMeasurementId: currentUnit?.id!,
      };
    });

    return {
      localizations,
      specializationIds,
    };
  };

  const onSubmit = async () => {
    const metricData = getParamsData();

    if (isEdit) {
      const params = {
        id: editedMetric?.id!,
        data: metricData,
      };

      dispatch(editMetric(params)).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          handleCloseModal();
        }
      });
    } else {
      dispatch(createMetric(metricData)).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          handleCloseModal();
        }
      });
    }
  };

  const isStateChanged = useMemo(() => {
    if (!isEdit) return;

    const metricData = getParamsData();
    const specializationIds = editedMetric?.specializations?.map(
      (item) => item.id,
    );
    const newLocalizations = editedMetric?.localizations?.map((item) => {
      return {
        languageIsoCode: item.languageIsoCode,
        translation: item.translation!,
        unitOfMeasurementId: item.unitOfMeasurement?.id,
      };
    });

    const hasChanges = compareObjectsChanges(metricData, {
      localizations: newLocalizations,
      specializationIds,
    });

    return hasChanges;
  }, [editedMetric, watch()]);

  return (
    <Modal open={opened}>
      <>
        <StyledModal>
          <Header>
            <h1>{title}</h1>
            <button type="button" onClick={handleCloseModal}>
              <CloseIcon />
            </button>
          </Header>
          <FormStyled>
            <FieldsSection>
              <MetricNamesSection
                getValues={getValues}
                register={register}
                errors={errors}
                control={control}
                isEdit={!!isEdit}
              />
              <UnitsSection
                errors={errors}
                control={control}
                setValue={setValue}
                watch={watch}
                isEdit={!!isEdit}
              />
              <Dropdown
                id={'specializationIds'}
                value={specializationIds}
                label={t('specialization_metrics.assign_to_specialization')}
                placeholder={t(
                  'specialization_metrics.assign_to_specialization_placeholder',
                )}
                onChange={(value) => {
                  setValue('specializationIds', value as string[], {
                    shouldValidate: !!errors.specializationIds,
                  });
                  setSpecializationIds(value as string[]);
                }}
                options={specializationsOptions}
                withSearch={true}
                isMulti={true}
                errorMessage={
                  errors?.specializationIds ? t('errors.required') : ''
                }
                sortByChosen
              />
            </FieldsSection>
          </FormStyled>
          <ButtonSection>
            <SecondaryButton type="button" onClick={handleCloseModal}>
              {t('global.cancel')}
            </SecondaryButton>
            <PrimaryButton
              type="button"
              disabled={isEdit ? !isStateChanged : !!Object.keys(errors).length}
              onClick={handleSubmit(onSubmit)}
            >
              {t('global.save')}
            </PrimaryButton>
          </ButtonSection>
        </StyledModal>
      </>
    </Modal>
  );
};

export default AddMetricsModal;
