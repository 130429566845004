import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PickIcon } from 'application/assets/pick.svg';
import { StyledTr } from './styles';

interface Props {
  onOpenPopup: () => void;
}

const ImportedDoctorsNoDataElement: FC<Props> = ({ onOpenPopup }) => {
  const { t } = useTranslation();
  return (
    <StyledTr>
      <td colSpan={4}>
        <div>
          <PickIcon />
          <div>
            <p>{t('users_managment.imported_doctors.no_data_text1')}</p>
            <p>
              {t('users_managment.imported_doctors.no_data_text2')}{' '}
              <span onClick={onOpenPopup}>
                {t('users_managment.imported_doctors.import_file')}
              </span>
            </p>
          </div>
        </div>
      </td>
    </StyledTr>
  );
};
export default ImportedDoctorsNoDataElement;
