import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';
import MetricsTable from '../MetricsTable';

const Main: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h1>{t('specialization_metrics.title')}</h1>
      <MetricsTable />
    </Wrapper>
  );
};

export default Main;
