import { createSlice } from '@reduxjs/toolkit';
import {
  addSpecialization,
  fetchPaginatedSpecializations,
  deactivateSpecialization,
  activateSpecialization,
  editSpecializations,
} from './ActionCreators';
import { PaginatedSpecializationsState } from './models';

const initialState: PaginatedSpecializationsState = {
  specializations: [],
  created: false,
  deleted: false,
  edited: false,
  activated: false,
  total: 0,
  page: 0,
  totalPages: 0,
  isLoading: false,
  error: '',
};

const paginatedSpecializationsSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {
    setCreated: (state, action) => {
      state.created = action.payload;
    },
    setDeleted: (state, action) => {
      state.deleted = action.payload;
    },
    setActivated: (state, action) => {
      state.activated = action.payload;
    },
    setEdited: (state, action) => {
      state.edited = action.payload;
    },
  },
  extraReducers: (builder) => {
    //specializations fetch
    builder.addCase(fetchPaginatedSpecializations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPaginatedSpecializations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(
      fetchPaginatedSpecializations.fulfilled,
      (state, action) => {
        state.specializations = action.payload.entities;
        state.total = action.payload.total;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.isLoading = false;
      },
    );
    // add specializations
    builder.addCase(addSpecialization.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addSpecialization.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(addSpecialization.fulfilled, (state, action) => {
      state.created = !!action.payload;
      state.isLoading = false;
    });
    // deactivate specializations
    builder.addCase(deactivateSpecialization.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deactivateSpecialization.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deactivateSpecialization.fulfilled, (state, action) => {
      state.deleted = !!action.payload;
      state.isLoading = false;
    });
    // activate specializations
    builder.addCase(activateSpecialization.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(activateSpecialization.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(activateSpecialization.fulfilled, (state, action) => {
      state.activated = !!action.payload;
      state.isLoading = false;
    });
    // edit insurance
    // edit insurance
    builder.addCase(editSpecializations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editSpecializations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(editSpecializations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.edited = true;
    });
  },
});

export const { setCreated, setDeleted, setActivated, setEdited } =
  paginatedSpecializationsSlice.actions;
export default paginatedSpecializationsSlice.reducer;
