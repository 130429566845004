import axiosInstance from 'integration/api/axiosInstance';
import {
  CommonEntitiesListResponse,
  PatientResponseDto,
} from '@docbay/schemas';
import { fetchPatientsProps } from 'common/types/patients';

//TODO Should be replaced with new endpoint for fetching users without filtering
export const PatientsApi = {
  fetchPatients: (params: fetchPatientsProps) =>
    axiosInstance.get<CommonEntitiesListResponse<PatientResponseDto>>(
      '/saas/users-management/patients',
      {
        params,
      },
    ),
};
