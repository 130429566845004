import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'application/assets/delete.svg';
import { ReactComponent as EditIcon } from 'application/assets/edit-square.svg';
import { Pagination } from 'common/components';
import { TableProps } from './models';
import {
  StyledTr,
  TableStyled,
  ActionButtonStyled,
  ActionButtonsGroupStyled,
} from './styles';

const TableSpecializations: FC<TableProps> = ({
  columns,
  data,
  totalPages,
  currentPage,
  setCurrentPage,
  handleDelete,
  handleActivate,
  handleEditModalOpen,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TableStyled>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={column + index}>
                <span>{column}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <StyledTr key={item.id}>
              <td>
                <span>{`${item.name_EN} (EN)`}</span>
                <span>{`${item.name_PT} (PT)`}</span>
                <span>{`${item.name_FR} (FR)`}</span>
              </td>
              <td>
                <ActionButtonsGroupStyled>
                  <ActionButtonStyled
                    type="button"
                    onClick={() =>
                      handleEditModalOpen(item.id, true, {
                        name_PT: item.name_PT,
                        name_EN: item.name_EN,
                        name_FR: item.name_FR,
                      })
                    }
                  >
                    <EditIcon />
                  </ActionButtonStyled>
                  {item.active ? (
                    <ActionButtonStyled
                      type="button"
                      onClick={() => handleDelete(item.id)}
                    >
                      {t('databases_feature.deactivate')}
                      <DeleteIcon />
                    </ActionButtonStyled>
                  ) : (
                    <ActionButtonStyled
                      type="button"
                      onClick={() => handleActivate(item.id)}
                    >
                      {t('databases_feature.activate')}
                    </ActionButtonStyled>
                  )}
                </ActionButtonsGroupStyled>
              </td>
            </StyledTr>
          ))}
        </tbody>
      </TableStyled>
      {!!data?.length && (
        <Pagination
          pages={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          itemCount={data.length || 0}
        />
      )}
    </>
  );
};

export default TableSpecializations;
