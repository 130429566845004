import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { InsurancesActions } from 'application/constants/actions';
import { fetchInsurancesProps } from 'common/types/insurances';
import { InsurancesAPI } from 'integration/api/insurances';
import { InsuranceCreationDto, InsuranceUpdateDto } from '@docbay/schemas';

export const fetchInsurances = createAsyncThunk(
  InsurancesActions.fetchInsurances,
  async (params: fetchInsurancesProps, { rejectWithValue }) => {
    try {
      const { data } = await InsurancesAPI.fetchInsurances(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteInsurances = createAsyncThunk(
  InsurancesActions.deleteInsurances,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await InsurancesAPI.deleteInsurances(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const addInsurances = createAsyncThunk(
  InsurancesActions.addInsurances,
  async (params: InsuranceCreationDto, { rejectWithValue }) => {
    try {
      const { data } = await InsurancesAPI.addInsurances(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const editInsurances = createAsyncThunk(
  InsurancesActions.editInsurances,
  async (
    params: { data: InsuranceUpdateDto; id: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await InsurancesAPI.editInsurances(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
