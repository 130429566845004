import React, { FC } from 'react';
import { CreatePassword } from 'features';
import { Wrapper } from './styles';

const CreatePasswordPage: FC = () => (
  <Wrapper>
    <CreatePassword />
  </Wrapper>
);

export default CreatePasswordPage;
