import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, PrimaryButton } from 'common/components';
import {
  TableStyled,
  SearchStyled,
} from 'features/feature-users-managment/components/Main/styles';
import { Input } from 'common/components';
import { ReactComponent as AddIcon } from 'application/assets/add.svg';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import Pagination from 'common/components/Pagination';
import { useDebounce } from 'common/hooks/useDebounce';
import { Wrapper } from 'features/feature-users-managment/components/PatientsList/styles';
import NoDataElement from 'features/feature-users-managment/components/NoDataElement';
import Dropdown from 'common/components/Dropdown';
import { TypesList } from 'features/feature-users-managment/constants';
import { Option } from 'features/feature-users-managment/models';
import InternalUsersItem from 'features/feature-users-managment/components/InternalUsersItem';
import { fetchInternalUsers } from 'application/store/reducers/InternalUsers/ActionCreators';
import { FiltersSectionStyled } from './styles';
import InternalUserActionModal from '../InternalUserActionModal';

const defaultLimit = 10;

const InternalUsers: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const debouncedInput = useDebounce(inputValue, 500);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUserType, setCurrentUserType] = useState<string[]>([]);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const { isLoading, internalUsers, totalPages } = useAppSelector(
    (state) => state.internalUsers,
  );
  const { isLoading: isResetPassword } = useAppSelector(
    (state) => state.userResetPassword,
  );

  const getCurrentData = useCallback(
    async (page: number) => {
      const userTypes = currentUserType.filter((item) => !!item);
      await dispatch(
        fetchInternalUsers({
          ...(debouncedInput.length > 0 ? { search: debouncedInput } : {}),
          ...(userTypes.length ? { userTypes: userTypes } : {}),
          limit: defaultLimit,
          page: page,
        }),
      );
      setCurrentPage(page);
    },
    [debouncedInput, currentUserType, currentPage],
  );

  const handleSearch = (value: string) => {
    setCurrentPage(1);
    setInputValue(value);
  };

  useEffect(() => {
    getCurrentData(currentPage);
  }, [debouncedInput, currentUserType]);

  const clearSearch = () => {
    setInputValue('');
  };

  const handleTypeChange = (data: Option) => {
    setCurrentPage(1);
    setCurrentUserType([data.value]);
  };

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleCloseCreateModal = async () => {
    setOpenCreateModal(false);
    await getCurrentData(1);
  };
  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = async () => {
    setOpenEditModal(false);
    await getCurrentData(currentPage);
  };

  const handleDelete = async () => {
    await getCurrentData(currentPage);
  };

  return (
    <Wrapper>
      {(isLoading || isResetPassword) && <Loader />}
      <FiltersSectionStyled>
        <SearchStyled>
          <Input
            id={'patientName'}
            type="search"
            value={inputValue}
            onClear={clearSearch}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={t('users_managment.search_by_name_placeholder')}
          />
          <div className={'dropdown'}>
            <Dropdown
              id={'type'}
              placeholder={'Type'}
              value={currentUserType}
              onChange={(data) => {
                handleTypeChange(data as Option);
              }}
              options={TypesList}
            />
          </div>
        </SearchStyled>
        <PrimaryButton onClick={handleOpenCreateModal}>
          <AddIcon />
          {t('global.add_new')}
        </PrimaryButton>
      </FiltersSectionStyled>

      <TableStyled>
        <table>
          <thead>
            <tr>
              <th>
                <span>{t('users_managment.lists.user_name_title')}</span>
              </th>
              <th>{t('global.type')}</th>
              <th className="actionsColumn">
                <span>{t('users_managment.lists.actions_title')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {!internalUsers?.length ? (
              <NoDataElement />
            ) : (
              internalUsers?.map((user: any) => (
                <InternalUsersItem
                  key={`${user.user.userTypes[0]}_${user.id}`}
                  user={user}
                  onEdit={handleOpenEditModal}
                  onDelete={handleDelete}
                />
              ))
            )}
          </tbody>
        </table>
      </TableStyled>
      {totalPages > 1 ? (
        <Pagination
          pages={totalPages}
          setCurrentPage={(page) => getCurrentData(page)}
          currentPage={currentPage}
          itemCount={internalUsers.length || 0}
        />
      ) : (
        ''
      )}
      <InternalUserActionModal
        title={t('users_managment.internal_users_create.add_new_user')}
        opened={openCreateModal}
        onClose={handleCloseCreateModal}
      />
      <InternalUserActionModal
        title={t('users_managment.internal_users_create.edit_user')}
        opened={openEditModal}
        onClose={handleCloseEditModal}
        isEdit
      />
    </Wrapper>
  );
};

export default InternalUsers;
