import { createSlice } from '@reduxjs/toolkit';
import { AdminOwnerState } from './models';

const initialState: AdminOwnerState = {
  currentUser: null,
  isLoading: false,
  error: '',
};

const adminOwnerSlice = createSlice({
  name: 'adminOwnerSlice',
  initialState,
  reducers: {
    setCurrentData: (state, action) => {
      state.currentUser = action.payload;
    },
  },
});

export default adminOwnerSlice.reducer;
