import React, { FC } from 'react';
import { Footer, Form, Header } from './components';
import { Wrapper } from './styles';

const CreatePassword: FC = () => {
  return (
    <Wrapper>
      <div>
        <Header />
        <Form />
      </div>
      <Footer />
    </Wrapper>
  );
};

export default CreatePassword;
