import React, { FC } from 'react';
import { SideMenu, ChangePassword } from 'features';
import { Wrapper } from './styles';

const PasswordPage: FC = () => (
  <Wrapper>
    <SideMenu />
    <ChangePassword />
  </Wrapper>
);

export default PasswordPage;
