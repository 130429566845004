import {
  CommonEntitiesListResponse,
  CountryCreationDto,
} from '@docbay/schemas';
import axiosInstance from 'integration/api/axiosInstance';
import { SupportedCountriesDto } from 'common/types/countries';

export const CountriesAPI = {
  getSupportedCountries: async () => {
    return await axiosInstance.get<
      CommonEntitiesListResponse<SupportedCountriesDto>
    >('/countries/supported');
  },
  getAllCountries: async () => {
    return await axiosInstance.get<
      CommonEntitiesListResponse<SupportedCountriesDto>
    >('/countries');
  },
  addToSupportedCountries: async (params: CountryCreationDto) => {
    return await axiosInstance.post<CountryCreationDto>(
      '/countries/supported',
      params,
    );
  },
  deleteSupportedCountry: async (isoCode: string) => {
    return await axiosInstance.delete(`/countries/supported/${isoCode}`);
  },
};
