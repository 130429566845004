import styled from 'styled-components';

export const HeaderStyled = styled.header`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  h1 {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 8px;
  }

  p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.4px;
  }
`;
