import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import {
  SecondaryButton,
  PrimaryButton,
  Input,
  Loader,
  SuccessModal,
  CountryDropdown,
} from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { ReactComponent as AddIcon } from 'application/assets/add.svg';
import { InternalUserAddModalProps } from './models';
import {
  StyledModal,
  Header,
  FormStyled,
  FieldsSection,
  ButtonSection,
  AddClinicButton,
} from './styles';
import { setSuccessCreated } from 'application/store/reducers/InternalUsers/InternalUsersSlice';
import { useCreateDoctorSchema } from '../../hooks/useCreateDoctorSchema';
import { AddProfessionalDataT } from '../../types/AddProfessionalDataT';
import Dropdown from 'common/components/Dropdown';
import { Specialization } from '@docbay/schemas';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import { AddProfessionalData } from '../../constants';
import { ExternalClinicResponseDto } from '@docbay/schemas';
import ClinicForm from './ClinicForm';
import { v4 as uuid } from 'uuid';
import { addProfessionals } from 'application/store/reducers/ImportProfessionals/ActionCreators';
import Emails from '../Emails';
import Phones from '../Phones';
import { SupportedCountriesDto } from 'common/types/countries';

const ImportedDoctorAddModal: FC<InternalUserAddModalProps> = ({
  opened,
  onClose,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [specializationIds, setSpecializationIds] = useState<string[]>([]);
  const [clinicsData, setClinicsData] = useState<ExternalClinicResponseDto[]>(
    [],
  );
  const { localizeNameKey } = useLocalizeKey();

  const createDoctorScheme = useCreateDoctorSchema();
  const { isLoading, successCreated } = useAppSelector(
    (state) => state.importProfessionals,
  );
  const { specializations } = useAppSelector((state) => state.statistics);

  const specializationsOptions = useMemo(() => {
    const options = specializations.map((item) => {
      const specializationName = item[localizeNameKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });
    return options;
  }, [specializations, i18n.language]);

  const addNewClinic = () => {
    const newClinicData = [
      ...clinicsData,
      { ...AddProfessionalData, id: uuid() },
    ];
    setClinicsData(newClinicData);
  };

  const {
    register,
    watch,
    reset,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    clearErrors,
    setError,
    control,
    formState: { errors },
  } = useForm<AddProfessionalDataT>({
    resolver: yupResolver(createDoctorScheme),
  });

  const onSubmit = async () => {
    const { firstName, lastName, emails, clinics, phones, supportedCountry } =
      getValues();

    const formattedClinicsData = clinics.map((item) => {
      const formattedClinicPhones = item?.phones?.map((phone) => {
        return `+${phone}`;
      });

      return {
        name: item.name,
        ...(item.phones?.length ? { phones: formattedClinicPhones } : {}),
        ...(item?.emails?.length ? { emails: item.emails } : {}),
        ...(item.region ? { region: item.region } : {}),
        ...(item.city ? { city: item.city } : {}),
        ...(item.street ? { street: item.street } : {}),
        ...(item.latitude ? { latitude: item.latitude } : {}),
        ...(item.longitude ? { longitude: item.longitude } : {}),
        supportedCountryId: item.supportedCountry?.id,
      };
    });

    const formattedPhones = phones.map((phone) => {
      return `+${phone}`;
    });

    const professionalData = {
      firstName,
      lastName,
      specializationIds,
      primaryPhone: `+${phones[0]}`,
      emails,
      phones: formattedPhones,
      ...(specializationIds ? { specializationIds } : {}),
      clinics: formattedClinicsData,
      supportedCountryId: supportedCountry?.id,
    };

    // @ts-ignore
    dispatch(addProfessionals(professionalData)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        reset();
        onClose();
      }
    });
  };

  const handleCloseModal = () => {
    reset();
    onClose();
    setSpecializationIds([]);
  };

  const handleCloseSuccessModal = () => {
    dispatch(setSuccessCreated(false));
    handleCloseModal();
  };

  const changeClinicData = (obj: ExternalClinicResponseDto, index: number) => {
    const newClinicData = [...clinicsData];
    newClinicData.splice(index, 1, obj);

    setClinicsData(newClinicData);
  };

  return (
    <Modal open={opened}>
      <>
        {isLoading && <Loader />}
        {successCreated ? (
          <SuccessModal
            title={t('users_managment.doctor_create.success_modal_title') || ''}
            opened={successCreated}
            onClose={handleCloseSuccessModal}
          />
        ) : (
          <StyledModal>
            <Header>
              <h1>{t('users_managment.doctor_create.add_new_user')}</h1>
              <button type="button" onClick={handleCloseModal}>
                <CloseIcon />
              </button>
            </Header>
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
              <FieldsSection>
                <Input
                  id={'firstName'}
                  label={t('global.first_name') || ''}
                  placeholder={t(
                    'users_managment.doctor_create.first_name_placeholder',
                  )}
                  type="text"
                  register={register}
                  errorMessage={errors.firstName?.message}
                />
                <Input
                  id={'lastName'}
                  label={t('global.last_name') || ''}
                  placeholder={t(
                    'users_managment.doctor_create.last_name_placeholder',
                  )}
                  type="text"
                  register={register}
                  errorMessage={errors.lastName?.message}
                />
                <CountryDropdown
                  id="professional-country-dropdown"
                  label={t('country_of_practice')}
                  value={watch('supportedCountry')}
                  onChange={(country) =>
                    setValue(
                      'supportedCountry',
                      country as SupportedCountriesDto,
                      {
                        shouldValidate: !!errors.supportedCountry,
                      },
                    )
                  }
                />
                <Phones
                  errors={errors}
                  control={control}
                  register={register}
                  getValues={getValues}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  setError={setError}
                />
                <Emails
                  errors={errors}
                  control={control}
                  register={register}
                  getValues={getValues}
                />
                <Dropdown
                  id={'specializationIds'}
                  value={specializationIds}
                  label={t('statistics.specializations')}
                  placeholder={t('statistics.specializations')}
                  onChange={(value) => {
                    setSpecializationIds(value as string[]);
                  }}
                  options={specializationsOptions}
                  withSearch={true}
                  isMulti={true}
                />
                <>
                  {clinicsData?.map((clinic, index) => {
                    return (
                      <ClinicForm
                        key={index}
                        clinic={clinic}
                        index={index}
                        register={register}
                        getValues={getValues}
                        watch={watch}
                        setValue={setValue}
                        trigger={trigger}
                        errors={errors}
                        control={control}
                        changeClinicData={changeClinicData}
                        clearErrors={clearErrors}
                        setError={setError}
                      />
                    );
                  })}
                </>
                <AddClinicButton onClick={addNewClinic}>
                  <AddIcon />
                  {t('users_managment.add_new_clinic')}
                </AddClinicButton>
              </FieldsSection>
              <ButtonSection>
                <SecondaryButton type="button" onClick={handleCloseModal}>
                  {t('global.cancel')}
                </SecondaryButton>
                <PrimaryButton
                  type="submit"
                  disabled={!!Object.keys(errors).length}
                >
                  {t('global.save')}
                </PrimaryButton>
              </ButtonSection>
            </FormStyled>
          </StyledModal>
        )}
      </>
    </Modal>
  );
};

export default ImportedDoctorAddModal;
