import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';

const Footer: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <h2>©DocBay {t('global.copyright')}</h2>
    </Wrapper>
  );
};

export default Footer;
