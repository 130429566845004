import { createAsyncThunk } from '@reduxjs/toolkit';
import { ImportProfessionalsActions } from 'application/constants/actions';
import { AxiosError } from 'axios';
import { ImportProfessionalsApi } from 'integration/api/importProfessionals';
import { GetImportedDoctorsParams } from 'common/types/importedDoctors';
import {
  ExternalProfessionalCreationDto,
  ExternalProfessionalUpdateDto,
} from '@docbay/schemas';

export const fetchImportedProfessionals = createAsyncThunk(
  ImportProfessionalsActions.fetchImportedProfessionals,
  async (params: GetImportedDoctorsParams, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await ImportProfessionalsApi.fetchImportedProfessionals(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getExternalProfessionalById = createAsyncThunk(
  ImportProfessionalsActions.getExternalProfessionalById,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ImportProfessionalsApi.getExternalProfessionalById(
        id,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const addProfessionals = createAsyncThunk(
  ImportProfessionalsActions.addProfessionals,
  async (data: ExternalProfessionalCreationDto, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await ImportProfessionalsApi.addProfessionals(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateProfessionals = createAsyncThunk(
  ImportProfessionalsActions.updateProfessionals,
  async (
    { id, data }: { id: string; data: ExternalProfessionalUpdateDto },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } =
        await ImportProfessionalsApi.updateProfessionals(id, data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteProfessionals = createAsyncThunk(
  ImportProfessionalsActions.deleteProfessionals,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await ImportProfessionalsApi.deleteProfessionals(id);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const uploadProfessionals = createAsyncThunk(
  ImportProfessionalsActions.uploadProfessionals,
  async (data: FormData, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await ImportProfessionalsApi.uploadProfessionals(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const uploadStatus = createAsyncThunk(
  ImportProfessionalsActions.uploadStatus,
  async (_, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await ImportProfessionalsApi.uploadStatus();
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
