import React, {
  FC,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
  MouseEvent,
} from 'react';
import {
  ExpandMoreIcon,
  CloseIcon,
  WarningTriangleIcon,
} from 'application/assets';
import useOnClickOutside from 'common/hooks/useClickOutside';
import Input from '../Input';
import { DropdownProps } from './models';
import {
  DropdownStyled,
  EmptyInput,
  OptionItem,
  OptionsList,
  OptionItemWrapper,
  DeleteSection,
  Message,
  ButtonWrapper,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useDebounce } from 'common/hooks/useDebounce';
import {
  createUnitOfMeasurement,
  deleteUnitOfMeasurement,
  fetchUnitsOfMeasurement,
} from 'application/store/reducers/SpecializationMetrics/ActionCreators';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from '../index';

const UnitOfMeasurementDropdown: FC<DropdownProps> = ({
  id,
  label,
  placeholder,
  searchPlaceholder,
  value,
  onChange,
  hint,
  disabled,
  errorMessage,
  withSearch,
  LeftIcon,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchItem, setSearchItem] = useState('');
  const searchValue = useDebounce(searchItem, 500);
  const [unitForDelete, setUnitForDelete] = useState('');
  const [cantDelete, setCantDelete] = useState(false);

  const { unitsOfMeasurement } = useAppSelector(
    (state) => state.specializationMetrics,
  );

  useOnClickOutside(ref, () => setIsActive(false));

  const optionList = useMemo(() => {
    if (!searchItem) return unitsOfMeasurement;

    return unitsOfMeasurement?.filter((item) =>
      String(item?.name!)?.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [searchValue, unitsOfMeasurement]);

  const getValue = () => {
    const currentOption = optionList.find(
      (item) => String(item.id) === String(value),
    );
    return currentOption?.name;
  };

  const handleAddUnitOfMeasurement = (event: KeyboardEvent<HTMLElement>) => {
    if (!optionList.length && event.code === 'Enter' && searchItem.length) {
      dispatch(createUnitOfMeasurement({ name: searchItem })).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          setSearchItem((event.target as HTMLInputElement).value);
          dispatch(fetchUnitsOfMeasurement());
        }
      });
    }
  };

  const handleRemoveUnit = (e: MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation();

    setUnitForDelete(id);
  };

  const onSubmitDelete = () => {
    dispatch(deleteUnitOfMeasurement(unitForDelete)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setUnitForDelete('');
        dispatch(fetchUnitsOfMeasurement());
      }

      if (data.meta.requestStatus === 'rejected') {
        if (data.payload.statusCode === 409) {
          setCantDelete(true);
        }
      }
    });
  };

  const handleCloseDeleteSection = () => {
    setCantDelete(false);
    setUnitForDelete('');
  };

  return (
    <DropdownStyled
      isActive={isActive}
      disabled={disabled}
      ref={ref}
      isError={!!errorMessage}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <section onMouseDown={() => !disabled && setIsActive(!isActive)}>
        {LeftIcon ? (
          <div>
            <LeftIcon />
            {!value.length ? <span>{placeholder}</span> : <h2>{getValue()}</h2>}
          </div>
        ) : (
          <>
            {!value.length ? <span>{placeholder}</span> : <h2>{getValue()}</h2>}
          </>
        )}
        <ExpandMoreIcon />
      </section>
      {isActive && (
        <OptionsList className="optionList">
          {withSearch && (
            <Input
              id={'search'}
              type="search"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target?.value || '')}
              placeholder={searchPlaceholder}
              onClear={() => setSearchItem('')}
              onKeyDown={handleAddUnitOfMeasurement}
            />
          )}
          {!optionList.length && searchItem.length ? (
            <EmptyInput>
              {t('specialization_metrics.create_unit_text')}
            </EmptyInput>
          ) : (
            optionList.map((item) => (
              <OptionItemWrapper
                forDelete={item.id === unitForDelete}
                key={item.name}
              >
                <OptionItem
                  onClick={() => {
                    onChange(item);
                    setIsActive(false);
                  }}
                  selected={value === String(item.id)}
                >
                  {item.name}

                  <CloseIcon
                    onClick={(e: MouseEvent<HTMLElement>) =>
                      handleRemoveUnit(e, item.id)
                    }
                  />
                </OptionItem>
                {item.id === unitForDelete &&
                  (cantDelete ? (
                    <DeleteSection>
                      <Message>
                        <WarningTriangleIcon />
                        {t('specialization_metrics.cant_delete_unit')}
                      </Message>
                      <ButtonWrapper>
                        <SecondaryButton
                          type={'button'}
                          onClick={handleCloseDeleteSection}
                        >
                          {t('global.got_it')}
                        </SecondaryButton>
                      </ButtonWrapper>
                    </DeleteSection>
                  ) : (
                    <DeleteSection>
                      <Message>
                        <WarningTriangleIcon />
                        {t('specialization_metrics.confirm_delete_unit')}
                      </Message>
                      <ButtonWrapper>
                        <SecondaryButton
                          type={'button'}
                          onClick={() => setUnitForDelete('')}
                        >
                          {t('global.cancel')}
                        </SecondaryButton>
                        <PrimaryButton
                          onClick={onSubmitDelete}
                          styleType={'error'}
                          type={'button'}
                        >
                          {t('global.confirm')}
                        </PrimaryButton>
                      </ButtonWrapper>
                    </DeleteSection>
                  ))}
              </OptionItemWrapper>
            ))
          )}
        </OptionsList>
      )}

      {(hint || errorMessage) && (
        <span>{errorMessage ? errorMessage : hint}</span>
      )}
    </DropdownStyled>
  );
};

export default UnitOfMeasurementDropdown;
