import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useUsersTypesList = () => {
  const { t } = useTranslation();
  const typesList = useMemo(() => {
    return [
      {
        value: 'AdminTech',
        label: t('users_managment.internal_user_types.admin_tech'),
      },
      {
        value: 'AdminCustomerTech',
        label: t('users_managment.internal_user_types.admin_customer_tech'),
      },
    ];
  }, [t]);

  return typesList;
};
