import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RowProps } from './models';
import { ReactComponent as DeleteIcon } from 'application/assets/delete.svg';
import { ReactComponent as EditIcon } from 'application/assets/edit-square.svg';
import { StyledTr, ActionWrapper } from './styles';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import DeleteModal from '../DeleteModal';
import { setEditedMetric } from 'application/store/reducers/SpecializationMetrics/SpecializationMetricsSlice';
import { deleteMetric } from 'application/store/reducers/SpecializationMetrics/ActionCreators';

const MetricsTableRow: FC<RowProps> = ({ row, onEdit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  const { localizeNameKey } = useLocalizeKey();
  const { supportedLanguages } = useAppSelector((state) => state.languages);

  const isSupportedEnglishLang = supportedLanguages.some(
    (lang) => lang.isoCode === 'en',
  );
  const metricEn = row?.localizations.find(
    (item) => item.languageIsoCode === 'en',
  );

  const restLangs = supportedLanguages.filter((item) => item.isoCode !== 'en');

  const restMetricsNames = restLangs?.map((item) => {
    const currentMetric = row.localizations.find(
      (metric) => metric.languageIsoCode === item.isoCode,
    );

    return currentMetric?.translation;
  });
  const restMetricsUnits = restLangs?.map((item) => {
    const currentMetric = row.localizations.find(
      (metric) => metric.languageIsoCode === item.isoCode,
    );

    return currentMetric?.unitOfMeasurement?.name;
  });

  const metricNameCol = (
    <div>
      {isSupportedEnglishLang && (
        <div className={'firstCol'}>{metricEn?.translation}</div>
      )}
      <div>{restMetricsNames?.join(' / ')}</div>
    </div>
  );

  const unitsCol = (
    <div>
      {isSupportedEnglishLang && (
        <div className={'firstCol'}>{metricEn?.unitOfMeasurement.name}</div>
      )}
      <div>{restMetricsUnits.join(' / ')}</div>
    </div>
  );

  const getSpecializations = () => {
    const specializationsList = row.specializations?.map(
      (specialization) => specialization[localizeNameKey],
    );
    return specializationsList?.join(', ');
  };

  const handleDelete = () => {
    setIsShowDeleteModal(false);

    dispatch(deleteMetric(row.id!));
  };

  const handleEdit = () => {
    onEdit();
    dispatch(setEditedMetric(row));
  };

  return (
    <StyledTr>
      <td>{metricNameCol}</td>
      <td>{unitsCol}</td>
      <td>
        <span>{getSpecializations()}</span>
      </td>
      <td>
        <ActionWrapper>
          <button onClick={handleEdit}>
            <EditIcon />
          </button>
          <button onClick={() => setIsShowDeleteModal(true)}>
            <DeleteIcon />
          </button>
        </ActionWrapper>
      </td>
      <DeleteModal
        isOpen={isShowDeleteModal}
        text={t('specialization_metrics.confirm_delete')}
        onClose={() => {
          setIsShowDeleteModal(false);
        }}
        onSubmit={handleDelete}
      />
    </StyledTr>
  );
};

export default MetricsTableRow;
