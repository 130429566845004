import styled from 'styled-components';

export const HeaderStyled = styled.header`
  width: 100%;
  height: fit-content;

  h1 {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 33.6px;
    letter-spacing: 0.1px;
  }
`;
