import React from 'react';
import { TableStyled, TableWrapper } from './styles';
import { useAppSelector } from 'common/hooks/redux';
import { platformMetricsList } from 'features/feature-statistics/constants';
import { Option } from 'application/store/reducers/Statistics/models';
import PlatformMetricTableName from '../PlatformMetricTableName';
import MetricTableCol from '../MetricTableCol';
import { useTranslation } from 'react-i18next';

const PlatformStatisticsTable = () => {
  const { t } = useTranslation();
  const { platformStatistics } = useAppSelector((state) => state.statistics);

  return (
    <TableWrapper>
      <TableStyled>
        <table>
          <thead>
            <tr>
              <th>
                <div className={'firstColumn'}>
                  <span>{t('statistics.cities')}</span>
                </div>
              </th>
              {platformMetricsList?.map((metricName: Option, ind) => {
                return (
                  <PlatformMetricTableName
                    key={`${metricName.value}_${ind}`}
                    metricName={metricName}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {platformStatistics?.total && (
              <tr>
                <td className={'firstColItem'}>{t('statistics.total')}</td>
                <td>
                  <span>
                    {platformStatistics?.total.patientPlatformViews || 0}
                  </span>
                </td>
                <td>
                  <span>
                    {platformStatistics?.total.searchesFromPatientWeb || 0}
                  </span>
                </td>
                <td>
                  <span>
                    {platformStatistics?.total.searchesFromPatientMobile || 0}
                  </span>
                </td>
                <td>
                  <span>
                    {platformStatistics?.total.searchesWithEmptyResult || 0}
                  </span>
                </td>
              </tr>
            )}

            {platformStatistics?.perCity?.entities?.map((item, ind) => {
              return (
                <tr key={`${item.city}_${ind}`}>
                  <td className={'firstColItem'}>{item.city}</td>
                  {platformMetricsList?.map((metricName: Option) => {
                    return (
                      <MetricTableCol
                        value={item}
                        item={metricName.value}
                        key={`${metricName.value}_${ind}`}
                        metricsName={metricName.label}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableStyled>
    </TableWrapper>
  );
};

export default PlatformStatisticsTable;
