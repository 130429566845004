import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from 'application/assets/checked.svg';
import { ActionStyled } from './styles';
import { ResetPasswordActionProps } from './modules';

const ResetPasswordAction: FC<ResetPasswordActionProps> = ({
  handleResetPassword,
  isPasswordReseted,
}) => {
  const { t } = useTranslation();

  return (
    <ActionStyled>
      {isPasswordReseted && <CheckIcon />}
      <p
        onClick={handleResetPassword}
        className={isPasswordReseted ? 'sent' : 'reset'}
      >
        {isPasswordReseted
          ? t('users_managment.lists.sent')
          : t('users_managment.lists.reset_password')}
      </p>
    </ActionStyled>
  );
};
export default ResetPasswordAction;
