import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  DateWrapper,
  FilterSection,
  LeftFilters,
  MetricsFilter,
  Wrapper,
} from './styles';
import Dropdown from 'common/components/Dropdown';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { CountryDropdown, Input, Loader } from 'common/components';
import {
  fetchClinics,
  fetchPerDoctorsMetrics,
  fetchSpecializations,
} from 'application/store/reducers/Statistics/ActionCreators';
import { genderList, metricsList } from 'features/feature-statistics/constants';
import {
  setMetricsData,
  setPerDoctorsFilters,
} from 'application/store/reducers/Statistics/StatisticsSlice';
import PerDoctorsMetricsTable from 'features/feature-statistics/components/PerDoctorsMetricsTable';
import DatePicker from 'common/components/DatePicker';
import dayjs from 'dayjs';
import Pagination from 'common/components/Pagination';
import { AgeWrapper } from '../PerClinics/styles';
import { useDebounce } from 'common/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { getLanguageFromLocalStorage } from 'application/sessionStorage/languages';
import { Gender, Specialization } from '@docbay/schemas';
import {
  onCityChange,
  onDateChange,
  removeKeysFromObject,
} from '../../helpers';
import { PerDoctorsDataDto, PerDoctorsProps } from './models';
import { SupportedCountriesDto } from 'common/types/countries';
import { useStatisticFilters } from 'common/hooks/useStatisticFilters';

const PerDoctors: FC<PerDoctorsProps> = ({
  supportedCountries,
  setSupportedCountries,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCities, setCurrentCities] = useState<string[]>([]);
  const [currentMetrics, setMetrics] = useState<string[]>([]);
  const [timeFrom, setTimeFrom] = useState<Date | null>(null);
  const [timeTo, setTimeTo] = useState<Date | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [ageFrom, setAgeFrom] = useState('');
  const [ageTo, setAgeTo] = useState('');
  const [isAgeError, setIsAgeError] = useState(false);
  const [citiesNames, setCitiesNames] = useState<string[]>([]);
  const [gender, setGender] = useState<string[]>([]);
  const [specializationIds, setSpecializationIds] = useState<string[]>([]);
  const [clinicIds, setClinicIds] = useState<string[]>([]);

  const debouncedInput = useDebounce(inputValue, 500);
  const debouncedAgeFrom = useDebounce(ageFrom, 500);
  const debouncedAgeTo = useDebounce(ageTo, 500);
  const {
    isLoading,
    metrics,
    perDoctorsMetricsData,
    specializations,
    clinics,
  } = useAppSelector((state) => state.statistics);
  const { cities, fetchCities } = useStatisticFilters();

  const getCurrenData = async (data?: PerDoctorsDataDto) => {
    if (isAgeError) return;

    const page = data?.page || currentPage;
    const cities = data?.citiesNames || citiesNames;
    const dataTimeFrom = data?.timeFrom || timeFrom;
    const dataTimeTo = data?.timeTo || timeTo;
    const dataAgeFrom = data?.ageFrom || ageFrom;
    const dataAgeTo = data?.ageTo || ageTo;
    const dataGender = data?.gender || gender;
    const dataSpecializationIds = data?.specializationIds || specializationIds;
    const dataClinics = data?.clinics || clinicIds;
    const dataSearchText =
      data?.searchText === '' ? data.searchText : inputValue;

    const params = {
      page: page,
      limit: 10,
      ...(dataAgeFrom.length || dataAgeTo.length
        ? {
            ageRange: {
              min: dataAgeFrom && dataAgeFrom !== '0' ? +dataAgeFrom : 1,
              max:
                dataAgeTo && dataAgeTo !== '0' && Number(dataAgeTo) < 121
                  ? +dataAgeTo
                  : 120,
            },
          }
        : {}),
      ...(dataGender.length ? { gender: dataGender as Gender[] } : {}),
      ...(dataSearchText ? { search: dataSearchText } : {}),
      ...(cities.length ? { cities: cities } : {}),
      ...(dataClinics.length ? { clinicIds: dataClinics } : {}),
      ...(dataSpecializationIds.length
        ? { specializationIds: dataSpecializationIds }
        : {}),
      ...(dataTimeFrom
        ? {
            timeRange: {
              start: dayjs(dataTimeFrom).utc(true).toDate() as Date,
              end: (dayjs(dataTimeTo).utc(true).format() ||
                dayjs().utc(true).toDate()) as Date,
            },
          }
        : {}),
      ...(supportedCountries.length
        ? {
            supportedCountriesIso: supportedCountries.map((item) => item.code),
          }
        : {}),
    };

    await dispatch(fetchPerDoctorsMetrics(params));

    // remove page and limit from the object since we do not want to limit the statistics to pagination parameters
    dispatch(
      setPerDoctorsFilters(removeKeysFromObject(params, ['page', 'limit'])),
    );
  };

  useEffect(() => {
    const getData = async () => {
      await dispatch(fetchClinics({}));
      await dispatch(fetchSpecializations());
    };

    getData();
  }, []);

  useEffect(() => {
    getCurrenData({ page: 1 });
    fetchCities(supportedCountries);
  }, [supportedCountries]);

  useEffect(() => {
    if (debouncedAgeFrom.length > 0 || debouncedAgeTo.length > 0) {
      getCurrenData({ page: 1 });
    }
  }, [debouncedAgeFrom, debouncedAgeTo]);

  useEffect(() => {
    setMetrics(metrics);
  }, [metrics]);

  const citiesOptions = useMemo(() => {
    const formatted = cities?.map((item) => {
      return { label: item.city, value: item.id };
    });

    return formatted;
  }, [cities]);

  const clinicOptions = useMemo(() => {
    const formatted = clinics?.length
      ? clinics?.map((item) => {
          return { label: item.name, value: item.id };
        })
      : [];

    return formatted;
  }, [clinics]);

  const handleCityChange = (data: string[]) => {
    const citiesData = onCityChange(data, citiesOptions);

    getCurrenData({ page: 1, citiesNames: citiesData.citiesLabels });
    setCurrentCities(citiesData.data);
    setCitiesNames(citiesData.citiesLabels);
  };

  const handleGenderChange = (data: string[]) => {
    getCurrenData({ gender: data, page: 1 });
    setGender(data);
  };

  const handleAgeChange = (value: string, type: string) => {
    if (type === 'from') {
      setIsAgeError(Number(value) > Number(ageTo ? ageTo : 120));
      setAgeFrom(value);
    } else {
      setIsAgeError(Number(value) < Number(ageFrom ? ageFrom : 1));
      setAgeTo(value);
    }
  };

  const handleDateToChange = (date: string) => {
    const dateTimeTo = onDateChange(date, 'to');

    getCurrenData({ page: 1, timeTo: dateTimeTo });
    setTimeTo(timeTo!);
  };

  const handleDateFromChange = (date: string) => {
    const dateTimeFrom = onDateChange(date, 'from');

    getCurrenData({
      page: 1,
      timeFrom: dateTimeFrom,
      ...(!timeTo
        ? { timeTo: dayjs().set('hours', 23).set('minutes', 59).toDate() }
        : { timeTo }),
    });

    if (!timeTo) {
      setTimeTo(dayjs().set('hours', 23).set('minutes', 59).toDate());
    }
    setTimeFrom(dateTimeFrom);
  };

  const clearSearch = () => {
    getCurrenData({ page: 1, searchText: '' });
    setInputValue('');
  };

  const handleSearch = (value: string) => {
    setInputValue(value);
    if (!value) {
      clearSearch();
    }
  };

  useEffect(() => {
    if (debouncedInput.length > 2) {
      getCurrenData();
    }
  }, [debouncedInput]);

  const specializationsOptions = useMemo(() => {
    const currentLanguage = getLanguageFromLocalStorage();
    const specializationLangKey = `name_${currentLanguage.toUpperCase()}` as
      | 'name_EN'
      | 'name_PT';

    const options = specializations?.map((item) => {
      const specializationName =
        item[specializationLangKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });
    return options;
  }, [specializations, i18n.language]);

  const handlePagination = (page: number) => {
    getCurrenData({ page });
    setCurrentPage(page);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <FilterSection>
        <LeftFilters>
          <div>
            <Input
              id={'doctorName'}
              type="search"
              value={inputValue}
              onClear={clearSearch}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={t('statistics.search_by_doctors_name')}
            />
            <DateWrapper>
              <DatePicker
                id={'from'}
                label={t('statistics.period_from')}
                value={dayjs(timeFrom).format()}
                onChange={(date) => {
                  handleDateFromChange(date);
                }}
              />
              <DatePicker
                id={'to'}
                label={t('statistics.period_to')}
                value={dayjs(timeTo).format()}
                maxDate={Date.now()}
                onChange={(date) => {
                  handleDateToChange(date);
                }}
              />
            </DateWrapper>
            <CountryDropdown
              id="country-dropdown"
              value={supportedCountries}
              onChange={(value) => {
                setSupportedCountries(value as SupportedCountriesDto[]);
              }}
              isMulti={true}
            />
            <Dropdown
              id="cities"
              placeholder={t('statistics.cities')}
              isMulti
              withSearch
              value={currentCities}
              onChange={(data) => {
                handleCityChange(data as string[]);
              }}
              options={citiesOptions!}
            />
          </div>
          <div>
            <AgeWrapper>
              <Input
                id={'ageFrom'}
                type="number"
                value={ageFrom}
                onChange={(e) => handleAgeChange(e.target.value, 'from')}
                placeholder={t('statistics.age_from')}
                isOnlyError={isAgeError}
              />
              <Input
                id={'ageTo'}
                type="number"
                value={ageTo}
                onChange={(e) => handleAgeChange(e.target.value, 'to')}
                placeholder={t('statistics.age_to')}
                isOnlyError={isAgeError}
              />
            </AgeWrapper>
            <Dropdown
              id="gender"
              placeholder={t('statistics.gender')}
              isMulti
              value={gender}
              onChange={(data) => {
                handleGenderChange(data as string[]);
              }}
              options={genderList!}
            />
            <Dropdown
              id={'specializations'}
              value={specializationIds}
              placeholder={t('statistics.specializations')}
              onChange={(value) => {
                getCurrenData({
                  page: 1,
                  specializationIds: value as string[],
                });
                setSpecializationIds(value as string[]);
              }}
              options={specializationsOptions}
              withSearch={true}
              isMulti={true}
            />
            <Dropdown
              id={'clinics'}
              value={clinicIds}
              placeholder={t('statistics.clinics')}
              onChange={(value) => {
                getCurrenData({
                  page: 1,
                  clinics: value as string[],
                });
                setClinicIds(value as string[]);
              }}
              options={clinicOptions}
              withSearch={true}
              isMulti={true}
            />
          </div>
        </LeftFilters>
        <MetricsFilter>
          <Dropdown
            id="metrics"
            placeholder={t('statistics.metrics')}
            isMulti
            withSearch={false}
            value={currentMetrics}
            onChange={(data) => {
              dispatch(setMetricsData(data));
            }}
            options={metricsList!}
          />
        </MetricsFilter>
      </FilterSection>
      <PerDoctorsMetricsTable />
      {perDoctorsMetricsData?.perProfessional?.entities?.length ? (
        <Pagination
          pages={perDoctorsMetricsData?.perProfessional?.totalPages || 1}
          setCurrentPage={handlePagination}
          currentPage={currentPage}
          itemCount={
            perDoctorsMetricsData?.perProfessional?.entities?.length || 0
          }
        />
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default PerDoctors;
