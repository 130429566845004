import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ClinicsManagementState } from './models';
import {
  getClinicById,
  getClinics,
  getClinicsSuggestions,
  updateClinic,
} from './ActionCreators';
import { ClinicSuggestionResponseDto } from '@docbay/schemas';

const initialState: ClinicsManagementState = {
  clinics: [],
  clinicsSuggestions: [],
  currentClinic: null,
  total: 0,
  page: 0,
  totalPages: 0,
  isLoading: false,
  error: '',
};

const clinicsManagementSlice = createSlice({
  name: 'clinicsManagementSlice',
  initialState,
  reducers: {
    setClinicsSuggestions: (
      state,
      action: PayloadAction<ClinicSuggestionResponseDto[]>,
    ) => {
      state.clinicsSuggestions = action.payload;
    },
  },
  extraReducers: (builder) => {
    //insurances fetch
    builder.addCase(getClinics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClinics.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getClinics.fulfilled, (state, action) => {
      state.clinics = action.payload.entities;
      state.total = action.payload.total || 0;
      state.page = action.payload.page || 0;
      state.totalPages = action.payload.totalPages || 0;
      state.isLoading = false;
    });

    //get clinics suggestions
    builder.addCase(getClinicsSuggestions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClinicsSuggestions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getClinicsSuggestions.fulfilled, (state, action) => {
      state.clinicsSuggestions = action.payload;
      state.isLoading = false;
    });

    //get clinic by id
    builder.addCase(getClinicById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClinicById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getClinicById.fulfilled, (state, action) => {
      state.currentClinic = action.payload;
      state.isLoading = false;
    });

    // update clinic
    builder.addCase(updateClinic.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateClinic.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateClinic.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setClinicsSuggestions } = clinicsManagementSlice.actions;

export default clinicsManagementSlice.reducer;
