import React, { FC } from 'react';
import { Wrapper, ErrorInfo } from './styles';
import { ReactComponent as ErrorIcon } from 'application/assets/error-icon.svg';
import { PrimaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PathNames } from 'application/routes';

const Error: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <ErrorInfo>
        <ErrorIcon />
        <h2>404</h2>
        <h4>{t('global_error.not_found')}</h4>
        <p>{t('global_error.page_not_exist')}</p>
        <PrimaryButton
          onClick={() => {
            navigate(PathNames.home);
          }}
        >
          {t('global_error.to_home')}
        </PrimaryButton>
      </ErrorInfo>
    </Wrapper>
  );
};

export default Error;
