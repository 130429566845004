import styled from 'styled-components';

export const Wrapper = styled.section`
  display: grid;
  grid-template-rows: max-content;
  gap: 32px;
  padding: 32px;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  border-top: 0;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
`;

export const ClinicNameStyled = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 33.6px;
    margin-left: 16px;
  }
`;

export const IconStyled = styled.div`
  display: flex;
  min-width: 48px;
  min-height: 48px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.blue800};
  border-radius: 50%;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.grey200};
`;

export const FormStyled = styled.form`
  display: grid;
  grid-template-rows: max-content;
  gap: 32px;
`;

export const LabelStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme }) => theme.color.grey600};
    }
  }

  p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin: 0 6px 0 8px;
  }

  h2 {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    min-width: 150px;
    width: fit-content;
  }
`;
