import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionStyled } from './styles';
import { DeleteActionProps } from './modules';

const DeleteProfileAction: FC<DeleteActionProps> = ({ handleDelete }) => {
  const { t } = useTranslation();

  return (
    <ActionStyled>
      <p onClick={handleDelete} className={'reset'}>
        {t('databases_feature.delete')}
      </p>
    </ActionStyled>
  );
};
export default DeleteProfileAction;
