import styled from 'styled-components';

export const TableStyled = styled.table`
  width: 100%;

  border-radius: 12px;
  border-collapse: separate;

  > thead {
    tr {
      th {
        vertical-align: middle;
        text-align: start;
        padding: 12px 24px;
        color: ${({ theme }) => theme.color.grey600};
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.07px;
        background: ${({ theme }) => theme.color.grey50};
        border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
        & > {
          width: 200px;
        }

        &:last-child {
          width: 150px;
        }
      }
    }
  }
  > tbody {
    > tr {
      &:last-child {
        > td {
          &:first-child {
            border-bottom-left-radius: 12px;
          }
          &:last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
`;

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    width: 200px;
    max-width: 220px;

    &:first-child {
      width: auto;
    }
    &:last-child {
      width: 150px;
    }
  }
`;

export const ActionButtonsGroupStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 120px;

  > button:first-child svg path {
    fill: ${({ theme }) => theme.color.blue800};
  }
`;

export const ActionButtonStyled = styled.button`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.grey600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.08px;

  svg {
    margin-left: 4px;
    path {
      fill: ${({ theme }) => theme.color.grey700};
    }
  }
`;
