import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyStateIcon } from 'application/assets/empty-state.svg';
import { StyledTr } from './styles';
import AddMetricsModal from '../AddMetrics';

const NoDataElement: FC = () => {
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  return (
    <StyledTr>
      <td colSpan={4}>
        <div>
          <EmptyStateIcon />
          <div>
            <p>{t('specialization_metrics.no_data')}</p>
            <p>
              {t('specialization_metrics.no_data_please')}
              <span onClick={() => setShowAddModal(true)}>
                {t('specialization_metrics.no_data_add')}
              </span>
            </p>
          </div>
        </div>
      </td>
      {showAddModal && (
        <AddMetricsModal
          opened={showAddModal}
          onClose={() => setShowAddModal(false)}
          title={t('specialization_metrics.add_metric')}
        />
      )}
    </StyledTr>
  );
};
export default NoDataElement;
