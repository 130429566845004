import { Box, styled } from '@mui/system';
import { Typography } from '@mui/material';
import { styledTheme } from 'application/theme';

export const LoginPageContentWrapper = styled(Box)`
  padding: 0;
  width: 100%;
  min-width: 480px;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const LoginPageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

export const LoginContentWrapper = styled(Box)`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
`;

export const FooterWrapper = styled('div')`
  padding: 20px 32px 0;
  width: 100%;
  text-align: center;
`;

export const FooterContentStyled = styled(Typography)`
  color: ${styledTheme.color.grey800};
  font-family: 'Mulish', sans-serif;
  padding-bottom: 12px;
`;
