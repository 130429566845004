import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'application/assets/delete.svg';
import { ReactComponent as EditIcon } from 'application/assets/edit-square.svg';
import { Pagination } from 'common/components';
import { TableProps } from './models';
import {
  StyledTr,
  TableStyled,
  ActionButtonStyled,
  ActionButtonsGroupStyled,
} from './styles';

const Table: FC<TableProps> = ({
  columns,
  data,
  currentPage,
  totalPages,
  setCurrentPage,
  handleDelete,
  handleEditModalOpen,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TableStyled>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={column + index}>
                <span>{column}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <StyledTr key={item.id}>
              <td>{item.name}</td>
              <td>
                <ActionButtonsGroupStyled>
                  <ActionButtonStyled
                    type="button"
                    onClick={() =>
                      handleEditModalOpen(item.id, true, {
                        name: item.name,
                        code: item.code,
                      })
                    }
                  >
                    <EditIcon />
                  </ActionButtonStyled>
                  <ActionButtonStyled
                    type="button"
                    onClick={() => handleDelete(item.id)}
                  >
                    {t('databases_feature.delete')}
                    <DeleteIcon />
                  </ActionButtonStyled>
                </ActionButtonsGroupStyled>
              </td>
            </StyledTr>
          ))}
        </tbody>
      </TableStyled>
      {!!data?.length && (
        <Pagination
          pages={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          itemCount={data.length || 0}
        />
      )}
    </>
  );
};

export default Table;
