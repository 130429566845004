import styled from 'styled-components';
import { Box } from '@mui/system';
import { styledTheme } from 'application/theme';
import { StyledTypography } from '../../../feature-login/styles';

export const MainText = styled.div`
  font-family: AcidGrotesk-Bold;
  color: ${({ theme }) => theme.color.grey900};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 12px;
`;

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  min-width: 500px;
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.success4};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.color.grey500};
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      color: ${({ theme }) => theme.color.grey900};
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      margin: 12px 0 32px 0;
      > span {
        font-weight: 600;
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;

  a {
    text-decoration: none;
    margin-left: 16px;
    width: inherit;
  }

  button {
    padding: 12px 24px;
    white-space: nowrap;

    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const FormGroupStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 12px;
`;

export const StyledForm = styled('form')`
  padding: 0 22px;
  width: 100%;
`;

export const StyledError = styled(StyledTypography)`
  color: ${styledTheme.color.error2};
  line-height: 19.6px;
  text-align: center;
  margin: 0 0 32px 0;
`;
