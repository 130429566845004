import React, { FC, useEffect, useState } from 'react';
import { Loader, SuccessModal } from 'common/components';
import {
  TableStyled,
  SearchStyled,
} from 'features/feature-users-managment/components/Main/styles';
import { Input } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  deleteAdmin,
  deleteClinicOwner,
  deleteDoctor,
  deleteSecretary,
  fetchProfessionals,
} from 'application/store/reducers/Professionals/ActionCreators';
import Pagination from 'common/components/Pagination';
import { useDebounce } from 'common/hooks/useDebounce';
import ProfessionalItem from 'features/feature-users-managment/components/ProfessionalItem';
import { Wrapper } from 'features/feature-users-managment/components/PatientsList/styles';
import NoDataElement from 'features/feature-users-managment/components/NoDataElement';
import Dropdown from 'common/components/Dropdown';
import {
  ProfessionalsTypesList,
  UserTypes,
} from 'features/feature-users-managment/constants';
import { Option } from 'features/feature-users-managment/models';
import DeleteModal from 'features/feature-database/components/DeleteModal';
import {
  resetUserDeleted,
  setUserForDelete,
} from 'application/store/reducers/Professionals/ProfessionalsSlice';

const defaultLimit = 10;

const ProfessionalsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const debouncedInput = useDebounce(inputValue, 500);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentUserType, setCurrentUserType] = useState<string[]>([]);
  const { isLoading, professionals, totalPages, userDeleted, userForDelete } =
    useAppSelector((state) => state.professionals);
  const { isLoading: isResetPassword } = useAppSelector(
    (state) => state.userResetPassword,
  );

  const getCurrentData = () => {
    const userTypes = currentUserType.filter((item) => !!item);
    dispatch(
      fetchProfessionals({
        ...(debouncedInput.length > 0 ? { searchItem: debouncedInput } : {}),
        ...(userTypes.length ? { userTypes: userTypes } : {}),
        limit: defaultLimit,
        page: currentPage,
      }),
    );
  };

  const handleSearch = (value: string) => {
    setInputValue(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    getCurrentData();
  }, [currentPage, debouncedInput, currentUserType]);

  useEffect(() => {
    userDeleted && getCurrentData();
  }, [userDeleted]);

  const clearSearch = () => {
    setInputValue('');
  };

  const handleTypeChange = (data: Option) => {
    setCurrentUserType([data.value]);
    setCurrentPage(1);
  };

  const handleDeleteProfile = () => {
    if (userForDelete?.entity?.includes(UserTypes.Professional)) {
      dispatch(deleteDoctor(userForDelete?.id!));
    } else if (userForDelete?.entity?.includes(UserTypes.Admin)) {
      dispatch(deleteAdmin(userForDelete?.id!));
    } else if (userForDelete?.entity?.includes(UserTypes.Secretary)) {
      dispatch(deleteSecretary(userForDelete?.id!));
    } else {
      dispatch(deleteClinicOwner(userForDelete?.id!));
    }
  };

  const handleCloseDeleteModal = () => {
    dispatch(setUserForDelete(null));
  };

  const handleCloseModal = () => {
    dispatch(resetUserDeleted());
  };

  return (
    <Wrapper>
      {(isLoading || isResetPassword) && <Loader />}

      <SearchStyled>
        <Input
          id={'patientName'}
          type="search"
          value={inputValue}
          onClear={clearSearch}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder={t('users_managment.search_by_name_placeholder')}
        />
        <div className={'dropdown'}>
          <Dropdown
            id={'type'}
            placeholder={'Type'}
            value={currentUserType}
            onChange={(data) => {
              handleTypeChange(data as Option);
            }}
            options={ProfessionalsTypesList}
          />
        </div>
      </SearchStyled>
      <TableStyled>
        <table>
          <thead>
            <tr>
              <th>
                <span>
                  {t('users_managment.lists.professional_name_title')}
                </span>
              </th>
              <th>{t('global.type')}</th>
              <th className="actionsColumn">
                <span>{t('users_managment.lists.actions_title')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {professionals.length ? (
              professionals.map((professional) => (
                <ProfessionalItem
                  key={`professional_${professional.id}`}
                  professional={professional}
                />
              ))
            ) : (
              <NoDataElement />
            )}
          </tbody>
        </table>
      </TableStyled>
      {professionals.length > 0 ? (
        <Pagination
          pages={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          itemCount={professionals.length || 0}
        />
      ) : (
        ''
      )}
      <DeleteModal
        isOpen={!!userForDelete}
        text={`${t('delete_modal.titlePart1_profile')}`}
        onClose={handleCloseDeleteModal}
        onSubmit={handleDeleteProfile}
      />
      <SuccessModal
        title={t('modal_success.delete_profile')}
        opened={userDeleted}
        onClose={handleCloseModal}
      />
    </Wrapper>
  );
};

export default ProfessionalsList;
