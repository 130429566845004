import styled from 'styled-components';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  margin-top: 20px;
  background: #fff;
`;

export const FilterSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
`;

export const LeftFilters = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 16px;
  gap: 16px;

  > div:first-of-type {
    width: 200px;
  }
  > div:nth-of-type(2) {
    width: 200px;
  }

  .country-list {
    width: fit-content;
  }
`;

export const MetricsFilter = styled('div')`
  width: 312px;
`;

export const DateWrapper = styled('div')`
  display: flex;
  align-items: center;

  > div {
    width: 170px;

    &:last-child {
      margin-left: 16px;
    }
  }
`;
