import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabName } from 'features/feature-users-managment/constants';
import {
  ButtonStyled,
  TabsSectionStyled,
  Wrapper,
} from 'features/feature-users-managment/components/Main/styles';
import PatientsList from 'features/feature-users-managment/components/PatientsList';
import ProfessionalsList from 'features/feature-users-managment/components/ProfessionalsList';
import InternalUsers from 'features/feature-users-managment/components/InternalUsers';
import ImportedDoctors from '../ImportedDoctors';

const Main: FC = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(TabName.Patients);
  const handleChangeTab = (tabName: TabName) => {
    setCurrentTab(tabName);
  };

  return (
    <Wrapper>
      <TabsSectionStyled>
        <h1>{t('users_managment.title')}</h1>
        <div>
          <ButtonStyled
            isActive={currentTab === TabName.Patients}
            type="button"
            onClick={() => handleChangeTab(TabName.Patients)}
          >
            <p>{t('users_managment.tabs.patients')}</p>
          </ButtonStyled>
          <ButtonStyled
            isActive={currentTab === TabName.Professionals}
            type="button"
            onClick={() => handleChangeTab(TabName.Professionals)}
          >
            <p>{t('users_managment.tabs.professionals')}</p>
          </ButtonStyled>
          <ButtonStyled
            isActive={currentTab === TabName.InternalUsers}
            type="button"
            onClick={() => handleChangeTab(TabName.InternalUsers)}
          >
            <p>{t('users_managment.tabs.internal_users')}</p>
          </ButtonStyled>
          <ButtonStyled
            isActive={currentTab === TabName.ImportedDoctors}
            type="button"
            onClick={() => handleChangeTab(TabName.ImportedDoctors)}
          >
            <p>{t('users_managment.tabs.imported_doctors')}</p>
          </ButtonStyled>
        </div>
      </TabsSectionStyled>
      {currentTab === TabName.Patients && <PatientsList />}
      {currentTab === TabName.Professionals && <ProfessionalsList />}
      {currentTab === TabName.InternalUsers && <InternalUsers />}
      {currentTab === TabName.ImportedDoctors && <ImportedDoctors />}
    </Wrapper>
  );
};

export default Main;
