import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const SearchStyled = styled.div`
  display: flex;
  height: 84px;
  padding: 20px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;

  > div {
    width: 320px;
  }

  .dropdown {
    margin-left: 20px;
    width: 170px;

    h2 {
      margin-right: 0;
    }
  }
`;

export const SuggestionsList = styled.div`
  display: grid;
  gap: 4px;
  grid-template-rows: max-content;
  max-height: 300px;
  overflow-y: auto;
  padding: 12px;
  position: absolute;
  z-index: 10;
  top: 64px;
  left: 20px;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
`;

export const SuggestionItem = styled(Link)`
  text-decoration: none;
  padding: 4px 6px;
  border-radius: 4px;
  background: transparent;
  color: ${({ theme }) => theme.color.grey800};
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }
`;
