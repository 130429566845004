import {
  CommonEntitiesListResponse,
  Insurance,
  InsuranceCreationDto,
  InsuranceResponseDto,
  InsuranceUpdateDto,
} from '@docbay/schemas';
import { fetchInsurancesProps } from 'common/types/insurances';
import axiosInstance from 'integration/api/axiosInstance';

export const InsurancesAPI = {
  fetchInsurances: async ({
    searchItem,
    limit,
    page,
  }: fetchInsurancesProps) => {
    const params = {
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(searchItem ? { search: searchItem } : {}),
    };

    return await axiosInstance.get<CommonEntitiesListResponse<Insurance>>(
      '/insurances',
      { params },
    );
  },
  deleteInsurances: (id: string) => axiosInstance.delete(`/insurances/${id}`),
  addInsurances: (params: InsuranceCreationDto) =>
    axiosInstance.post<InsuranceResponseDto>('/insurances', params),
  editInsurances: ({ data, id }: { data: InsuranceUpdateDto; id: string }) =>
    axiosInstance.patch<InsuranceResponseDto>(`/insurances/${id}`, data),
};
