export enum TabName {
  PerCities = 'PerCities',
  PerClinics = 'PerClinics',
  PerDoctors = 'PerDoctors',
  PlatformStatistics = 'PlatformStatistics',
  SpecializationPerCity = 'SpecializationPerCity',
}

export const metricsList = [
  {
    label: 'Appointments online \n(% of total appointments)',
    value: 'onlineAppointmentsPercentage',
  },
  {
    label: 'Appointments offline \n(% of total appointments)',
    value: 'offlineAppointmentsPercentage',
  },
  { label: 'Total\n appointments', value: 'totalAppointments' },
  { label: 'Appointments \nonline', value: 'onlineAppointmentsCount' },
  { label: 'Total \npatients', value: 'appointmentsPatientsCount' },
  { label: 'Total \nprofessionals', value: 'appointmentsProfessionalsCount' },
  {
    label: 'New patients %\n(of total patients)',
    value: 'newPatientsPercentage',
  },
  { label: 'Patients \nretention rate', value: 'patientsRetentionRate' },
  {
    label: 'Cancellations \nfrom patient',
    value: 'cancellationsFromPatientCount',
  },
  {
    label: 'Cancellations \nfrom clinic',
    value: 'cancellationsFromClinicCount',
  },
  { label: 'Reschedules \nfrom clinic', value: 'reschedulesFromClinic' },
  {
    label: 'Views per \ndoctor profile',
    value: 'professionalProfileViewsCount',
  },
  { label: 'Views per \nclinic profile', value: 'clinicProfileViewsCount' },
];

export const platformMetricsList = [
  {
    label: 'Patient platform views',
    value: 'patientPlatformViews',
  },
  {
    label: 'Searches from Patient Web',
    value: 'searchesFromPatientWeb',
  },
  { label: 'Searches from Patient Mobile', value: 'searchesFromPatientMobile' },
  { label: 'Searches with empty result', value: 'searchesWithEmptyResult' },
];

export const metricsArray = [
  'onlineAppointmentsPercentage',
  'offlineAppointmentsPercentage',
  'totalAppointments',
  'onlineAppointmentsCount',
  'appointmentsPatientsCount',
  'appointmentsProfessionalsCount',
  'newPatientsPercentage',
  'patientsRetentionRate',
  'cancellationsFromPatientCount',
  'cancellationsFromClinicCount',
  'reschedulesFromClinic',
  'professionalProfileViewsCount',
  'clinicProfileViewsCount',
];

export const genderList = [
  { label: 'statistics.male', value: 'Male' },
  { label: 'statistics.female', value: 'Female' },
];
