import React, { FC } from 'react';
import {
  Header,
  InsuranceTable,
  SpecializationTable,
  Geographies,
} from './components';
import { Wrapper, TableWrapper } from './styles';

const Database: FC = () => {
  return (
    <Wrapper>
      <Header />
      <TableWrapper>
        <SpecializationTable />
        <InsuranceTable />
      </TableWrapper>
      <TableWrapper>
        <Geographies />
      </TableWrapper>
    </Wrapper>
  );
};

export default Database;
