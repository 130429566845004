export enum UsersActions {
  usersLogin = 'usersLogin',
  usersRefreshToken = 'usersRefreshToken',
  signOut = 'signOut',
  changePasswordSaas = 'changePasswordSaas',
  createPassword = 'createPassword',
}

export enum PatientsActions {
  fetchPatients = 'fetchPatients',
}

export enum ProfessionalsActions {
  fetchProfessionals = 'fetchProfessionals',
  loginAsUser = 'loginAsUser',
  deleteClinicOwner = 'deleteClinicOwner',
  deleteDoctor = 'deleteDoctor',
  deleteAdmin = 'deleteAdmin',
  deleteSecretary = 'deleteSecretary',
}

export enum InternalUsersActions {
  fetchInternalUsers = 'fetchInternalUsers',
  createInternalUser = 'createInternalUser',
  editInternalUser = 'editInternalUser',
}

export enum StatisticsActions {
  fetchCities = 'fetchCities',
  fetchClinics = 'fetchClinics',
  fetchPerCityMetrics = 'fetchPerCityMetrics',
  fetchPerClinicsMetrics = 'fetchPerClinicsMetrics',
  fetchPerDoctorsMetrics = 'fetchPerDoctorsMetrics',
  fetchSpecializations = 'fetchSpecializations',
  fetchPlatformStatistics = 'fetchPlatformStatistics',
}

export enum InsurancesActions {
  fetchInsurances = 'fetchInsurances',
  deleteInsurances = 'deleteInsurances',
  addInsurances = 'addInsurances',
  editInsurances = 'editInsurances',
}

export enum PaginatedSpecializationsActions {
  fetchPaginatedSpecializations = 'fetchPaginatedSpecializations',
  addSpecialization = 'addSpecialization',
  deactivateSpecialization = 'deactivateSpecialization',
  activateSpecialization = 'activateSpecialization',
  editSpecializations = 'editSpecializations',
}

export enum UserResetPasswordActions {
  resetPassword = 'resetPassword',
}
export enum ClinicsManagementActions {
  getClinics = 'getClinics',
  getClinicById = 'getClinicById',
  getClinicsSuggestions = 'getClinicsSuggestions',
  updateClinic = 'updateClinic',
  deleteClinic = 'deleteClinic',
}

export enum ImportProfessionalsActions {
  fetchImportedProfessionals = 'fetchImportedProfessionals',
  addProfessionals = 'addProfessionals',
  updateProfessionals = 'updateProfessionals',
  deleteProfessionals = 'deleteProfessionals',
  uploadProfessionals = 'uploadProfessionals',
  uploadStatus = 'uploadStatus',
  getExternalProfessionalById = 'getExternalProfessionalById',
}

export enum CountriesActions {
  getSupportedCountries = 'getSupportedCountries',
  getAllCountries = 'getAllCountries',
  addToSupportedCountries = 'addToSupportedCountries',
  deleteSupportedCountry = 'deleteSupportedCountry',
}

export enum LanguagesActions {
  getSupportedLanguages = 'getSupportedLanguages',
}

export enum MetricsActions {
  fetchMetrics = 'fetchMetrics',
  fetchSpecializations = 'fetchSpecializations',
  fetchUnitsOfMeasurement = 'fetchUnitsOfMeasurement',
  createUnitOfMeasurement = 'createUnitOfMeasurement',
  deleteUnitOfMeasurement = 'deleteUnitOfMeasurement',
  createMetric = 'createMetric',
  deleteMetric = 'deleteMetric',
  editMetric = 'editMetric',
}
