import React, { FC } from 'react';
import SideMenu from 'features/feature-aside-menu';
import { Wrapper } from './styles';
import UsersManagment from 'features/feature-users-managment';

const UsersManagmentPage: FC = () => (
  <Wrapper>
    <SideMenu />
    <UsersManagment />
  </Wrapper>
);

export default UsersManagmentPage;
