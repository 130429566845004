import React, { FC, useEffect, useMemo, useState } from 'react';
import { FilterSection, Wrapper } from './styles';
import Dropdown from 'common/components/Dropdown';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  Loader,
  Pagination,
  PrimaryButton,
  SuccessModal,
} from 'common/components';
import { ReactComponent as AddIcon } from 'application/assets/add.svg';

import SpecializationMetricsTable from '../SpecializationMetricsTable';
import { useTranslation } from 'react-i18next';
import { getLanguageFromLocalStorage } from 'application/sessionStorage/languages';
import { Specialization } from '@docbay/schemas';
import {
  fetchMetrics,
  fetchSpecializations,
} from 'application/store/reducers/SpecializationMetrics/ActionCreators';
import AddMetricsModal from '../AddMetrics';
import {
  setMetricAdded,
  setMetricDeleted,
  setMetricEditedSuccessfully,
} from 'application/store/reducers/SpecializationMetrics/SpecializationMetricsSlice';
import { getSupportedLanguages } from 'application/store/reducers/Languages/ActionCreators';

const defaultLimit = 10;

const MetricsTable: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [specializationIds, setSpecializationIds] = useState<string[]>([]);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const {
    isLoading,
    specializations,
    metrics,
    metricAdded,
    metricDeleted,
    metricEditedSuccessfully,
    totalPages,
  } = useAppSelector((state) => state.specializationMetrics);

  const getCurrentData = async (data?: {
    page: number;
    specializationIds?: string;
  }) => {
    dispatch(
      fetchMetrics({
        page: Number(data?.page),
        limit: defaultLimit!,
        ...(data?.specializationIds?.length
          ? { specializationIds: data.specializationIds }
          : {}),
      }),
    );
  };

  useEffect(() => {
    const getData = async () => {
      await dispatch(fetchSpecializations());
      await getCurrentData({ page: 1 });
      await dispatch(getSupportedLanguages());
    };

    getData();
  }, []);

  const specializationsOptions = useMemo(() => {
    const currentLanguage = getLanguageFromLocalStorage();
    const specializationLangKey = `name_${currentLanguage.toUpperCase()}` as
      | 'name_EN'
      | 'name_PT';

    const options = specializations?.map((item) => {
      const specializationName =
        item[specializationLangKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });
    return options;
  }, [specializations, i18n.language]);

  const handlePagination = (page: number) => {
    getCurrentData({ page });
    setCurrentPage(page);
  };

  const handleCloseSuccessModal = () => {
    dispatch(setMetricAdded(false));
    getCurrentData({ page: 1 });
  };

  const handleMetricDeleted = () => {
    dispatch(setMetricDeleted(false));
    getCurrentData({ page: 1 });
  };

  const handleMetricEdited = () => {
    dispatch(setMetricEditedSuccessfully(false));
    getCurrentData({ page: 1 });
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <FilterSection>
        <Dropdown
          id={'specializations'}
          value={specializationIds}
          placeholder={t('statistics.specializations')}
          onChange={(value) => {
            getCurrentData({
              page: 1,
              specializationIds: (value as string[]).join(','),
            });
            setCurrentPage(1);
            setSpecializationIds(value as string[]);
          }}
          options={specializationsOptions}
          withSearch={true}
          isMulti={true}
        />
        <PrimaryButton onClick={() => setShowAddModal(true)}>
          <AddIcon />
          {t('specialization_metrics.add_metric')}
        </PrimaryButton>
      </FilterSection>
      <SpecializationMetricsTable />
      {metrics?.length ? (
        <Pagination
          pages={totalPages}
          setCurrentPage={handlePagination}
          currentPage={currentPage}
          itemCount={metrics?.length || 0}
        />
      ) : (
        ''
      )}
      {showAddModal && (
        <AddMetricsModal
          opened={showAddModal}
          onClose={() => setShowAddModal(false)}
          title={t('specialization_metrics.add_metric')}
        />
      )}
      <SuccessModal
        title={t('specialization_metrics.success_add')}
        opened={metricAdded}
        onClose={handleCloseSuccessModal}
      />
      <SuccessModal
        title={t('specialization_metrics.success_delete')}
        opened={metricDeleted}
        onClose={handleMetricDeleted}
      />
      <SuccessModal
        title={t('specialization_metrics.success_edited')}
        opened={metricEditedSuccessfully}
        onClose={handleMetricEdited}
      />
    </Wrapper>
  );
};

export default MetricsTable;
