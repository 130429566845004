import React, { FC } from 'react';

import { PathNames } from 'application/routes';
import { ReactComponent as EditIcon } from 'application/assets/edit-square.svg';
import { ReactComponent as HouseIcon } from 'application/assets/home.svg';

import { ClinicItemProps } from './models';
import { StyledTr, ClinicNameStyled, IconStyled, ActionButton } from './styles';

const ClinicItem: FC<ClinicItemProps> = ({ clinic }) => {
  return (
    <StyledTr>
      <td>
        <ClinicNameStyled>
          {clinic.mainPhoto ? (
            <img
              src={clinic.mainPhoto.thumbnailUrl}
              alt={clinic.mainPhoto.id}
            />
          ) : (
            <IconStyled>
              <HouseIcon />
            </IconStyled>
          )}
          <p>{clinic.name}</p>
        </ClinicNameStyled>
      </td>
      <td>
        <p>{clinic.owner?.user?.email}</p>
      </td>
      <td>
        <ActionButton to={`${PathNames.clinics}/${clinic.id}`}>
          <EditIcon />
        </ActionButton>
      </td>
    </StyledTr>
  );
};
export default ClinicItem;
