import { createSlice } from '@reduxjs/toolkit';
import {
  deleteClinicOwner,
  fetchProfessionals,
  loginAsUser,
  deleteDoctor,
  deleteAdmin,
  deleteSecretary,
} from './ActionCreators';
import { ProfessionalsState } from './models';

const initialState: ProfessionalsState = {
  professionals: [],
  total: 0,
  page: 0,
  totalPages: 0,
  isLoading: false,
  loginAsUserError: '',
  error: '',
  userForDelete: null,
  userDeleted: false,
};

const professionalsSlice = createSlice({
  name: 'professionals',
  initialState,
  reducers: {
    resetProfessionals: (state) => {
      state.professionals = [];
    },
    setLoginAsUserError: (state, action) => {
      state.loginAsUserError = action.payload;
    },
    setUserForDelete: (state, action) => {
      state.userForDelete = action.payload;
    },
    resetUserDeleted: (state) => {
      state.userDeleted = false;
    },
  },
  extraReducers: (builder) => {
    //professionals fetch
    builder.addCase(fetchProfessionals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProfessionals.rejected, (state, action) => {
      state.isLoading = false;
      state.professionals = [];
      state.error = action.payload as string;
    });
    builder.addCase(fetchProfessionals.fulfilled, (state, action) => {
      state.professionals = action.payload.entities;
      state.total = action.payload.total!;
      state.page = action.payload.page!;
      state.totalPages = action.payload.totalPages!;
      state.isLoading = false;
    });
    // login as a user
    builder.addCase(loginAsUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginAsUser.rejected, (state, action) => {
      state.isLoading = false;
      state.loginAsUserError = action.payload as string;
    });
    builder.addCase(loginAsUser.fulfilled, (state) => {
      state.isLoading = false;
    });
    // delete clinicOwner
    builder.addCase(deleteClinicOwner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteClinicOwner.rejected, (state, action) => {
      state.isLoading = false;
      state.loginAsUserError = action.payload as string;
    });
    builder.addCase(deleteClinicOwner.fulfilled, (state) => {
      state.userForDelete = null;
      state.userDeleted = true;
    });
    // delete doctor
    builder.addCase(deleteDoctor.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteDoctor.rejected, (state, action) => {
      state.isLoading = false;
      state.loginAsUserError = action.payload as string;
    });
    builder.addCase(deleteDoctor.fulfilled, (state) => {
      state.userForDelete = null;
      state.userDeleted = true;
    });
    // delete Admin
    builder.addCase(deleteAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAdmin.rejected, (state, action) => {
      state.isLoading = false;
      state.loginAsUserError = action.payload as string;
    });
    builder.addCase(deleteAdmin.fulfilled, (state) => {
      state.userForDelete = null;
      state.userDeleted = true;
    });
    // delete Secretary
    builder.addCase(deleteSecretary.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSecretary.rejected, (state, action) => {
      state.isLoading = false;
      state.loginAsUserError = action.payload as string;
    });
    builder.addCase(deleteSecretary.fulfilled, (state) => {
      state.userForDelete = null;
      state.userDeleted = true;
    });
  },
});

export const {
  resetProfessionals,
  setLoginAsUserError,
  setUserForDelete,
  resetUserDeleted,
} = professionalsSlice.actions;

export default professionalsSlice.reducer;
