import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const TableStyled = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  overflow-x: auto;

  ::-webkit-scrollbar {
    border-radius: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 6px;
    background: ${({ theme }) => theme.color.grey50};
  }

  > table {
    width: 100%;
    overflow: scroll;
    thead {
      th {
        border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
        background: ${({ theme }) => theme.color.grey50};
        padding: 16px 24px;
        text-align: left;
        color: ${({ theme }) => theme.color.grey600};
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.07px;
        border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

        &:first-child {
          position: sticky;
          left: 0;
        }

        span {
          display: block;
          white-space: nowrap;
        }
      }
    }

    td {
      padding: 16px 24px;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: ${({ theme }) => theme.color.grey700};
      border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

      &.firstColItem {
        position: sticky;
        left: 0;
        background: ${({ theme }) => theme.color.white};
        font-weight: 700;
        color: ${({ theme }) => theme.color.grey900};
      }
    }
  }
`;
