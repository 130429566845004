import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserType } from '@docbay/schemas';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CreatePassword, Input, PrimaryButton } from 'common/components';
import { useAppDispatch } from 'common/hooks/redux';
import {
  createPassword,
  usersLogin,
} from 'application/store/reducers/Users/ActionCreators';
import { clearStore } from 'application/store/reducers/Users/UsersSlice';
import { PathNames } from 'application/routes';
import { useCreatePasswordSchema } from 'features/feature-create-password/hooks/useCreatePasswordSchema';
import { CreatePasswordForm } from './models';
import { FormStyled } from './styles';

const Form: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const createPasswordSchema = useCreatePasswordSchema();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    register,
    watch,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<CreatePasswordForm>({
    resolver: yupResolver(createPasswordSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data: CreatePasswordForm) => {
    const { password, confirmPassword } = data;
    const email = searchParams.get('email')?.replace(' ', '+') || '';
    const code = searchParams.get('code') || '';
    const userType = (searchParams.get('user-type') || '') as UserType;
    const isPasswordValid = password === confirmPassword;

    if (!isPasswordValid) {
      return setError('confirmPassword', {
        message: t('password don`t match'),
      });
    }
    if (!userType.length) return;
    const response = await dispatch(
      createPassword({
        password,
        email,
        code,
        userType: (userType.charAt(0).toUpperCase() +
          userType.slice(1)) as UserType,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      clearStore();
      const loginResponse = await dispatch(
        usersLogin({
          email: email,
          password: password,
        }),
      );
      if (loginResponse.meta.requestStatus === 'fulfilled') {
        navigate(PathNames.home);
      }
    }
  };

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <CreatePassword
        label={t('global.create_password')}
        watch={watch}
        getValues={getValues}
        register={register}
        errors={errors.password?.message}
      />
      <Input
        id="confirmPassword"
        label={t('auth.change_password.old_password') || ''}
        type="password"
        errorMessage={errors.confirmPassword?.message}
        register={register}
      />
      <PrimaryButton type="submit">{t('global.continue')}</PrimaryButton>
    </FormStyled>
  );
};

export default Form;
