import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatisticsActions } from 'application/constants/actions';
import { AxiosError } from 'axios';
import { StatisticsApi } from 'integration/api/statistics';
import {
  FiltersCitiesQueryParamsDto,
  FiltersClinicsQueryParamsDto,
  PlatformStatisticsQueryParamsDto,
  StatsPerCityQueryParamsDto,
  StatsPerClinicsQueryParamsDto,
  StatsPerProfessionalsQueryParamsDto,
} from '@docbay/schemas';

export const fetchCities = createAsyncThunk(
  StatisticsActions.fetchCities,
  async (params: FiltersCitiesQueryParamsDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await StatisticsApi.fetchCities(params);

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchClinics = createAsyncThunk(
  StatisticsActions.fetchClinics,
  async (params: FiltersClinicsQueryParamsDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await StatisticsApi.fetchClinics(params);

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchPerCityMetrics = createAsyncThunk(
  StatisticsActions.fetchPerCityMetrics,
  async (params: StatsPerCityQueryParamsDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await StatisticsApi.fetchPerCityMetrics(
        params,
      );

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchPerClinicsMetrics = createAsyncThunk(
  StatisticsActions.fetchPerClinicsMetrics,
  async (params: StatsPerClinicsQueryParamsDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await StatisticsApi.fetchPerClinicsMetrics(
        params,
      );

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchPerDoctorsMetrics = createAsyncThunk(
  StatisticsActions.fetchPerDoctorsMetrics,
  async (params: StatsPerProfessionalsQueryParamsDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await StatisticsApi.fetchPerDoctorsMetrics(
        params,
      );

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchSpecializations = createAsyncThunk(
  StatisticsActions.fetchSpecializations,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await StatisticsApi.fetchSpecializations();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchPlatformStatistics = createAsyncThunk(
  StatisticsActions.fetchPlatformStatistics,
  async (params: PlatformStatisticsQueryParamsDto, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await StatisticsApi.fetchPlatformStatistics(params);

      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
