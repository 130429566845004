import { createSlice } from '@reduxjs/toolkit';
import { SpecializationMetricsState } from './models';
import {
  fetchSpecializations,
  fetchMetrics,
  fetchUnitsOfMeasurement,
  createUnitOfMeasurement,
  deleteUnitOfMeasurement,
  createMetric,
  deleteMetric,
  editMetric,
} from './ActionCreators';

const initialState: SpecializationMetricsState = {
  isLoading: false,
  error: '',
  metrics: [],
  specializations: [],
  unitsOfMeasurement: [],
  metricAdded: false,
  metricDeleted: false,
  metricEditedSuccessfully: false,
  editedMetric: null,
  total: 0,
  page: 0,
  totalPages: 0,
};

const specializationMetricsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setMetricAdded: (state, action) => {
      state.metricAdded = action.payload;
    },
    setMetricDeleted: (state, action) => {
      state.metricDeleted = action.payload;
    },
    setEditedMetric: (state, action) => {
      state.editedMetric = action.payload;
    },
    setMetricEditedSuccessfully: (state, action) => {
      state.metricEditedSuccessfully = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Fetch cities
    builder.addCase(fetchMetrics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMetrics.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchMetrics.fulfilled, (state, action) => {
      state.metrics = action.payload.entities;
      state.total = action.payload.total!;
      state.totalPages = action.payload.totalPages!;
      state.page = action.payload.page!;
      state.isLoading = false;
    });
    //Fetch specializations
    builder.addCase(fetchSpecializations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSpecializations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchSpecializations.fulfilled, (state, action) => {
      state.specializations = action.payload;
      state.isLoading = false;
    });
    //Fetch units of measurement
    builder.addCase(fetchUnitsOfMeasurement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUnitsOfMeasurement.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchUnitsOfMeasurement.fulfilled, (state, action) => {
      state.unitsOfMeasurement = action.payload.entities;
      state.isLoading = false;
    });
    //create Unit Of Measurement
    builder.addCase(createUnitOfMeasurement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUnitOfMeasurement.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createUnitOfMeasurement.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    //create Unit Of Measurement
    builder.addCase(deleteUnitOfMeasurement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteUnitOfMeasurement.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteUnitOfMeasurement.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    //create Unit Of Measurement
    builder.addCase(createMetric.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createMetric.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createMetric.fulfilled, (state, action) => {
      state.metricAdded = true;
      state.isLoading = false;
    });
    // delete metric
    builder.addCase(deleteMetric.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMetric.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteMetric.fulfilled, (state, action) => {
      state.metricDeleted = true;
      state.isLoading = false;
    });
    // edit metric
    builder.addCase(editMetric.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editMetric.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(editMetric.fulfilled, (state, action) => {
      state.metricEditedSuccessfully = true;
      state.isLoading = false;
    });
  },
});

export const {
  setMetricAdded,
  setMetricEditedSuccessfully,
  setMetricDeleted,
  setEditedMetric,
} = specializationMetricsSlice.actions;

export default specializationMetricsSlice.reducer;
