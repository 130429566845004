import { Box, styled } from '@mui/system';
import { styledTheme } from 'application/theme';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h1 {
    color: ${styledTheme.color.grey900};
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 39.2px;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
  }

  > h2 {
    color: ${styledTheme.color.grey700};
    text-align: center;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: 0.09px;
  }
`;

export const LogoWrapper = styled('div')`
  display: flex;
  width: 188px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;

  .name {
    flex: 1;
    height: 40px;
  }
  .logo {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }
`;
