import { PhotoResponseDto, UserLoginResDto } from '@docbay/schemas';
import { parseJwt } from 'common/helpers/parseJwt';
import { StorageFields } from 'application/constants';

export enum AuthLocalStorage {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  accessTokenExpiresAt = 'accessTokenExpiresAt',
  profiles = 'profiles',
}

export interface Profile {
  token: string;
  refreshToken: string;
  email: string;
  firstName: string;
  lastName: string;
  photo: PhotoResponseDto;
  id: string;
  userTypes?: string;
}

export const setUsersAuthSessionStorage = (value: UserLoginResDto) => {
  localStorage.setItem(
    AuthLocalStorage.accessToken,
    JSON.stringify(value.token),
  );
  localStorage.setItem(
    AuthLocalStorage.refreshToken,
    JSON.stringify(value.refreshToken),
  );

  localStorage.setItem(AuthLocalStorage.accessTokenExpiresAt, value.expiresAt);

  const token = parseJwt(value.token);

  localStorage.setItem(StorageFields.userRole, token['custom:userTypes']);
  localStorage.setItem(StorageFields.userId, value.user?.id as string);
  localStorage.setItem(
    StorageFields.userPermissions,
    token['custom:permissions'],
  );

  if (token && token['custom:proPermissions']?.length) {
    localStorage.setItem(
      StorageFields.userProPermissions,
      JSON.stringify(token['custom:proPermissions']),
    );
  }
};

export const setProfilesInSessionStorage = (profiles: Profile[]) => {
  const parsedProfile = profiles.map((item) => {
    return {
      ...item,
      userTypes: parseJwt(profiles[0].token)
        ? parseJwt(profiles[0].token)['custom:userTypes']
        : '',
    };
  });

  localStorage.setItem(
    AuthLocalStorage.profiles,
    JSON.stringify(parsedProfile),
  );
};

export const getProfilesFromSessionStorage = (): Profile[] | null => {
  const profiles = localStorage.getItem(AuthLocalStorage.profiles);

  return profiles ? (JSON.parse(profiles) as Profile[]) : null;
};

export const getUserRole = () => localStorage.getItem(StorageFields.userRole);
export const getUserId = () => localStorage.getItem(StorageFields.userId);
export const getToken = () => {
  const token = localStorage.getItem(AuthLocalStorage.accessToken);
  return token ? JSON.parse(token) : '';
};

export const setUsersRefreshTokenSessionStorage = (value: {
  token: string;
  expiresAt: string;
}) => {
  localStorage.setItem(
    AuthLocalStorage.accessToken,
    JSON.stringify(value.token),
  );

  localStorage.setItem(AuthLocalStorage.accessTokenExpiresAt, value.expiresAt);
};
