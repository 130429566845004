import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, PrimaryButton } from 'common/components';
import {
  TableStyled,
  SearchStyled,
} from 'features/feature-users-managment/components/Main/styles';
import { Input } from 'common/components';
import { ReactComponent as AddIcon } from 'application/assets/add.svg';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import Pagination from 'common/components/Pagination';
import { useDebounce } from 'common/hooks/useDebounce';
import { Wrapper } from 'features/feature-users-managment/components/PatientsList/styles';
import Dropdown from 'common/components/Dropdown';
import {
  FiltersSectionStyled,
  ButtonsWrapper,
  StatusWrapper,
  ImportButton,
  StatusLine,
} from './styles';
import { Specialization } from '@docbay/schemas';
import { fetchSpecializations } from 'application/store/reducers/Statistics/ActionCreators';
import ImportedDoctorsNoDataElement from '../ImportedDoctorsNoDataElement';
import ImportedDoctorsItem from '../ImportedDoctorsItem';
import DocumentsUploadModal from 'common/components/DocumentsUploadModal';
import ImportedDoctorAddModal from '../ImportedDoctorAddModal';
import {
  fetchImportedProfessionals,
  uploadProfessionals,
  uploadStatus,
} from 'application/store/reducers/ImportProfessionals/ActionCreators';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import SuccessModal from 'common/components/SuccessModal';
import {
  setIsDeleted,
  setLoading,
  setSuccessAddedProfessional,
  setSuccessDeleted,
  setEditedProfessional,
} from 'application/store/reducers/ImportProfessionals/ImportProfessionalsSlice';
import ImportedDoctorEditModal from '../ImportedDoctorEditModal';
import dayjs from 'dayjs';
import { statuses } from './Statuses';
import {
  ExternalProfessionalImportStatus,
  ExternalProfessionalImportInfoDto,
} from '@docbay/schemas';

const defaultLimit = 10;

const ImportedDoctors: FC = () => {
  const { t, i18n } = useTranslation();
  const { localizeNameKey } = useLocalizeKey();
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const debouncedInput = useDebounce(inputValue, 500);
  const [currentPage, setCurrentPage] = useState(1);
  const [specializationIds, setSpecializationIds] = useState<string[]>([]);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [selectedProfessionalId, setSelectedProfessionalId] = useState('');

  const {
    isLoading,
    professionals = [],
    totalPages = 0,
    successDeleted,
    status,
  } = useAppSelector((state) => state.importProfessionals);

  const isInProgress = useMemo(() => {
    return status?.status === 'IN_PROGRESS';
  }, [status?.status]);

  const getCurrentStatus = () => {
    dispatch(uploadStatus()).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setStatusTimeInterval(data.payload);
      }
    });
  };

  const setStatusTimeInterval = (data: ExternalProfessionalImportInfoDto) => {
    const getRequestStatus = setTimeout(() => {
      getCurrentStatus();
    }, 15000);

    if (data?.status !== 'IN_PROGRESS') {
      clearInterval(getRequestStatus);
    }
  };

  useEffect(() => {
    !status && getCurrentStatus();
  }, []);

  const { specializations } = useAppSelector((state) => state.statistics);

  const handleCloseModal = () => {
    setIsImportModalOpen(false);
  };

  const specializationsOptions = useMemo(() => {
    const options = specializations.map((item) => {
      const specializationName = item[localizeNameKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });
    return options;
  }, [specializations, i18n.language]);

  const getCurrentData = useCallback(
    async (page: number) => {
      await dispatch(
        fetchImportedProfessionals({
          ...(debouncedInput.length > 0 ? { search: debouncedInput } : {}),
          limit: defaultLimit,
          page: page,
          ...(specializationIds?.length ? { specializationIds } : {}),
        }),
      );
      setCurrentPage(page);
    },
    [debouncedInput, currentPage, specializationIds],
  );

  const handleSaveModal = (data: FormData) => {
    dispatch(setLoading(true));
    handleCloseModal();
    dispatch(uploadProfessionals(data)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        dispatch(uploadStatus()).then((data) => {
          if (data.meta.requestStatus === 'fulfilled') {
            setStatusTimeInterval(data.payload);
            dispatch(setLoading(false));
          }
        });
      }
    });
  };

  const handleSearch = (value: string) => {
    setInputValue(value);
  };

  useEffect(() => {
    dispatch(fetchSpecializations());
  }, []);

  useEffect(() => {
    getCurrentData(1);
  }, [debouncedInput, specializationIds]);

  const clearSearch = () => {
    setInputValue('');
  };

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleCloseCreateModal = async () => {
    setOpenCreateModal(false);
    dispatch(setSuccessAddedProfessional(false));
    getCurrentData(1);
  };

  const handleCloseSuccessModal = async () => {
    getCurrentData(currentPage);
    dispatch(setSuccessDeleted(null));
    dispatch(setIsDeleted(false));
  };

  const successDeleteTitle = useMemo(() => {
    return `${t('users_managment.doctor')} ${successDeleted?.firstName} ${
      successDeleted?.lastName
    } ${t('users_managment.is_deleted')}`;
  }, [successDeleted]);

  const handleCloseEditProfessional = () => {
    setSelectedProfessionalId('');
    dispatch(setEditedProfessional(null));
    getCurrentData(currentPage);
  };

  const statusText = useMemo(() => {
    return (
      <span>
        {' '}
        | {t('users_managment.imported_doctors.status')}{' '}
        {dayjs(status?.latestImportedAt)
          .locale(i18n.language)
          .format('DD/MM/YYYY HH:mm')}
        :{' '}
      </span>
    );
  }, [status]);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <FiltersSectionStyled>
        <SearchStyled>
          <Input
            id={'doctorName'}
            type="search"
            value={inputValue}
            onClear={clearSearch}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={t('users_managment.search_by_name_placeholder')}
          />
          <div className={'dropdown'}>
            <Dropdown
              id={'specializations'}
              value={specializationIds}
              placeholder={t('statistics.specializations')}
              onChange={(value) => {
                setSpecializationIds(value as string[]);
              }}
              options={specializationsOptions}
              withSearch={true}
              isMulti={true}
            />
          </div>
        </SearchStyled>

        <ButtonsWrapper isStatus={!!status}>
          <StatusWrapper isStatus={!!status}>
            <ImportButton
              disabled={isInProgress}
              onClick={() => {
                setIsImportModalOpen(true);
              }}
            >
              {t('users_managment.imported_doctors.import_file')}
            </ImportButton>
            {!!status && (
              <StatusLine>
                {statusText}{' '}
                {statuses[status?.status as ExternalProfessionalImportStatus]}
              </StatusLine>
            )}
          </StatusWrapper>

          <PrimaryButton
            onClick={handleOpenCreateModal}
            disabled={isInProgress}
          >
            <AddIcon />
            {t('global.add_new')}
          </PrimaryButton>
        </ButtonsWrapper>
      </FiltersSectionStyled>

      <TableStyled>
        <table>
          <thead>
            <tr>
              <th>
                <span>
                  {t(
                    'users_managment.imported_doctors.lists.doctor_name_title',
                  )}
                </span>
              </th>
              <th>
                <span>
                  {t(
                    'users_managment.imported_doctors.lists.doctor_phone_title',
                  )}
                </span>
              </th>
              <th>
                <span>
                  {t(
                    'users_managment.imported_doctors.lists.doctor_email_title',
                  )}
                </span>
              </th>
              <th>
                {t(
                  'users_managment.imported_doctors.lists.doctor_specialization',
                )}
              </th>
              <th className="actionsColumn">
                <span>
                  {t('users_managment.imported_doctors.lists.actions_title')}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {professionals.map((item) => (
              <ImportedDoctorsItem
                key={`${item?.firstName}${item?.lastName}`}
                doctor={item}
                setSelectedProfessionalId={setSelectedProfessionalId}
              />
            ))}
            {professionals.length === 0 && (
              <ImportedDoctorsNoDataElement
                onOpenPopup={() => setIsImportModalOpen(true)}
              />
            )}
          </tbody>
        </table>
      </TableStyled>
      {totalPages > 1 ? (
        <Pagination
          pages={totalPages}
          setCurrentPage={(page) => getCurrentData(page)}
          currentPage={currentPage}
          itemCount={professionals.length || 0}
        />
      ) : (
        ''
      )}
      {openCreateModal && (
        <ImportedDoctorAddModal
          opened={openCreateModal}
          onClose={handleCloseCreateModal}
        />
      )}
      <DocumentsUploadModal
        isOpen={isImportModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveModal}
        isLoading={isLoading}
        extantions={'.xlsx'}
      />
      <SuccessModal
        title={successDeleteTitle}
        onClose={handleCloseSuccessModal}
        opened={!!successDeleted}
      />
      {!!selectedProfessionalId && (
        <ImportedDoctorEditModal
          professionalId={selectedProfessionalId}
          opened={!!selectedProfessionalId}
          onClose={handleCloseEditProfessional}
        />
      )}
    </Wrapper>
  );
};

export default ImportedDoctors;
