import { Box, styled } from '@mui/system';
import { styledTheme } from 'application/theme';

export const LogoWrapper = styled('div')`
  display: flex;
  width: 188px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin: 0 auto 64px auto;
  .name {
    flex: 1;
    height: 40px;
  }
  .logo {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }
`;

export const FormTitle = styled(Box)`
  color: ${styledTheme.color.grey900};
  text-align: center;
  font-family: 'AcidGrotesk-Medium';
  font-size: 28px;
  line-height: 39.2px;
  letter-spacing: 0.5px;
`;

export const FormSubTitle = styled(Box)`
  color: ${styledTheme.color.grey700};
  text-align: center;
  font: 500 18px/25.2px 'Mulish', sans-serif;
  letter-spacing: 0.09px;
`;
export const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
`;
