import React from 'react';
import { TableStyled, TableWrapper } from './styles';
import { useAppSelector } from 'common/hooks/redux';
import { metricsList } from 'features/feature-statistics/constants';
import { Option } from 'application/store/reducers/Statistics/models';
import { CommonCountsDto } from '@docbay/schemas';
import MetricTableName from '../MetricTableName';
import MetricTableCol from '../MetricTableCol';
import { useTranslation } from 'react-i18next';

const PerClinicsMetricsTable = () => {
  const { t } = useTranslation();
  const { perClinicsMetricsData } = useAppSelector((state) => state.statistics);

  return (
    <TableWrapper>
      <TableStyled>
        <table>
          <thead>
            <tr>
              <th>
                <div className={'firstColumn'}>
                  <span>{t('statistics.clinics')}</span>
                </div>
              </th>
              {metricsList?.map((metricName: Option, ind) => {
                return (
                  <MetricTableName
                    key={`${metricName.value}_${ind}`}
                    metricName={metricName}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {perClinicsMetricsData?.total && (
              <tr>
                <td className={'firstColItem'}>{t('statistics.total')}</td>
                {metricsList?.map((metricName: Option) => {
                  const total = perClinicsMetricsData?.total!;

                  return (
                    <td key={`total_${metricName.value}`}>
                      <span>
                        {total[metricName.value as keyof CommonCountsDto]}
                      </span>
                    </td>
                  );
                })}
              </tr>
            )}

            {perClinicsMetricsData?.perClinic?.entities?.map((item, ind) => {
              return (
                <tr key={`${item.clinicName}`}>
                  <td className={'firstColItem'}>{item.clinicName}</td>
                  {metricsList?.map((metricName: Option) => {
                    return (
                      <MetricTableCol
                        value={item}
                        item={metricName.value}
                        key={`${metricName.value}_${ind}`}
                        metricsName={metricName.label}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableStyled>
    </TableWrapper>
  );
};

export default PerClinicsMetricsTable;
