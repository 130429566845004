import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  addProfessionals,
  deleteProfessionals,
  fetchImportedProfessionals,
  updateProfessionals,
  uploadStatus,
  uploadProfessionals,
  getExternalProfessionalById,
} from './ActionCreators';
import { InternalUsers } from './models';
import { ExternalProfessionalResponseDto } from '@docbay/schemas';

const initialState: InternalUsers = {
  professionals: [],
  editedProfessional: null,
  total: 0,
  page: 0,
  totalPages: 0,
  isLoading: false,
  error: '',
  successCreated: false,
  successDeleted: null,
  successUpdated: false,
  isDeleted: false,
  status: null,
};

const importProfessionalsSlice = createSlice({
  name: 'internalUsers',
  initialState,
  reducers: {
    setSuccessDeleted: (state, action) => {
      state.successDeleted = action.payload;
    },
    setIsDeleted: (state, action) => {
      state.isDeleted = action.payload;
    },
    setEditedProfessional: (
      state,
      action: PayloadAction<ExternalProfessionalResponseDto | null>,
    ) => {
      state.editedProfessional = action.payload;
    },
    setSuccessAddedProfessional: (state, action) => {
      state.successCreated = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    //fetch imported professionals
    builder.addCase(fetchImportedProfessionals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchImportedProfessionals.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchImportedProfessionals.fulfilled, (state, action) => {
      state.isLoading = false;
      state.professionals = action.payload.entities;
      state.total = action.payload.total || 0;
      state.page = action.payload.page || 0;
      state.totalPages = action.payload.totalPages || 0;
    });
    //add doctor
    builder.addCase(addProfessionals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addProfessionals.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(addProfessionals.fulfilled, (state) => {
      state.isLoading = false;
      state.successCreated = true;
    });
    //update doctor
    builder.addCase(updateProfessionals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProfessionals.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateProfessionals.fulfilled, (state) => {
      state.isLoading = false;
      state.successUpdated = true;
    });
    //delete doctor
    builder.addCase(deleteProfessionals.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProfessionals.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteProfessionals.fulfilled, (state) => {
      state.isLoading = false;
      state.isDeleted = true;
    });
    //upload doctors list
    builder.addCase(uploadProfessionals.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    //upload doctors list
    builder.addCase(uploadStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(uploadStatus.fulfilled, (state, action) => {
      state.status = action.payload;
    });
    //get external professional by id
    builder.addCase(getExternalProfessionalById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getExternalProfessionalById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getExternalProfessionalById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.editedProfessional = action.payload;
    });
  },
});

export const {
  setSuccessDeleted,
  setIsDeleted,
  setSuccessAddedProfessional,
  setEditedProfessional,
  setLoading,
} = importProfessionalsSlice.actions;

export default importProfessionalsSlice.reducer;
