import CryptoJS from 'crypto-js';

export const encrypt = (text: string, secretKey: string, iv: string) => {
  const value = CryptoJS.enc.Utf8.parse(text);
  const key = CryptoJS.enc.Hex.parse(secretKey);
  const ivParsed = CryptoJS.enc.Hex.parse(iv);
  const encrypted = CryptoJS.AES.encrypt(value, key, {
    iv: ivParsed,
    mode: CryptoJS.mode.CBC,
  });

  return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
};

export const decrypt = (text: string, secretKey: string, iv: string) => {
  const key = CryptoJS.enc.Hex.parse(secretKey);
  const ivParsed = CryptoJS.enc.Hex.parse(iv);

  const decrypted = CryptoJS.AES.decrypt(
    // @ts-ignore
    { ciphertext: CryptoJS.enc.Hex.parse(text) },
    key,
    { iv: ivParsed },
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};
