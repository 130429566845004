import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { FilterSection, LeftFilters, Wrapper } from './styles';
import Dropdown from 'common/components/Dropdown';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { CountryDropdown, Loader } from 'common/components';
import { fetchPlatformStatistics } from 'application/store/reducers/Statistics/ActionCreators';
import dayjs from 'dayjs';
import Pagination from 'common/components/Pagination';
import { SupportedCountriesDto } from 'common/types/countries';
import utc from 'dayjs/plugin/utc';
import { onCityChange } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { PlatformStatisticsDataDto, PlatformStatisticsProps } from './models';
import PlatformStatisticsTable from '../PlatformStatisticsTable';
import { useStatisticFilters } from 'common/hooks/useStatisticFilters';

dayjs.extend(utc);

const PlatformStatistics: FC<PlatformStatisticsProps> = ({
  supportedCountries,
  setSupportedCountries,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCities, setCurrentCities] = useState<string[]>([]);
  const [citiesNames, setCitiesNames] = useState<string[]>([]);
  const { cities, fetchCities } = useStatisticFilters();

  const { isLoading, platformStatistics } = useAppSelector(
    (state) => state.statistics,
  );

  const getCurrenData = async (data?: PlatformStatisticsDataDto) => {
    const page = data?.page || currentPage;
    const cities = data?.citiesNames || citiesNames;

    const params = {
      page: page,
      limit: 10,
      ...(cities.length ? { cities: cities } : {}),
      ...(supportedCountries.length
        ? {
            supportedCountriesIso: supportedCountries.map((item) => item.code),
          }
        : {}),
    };

    await dispatch(fetchPlatformStatistics(params));
  };

  useEffect(() => {
    fetchCities(supportedCountries);
    getCurrenData();
  }, [supportedCountries]);

  const citiesOptions = useMemo(() => {
    const formatted = cities?.map((item) => {
      return { label: item.city, value: item.id };
    });

    return formatted;
  }, [cities]);

  const handleCityChange = (data: string[]) => {
    const citiesData = onCityChange(data, citiesOptions);

    getCurrenData({ page: 1, citiesNames: citiesData.citiesLabels });
    setCurrentCities(citiesData.data);
    setCitiesNames(citiesData.citiesLabels);
  };

  const handlePagination = (page: number) => {
    getCurrenData({ page });
    setCurrentPage(page);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <FilterSection>
        <LeftFilters>
          <CountryDropdown
            id="country-dropdown"
            value={supportedCountries}
            onChange={(value) => {
              setSupportedCountries(value as SupportedCountriesDto[]);
            }}
            isMulti={true}
          />
          <Dropdown
            id="cities"
            placeholder={t('statistics.cities')}
            isMulti
            withSearch
            value={currentCities}
            onChange={(data) => {
              handleCityChange(data as string[]);
            }}
            options={citiesOptions!}
          />
        </LeftFilters>
      </FilterSection>
      <PlatformStatisticsTable />
      {platformStatistics?.perCity?.entities?.length ? (
        <Pagination
          pages={platformStatistics?.perCity?.totalPages || 1}
          setCurrentPage={handlePagination}
          currentPage={currentPage}
          itemCount={platformStatistics?.perCity?.entities?.length || 0}
        />
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default memo(PlatformStatistics);
