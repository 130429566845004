import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { FormSubTitle, FormTitle, HeaderWrapper, LogoWrapper } from './styles';
import { ReactComponent as Logo } from 'application/assets/logo.svg';
import { ReactComponent as DocBayName } from 'application/assets/doc-bay-name.svg';

const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <LogoWrapper>
        <DocBayName className="name" /> <Logo className="logo" />
      </LogoWrapper>
      <FormTitle>{t('auth.log_in.title')}</FormTitle>
      <FormSubTitle>{t('auth.log_in.sub_title')}</FormSubTitle>
    </HeaderWrapper>
  );
};

export default Header;
