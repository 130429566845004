import React, { FC } from 'react';

import { Header, ProfileInfo } from './components';
import { Wrapper } from './styles';

const ClinicProfile: FC = () => {
  return (
    <Wrapper>
      <Header />
      <ProfileInfo />
    </Wrapper>
  );
};

export default ClinicProfile;
