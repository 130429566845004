import axiosInstance from 'integration/api/axiosInstance';
import {
  CommonEntitiesListResponse,
  ExternalProfessionalCreationDto,
  ExternalProfessionalResponseDto,
  ExternalProfessionalUpdateDto,
} from '@docbay/schemas';
import { GetImportedDoctorsParams } from 'common/types/importedDoctors';

export const ImportProfessionalsApi = {
  fetchImportedProfessionals: (params: GetImportedDoctorsParams) =>
    axiosInstance.get<
      CommonEntitiesListResponse<ExternalProfessionalResponseDto>
    >('/external/professionals', {
      params,
    }),
  getExternalProfessionalById: (id: string) =>
    axiosInstance.get<ExternalProfessionalResponseDto>(
      `/external/professionals/${id}`,
    ),
  addProfessionals: (data: ExternalProfessionalCreationDto) =>
    axiosInstance.post<ExternalProfessionalResponseDto>(
      '/external/professionals',
      data,
    ),
  updateProfessionals: (id: string, data: ExternalProfessionalUpdateDto) =>
    axiosInstance.patch<ExternalProfessionalResponseDto>(
      `/external/professionals/${id}`,
      data,
    ),
  deleteProfessionals: (id: string) =>
    axiosInstance.delete(`/external/professionals/${id}`),
  uploadProfessionals: (data: FormData) =>
    axiosInstance.post('/external/professionals/import', data),
  uploadStatus: () =>
    axiosInstance.get('/external/professionals/import/status'),
};
