import React from 'react';

const MetricTableCol = ({
  value,
  item,
  key,
  metricsName,
}: {
  value: any;
  item: string;
  key: string;
  metricsName: string;
}) => {
  if (!metricsName) return <></>;

  return (
    <td key={key}>
      <span>{value[item]}</span>
    </td>
  );
};

export default MetricTableCol;
