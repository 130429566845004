import { styled } from '@mui/system';

export const Wrapper = styled('article')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.common.grey200};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  margin-top: 20px;
  background: ${({ theme }) => theme.palette.common.white};
`;

export const FiltersSectionStyled = styled('section')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;

  & > button {
    min-width: 150px;
    width: fit-content;
    color: ${({ theme }) => theme.palette.common.grey900};
    svg {
      path {
        fill: ${({ theme }) => theme.palette.common.grey900};
      }
    }
  }
`;
