import styled from 'styled-components';

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  > h1 {
    color: ${({ theme }) => theme.color.grey700};
    font-family: 'AcidGrotesk-Medium';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.1px;
  }
`;
