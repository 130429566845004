import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { fetchPerCityMetrics } from 'application/store/reducers/Statistics/ActionCreators';
import { metricsList } from 'features/feature-statistics/constants';
import {
  setMetricsData,
  setPerCitiesFilters,
} from 'application/store/reducers/Statistics/StatisticsSlice';

import { SupportedCountriesDto } from 'common/types/countries';
import Pagination from 'common/components/Pagination';
import Dropdown from 'common/components/Dropdown';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { CountryDropdown, Loader } from 'common/components';
import DatePicker from 'common/components/DatePicker';

import PerCityMetricsTable from 'features/feature-statistics/components/PerCityMetricsTable';
import {
  onCityChange,
  onDateChange,
  removeKeysFromObject,
} from '../../helpers';

import { PerCityDataDto, PerCityProps } from './models';
import {
  DateWrapper,
  FilterSection,
  LeftFilters,
  MetricsFilter,
  Wrapper,
} from './styles';
import { useStatisticFilters } from 'common/hooks/useStatisticFilters';

dayjs.extend(utc);

const PerCity: FC<PerCityProps> = ({
  supportedCountries,
  setSupportedCountries,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCities, setCurrentCities] = useState<string[]>([]);
  const [currentMetrics, setMetrics] = useState<string[]>([]);
  const [timeFrom, setTimeFrom] = useState<Date | null>(null);
  const [timeTo, setTimeTo] = useState<Date | null>(null);
  const [citiesNames, setCitiesNames] = useState<string[]>([]);
  const { cities, fetchCities } = useStatisticFilters();

  const { isLoading, metrics, perCitiesMetricsData } = useAppSelector(
    (state) => state.statistics,
  );

  const getCurrenData = async (data?: PerCityDataDto) => {
    const page = data?.page || currentPage;
    const cities = data?.citiesNames || citiesNames;
    const dataTimeFrom = data?.timeFrom || timeFrom;
    const dataTimeTo = data?.timeTo || timeTo;

    const params = {
      page: page,
      limit: 10,
      ...(cities.length ? { cities: cities } : {}),
      ...(dataTimeFrom
        ? {
            timeRange: {
              start: dayjs(dataTimeFrom).utc(true).toDate() as Date,
              end: (dayjs(dataTimeTo).utc(true).format() ||
                dayjs().utc(true).toDate()) as Date,
            },
          }
        : {}),
      ...(supportedCountries.length
        ? {
            supportedCountriesIso: supportedCountries.map((item) => item.code),
          }
        : {}),
    };

    await dispatch(fetchPerCityMetrics(params));

    // remove page and limit from the object since we do not want to limit the statistics to pagination parameters
    dispatch(
      setPerCitiesFilters(removeKeysFromObject(params, ['page', 'limit'])),
    );
  };

  useEffect(() => {
    const getData = async () => {
      await fetchCities(supportedCountries);
      await getCurrenData({ page: 1 });
    };
    getData();
  }, [supportedCountries]);

  useEffect(() => {
    setMetrics(metrics);
  }, [metrics]);

  const citiesOptions = useMemo(() => {
    const formatted = cities?.map((item) => {
      return { label: item.city, value: item.id };
    });

    return formatted;
  }, [cities]);

  const handleCityChange = (data: string[]) => {
    const citiesData = onCityChange(data, citiesOptions);

    getCurrenData({ page: 1, citiesNames: citiesData.citiesLabels });
    setCurrentCities(citiesData.data);
    setCitiesNames(citiesData.citiesLabels);
  };

  const handleDateToChange = (date: string) => {
    const dateTimeTo = onDateChange(date, 'to');

    getCurrenData({ page: 1, timeTo: dateTimeTo });
    setTimeTo(timeTo!);
  };

  const handleDateFromChange = (date: string) => {
    const dateTimeFrom = onDateChange(date, 'from');

    getCurrenData({
      page: 1,
      timeFrom: dateTimeFrom,
      ...(!timeTo
        ? { timeTo: dayjs().set('hours', 23).set('minutes', 59).toDate() }
        : { timeTo }),
    });

    if (!timeTo) {
      setTimeTo(dayjs().set('hours', 23).set('minutes', 59).toDate());
    }
    setTimeFrom(dateTimeFrom);
  };

  const handlePagination = (page: number) => {
    getCurrenData({ page });
    setCurrentPage(page);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <FilterSection>
        <LeftFilters>
          <CountryDropdown
            id="country-dropdown"
            value={supportedCountries}
            onChange={(value) => {
              setSupportedCountries(value as SupportedCountriesDto[]);
            }}
            isMulti={true}
          />
          <Dropdown
            id="cities"
            placeholder={t('statistics.cities')}
            isMulti
            withSearch
            value={currentCities}
            onChange={(data) => {
              handleCityChange(data as string[]);
            }}
            options={citiesOptions!}
          />
          <DateWrapper>
            <DatePicker
              id={'from'}
              label={t('statistics.period_from')}
              value={dayjs(timeFrom).format()}
              maxDate={dayjs(timeTo).valueOf()}
              onChange={(date) => {
                handleDateFromChange(date);
              }}
            />
            <DatePicker
              id={'to'}
              label={t('statistics.period_to')}
              value={dayjs(timeTo).format()}
              maxDate={Date.now()}
              onChange={(date) => {
                handleDateToChange(date);
              }}
            />
          </DateWrapper>
        </LeftFilters>
        <MetricsFilter>
          <Dropdown
            id="metrics"
            placeholder={t('statistics.metrics')}
            isMulti
            withSearch={false}
            value={currentMetrics}
            onChange={(data) => {
              dispatch(setMetricsData(data));
            }}
            options={metricsList!}
          />
        </MetricsFilter>
      </FilterSection>
      <PerCityMetricsTable />
      {perCitiesMetricsData?.perCity?.entities?.length ? (
        <Pagination
          pages={perCitiesMetricsData?.perCity?.totalPages || 1}
          setCurrentPage={handlePagination}
          currentPage={currentPage}
          itemCount={perCitiesMetricsData?.perCity?.entities?.length || 0}
        />
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default PerCity;
