import dayjs from 'dayjs';
import { Option } from 'common/components/Dropdown/models';

export const onCityChange = (data: string[], citiesOptions?: Option[]) => {
  const filteredCities = data.map((city) => {
    return citiesOptions?.filter((item) => item.value === city);
  });

  const citiesLabels = filteredCities.flat().map((name) => String(name?.label));

  return {
    citiesLabels,
    data,
  };
};

export const onDateChange = (date: string, type: string) => {
  if (type === 'to') {
    return dayjs(date).set('hours', 23).set('minutes', 59).toDate();
  } else {
    return dayjs(date).set('hours', 0).set('minutes', 0).toDate();
  }
};

/**
 * Removes specified keys from a given object and returns a new object without those keys.
 *
 * @template T - The type of the input object.
 * @template K - The type of the keys to be removed.
 *
 * @param {T} obj - The input object from which keys are to be removed.
 * @param {K[]} keys - An array of keys that should be removed from the object.
 *
 * @returns {Omit<T, K>} A new object with the specified keys removed.
 *
 * @example
 * const originalObject = { a: 1, b: 2, c: 3, d: 4 };
 * const keysToRemove = ['b', 'd'];
 * const modifiedObject = removeKeysFromObject(originalObject, keysToRemove);
 * console.log(modifiedObject); // Output: { a: 1, c: 3 }
 */
export function removeKeysFromObject<T, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> {
  const result = { ...obj };

  keys.forEach((key) => {
    delete result[key];
  });

  return result;
}
