import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useProfileInfoSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');

  return yup.object({
    billingApiKey: yup.string().required(requiredError),
  });
};
