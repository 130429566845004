import {
  CommonEntitiesListResponse,
  SaasAdminCreationDto,
  SaasAdminUpdateDto,
} from '@docbay/schemas';
import axiosInstance from './axiosInstance';

export const InternalUsersAPI = {
  fetchInternalUsers: (params: any) =>
    axiosInstance.get<CommonEntitiesListResponse<any>>(
      '/saas/users-management/internal-users',
      {
        params,
      },
    ),
  createInternalUser: (data: SaasAdminCreationDto) =>
    axiosInstance.post('/saas/create-admin', data),
  editInternalUser: (id: string, data: SaasAdminUpdateDto) =>
    axiosInstance.patch(`/saas/admin/${id}?adminType=${data.userType}`, data),
  deleteInternalUser: (adminId: string, type: string) =>
    axiosInstance.delete(`/saas/admin/${adminId}?adminType=${type}`),
};
