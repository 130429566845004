import axiosInstance from './axiosInstance';
import {
  CommonEntitiesListResponse,
  HealthMetricCreateRequest,
  HealthMetricUpdateRequest,
  Specialization,
  UnitOfMeasurementCreateRequest,
} from '@docbay/schemas';

export const MetricsAPI = {
  fetchMetrics: (params: {
    page: number;
    limit: number;
    specializationIds?: string;
  }) =>
    axiosInstance.get<CommonEntitiesListResponse<any>>('/health-metrics', {
      params,
    }),
  fetchSpecializations: () =>
    axiosInstance.get<Specialization[]>('/specializations'),
  fetchUnitsOfMeasurement: () =>
    axiosInstance.get<CommonEntitiesListResponse<any>>(
      '/health-metrics/unit-of-measurements',
    ),
  createUnitOfMeasurement: (data: UnitOfMeasurementCreateRequest) =>
    axiosInstance.post('/health-metrics/unit-of-measurements', data),
  deleteUnitOfMeasurement: (id: string) =>
    axiosInstance.delete(`/health-metrics/unit-of-measurements/${id}`),
  createMetric: (data: HealthMetricCreateRequest) =>
    axiosInstance.post('/health-metrics', data),
  deleteMetric: (id: string) => axiosInstance.delete(`/health-metrics/${id}`),
  editMetric: (id: string, data: HealthMetricUpdateRequest) =>
    axiosInstance.patch(`/health-metrics/${id}`, data),
};
