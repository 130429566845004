import { createSlice } from '@reduxjs/toolkit';
import { CustomerSupportState } from './models';

const initialState: CustomerSupportState = {
  currentUser: null,
  isLoading: false,
  error: '',
};

const customerSupportSlice = createSlice({
  name: 'customerSupportSlice',
  initialState,
  reducers: {
    setCurrentData: (state, action) => {
      state.currentUser = action.payload;
    },
  },
});

export default customerSupportSlice.reducer;
