import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProfessionalsActions } from 'application/constants/actions';
import { ProfessionalsAPI } from 'integration/api/professionals';
import { fetchProfessionalsProps } from 'common/types/professionals';
import { AxiosError } from 'axios';
import { LoginAsDto } from '@docbay/schemas';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchProfessionals = createAsyncThunk(
  ProfessionalsActions.fetchProfessionals,
  async (params: fetchProfessionalsProps, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.fetchProfessionals(params);

      await delay(1000);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const loginAsUser = createAsyncThunk(
  ProfessionalsActions.loginAsUser,
  async (params: LoginAsDto, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.loginAsUser(params);

      await delay(1000);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteClinicOwner = createAsyncThunk(
  ProfessionalsActions.deleteClinicOwner,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.deleteClinicOwner(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteDoctor = createAsyncThunk(
  ProfessionalsActions.deleteDoctor,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.deleteDoctor(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteSecretary = createAsyncThunk(
  ProfessionalsActions.deleteSecretary,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.deleteSecretary(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteAdmin = createAsyncThunk(
  ProfessionalsActions.deleteAdmin,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.deleteAdmin(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
