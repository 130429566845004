import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoginDataT } from 'features/feature-login/types';

export const useLoginForm = ({
  emailError,
  requiredError,
}: {
  emailError: string;
  requiredError: string;
}) => {
  const createPasswordSchema = yup
    .object({
      email: yup.string().email(emailError!).required(requiredError!),
      password: yup.string().required(requiredError),
      remember: yup.boolean(),
    })
    .required();

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<LoginDataT>({
    resolver: yupResolver(createPasswordSchema),
    mode: 'onChange',
  });

  return {
    register,
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
  };
};
