import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createInternalUser,
  editInternalUser,
  fetchInternalUsers,
} from './ActionCreators';
import { InternalUsers } from './models';
import {
  AdminCustomerTechResponseDto,
  AdminOwnerResponseDto,
  AdminTechResponseDto,
} from '@docbay/schemas';

const initialState: InternalUsers = {
  internalUsers: [],
  editedUser: null,
  total: 0,
  page: 0,
  totalPages: 0,
  isLoading: false,
  error: '',
  successCreated: false,
  successEdited: false,
};

const internalUsersSlice = createSlice({
  name: 'internalUsers',
  initialState,
  reducers: {
    resetInternalUsers: (state) => {
      state.internalUsers = [];
    },
    setSuccessCreated: (state, action: PayloadAction<boolean>) => {
      state.successCreated = action.payload;
    },
    setSuccessEdited: (state, action: PayloadAction<boolean>) => {
      state.successEdited = action.payload;
    },
    setEditedUser: (
      state,
      action: PayloadAction<
        | AdminOwnerResponseDto
        | AdminTechResponseDto
        | AdminCustomerTechResponseDto
        | null
      >,
    ) => {
      state.editedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    //professionals fetch
    builder.addCase(fetchInternalUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInternalUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.internalUsers = [];
      state.error = action.payload as string;
    });
    builder.addCase(fetchInternalUsers.fulfilled, (state, action) => {
      state.internalUsers = action.payload.entities;
      state.total = action.payload.total!;
      state.page = action.payload.page!;
      state.totalPages = action.payload.totalPages!;
      state.isLoading = false;
    });
    //create internal user
    builder.addCase(createInternalUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createInternalUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createInternalUser.fulfilled, (state) => {
      state.isLoading = false;
      state.successCreated = true;
    });
    //create internal user
    builder.addCase(editInternalUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editInternalUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(editInternalUser.fulfilled, (state) => {
      state.isLoading = false;
      state.successEdited = true;
    });
  },
});

export const {
  resetInternalUsers,
  setSuccessCreated,
  setEditedUser,
  setSuccessEdited,
} = internalUsersSlice.actions;

export default internalUsersSlice.reducer;
