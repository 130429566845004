import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getClinics } from 'application/store/reducers/ClinicsManagement/ActionCreators';

import { Loader } from 'common/components';
import Pagination from 'common/components/Pagination';
import { useAppSelector, useAppDispatch } from 'common/hooks/redux';

import ClinicItem from '../ClinicItem';
import NoDataElement from '../NoDataElement';
import ClinicsSuggestions from '../ClinicsSuggestions';

import { Wrapper, TableStyled } from './styles';

const defaultLimit = 10;

const ClinicsTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { total, totalPages, clinics, isLoading } = useAppSelector(
    (state) => state.clinicsManagement,
  );
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getClinics({ limit: defaultLimit, page: currentPage }));
  }, [currentPage]);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <ClinicsSuggestions />
      <TableStyled>
        <table>
          <thead>
            <tr>
              <th>
                <span>{t('clinics_management.clinics_name')}</span>
              </th>
              <th>
                <span>{t('clinics_management.clinic_owner_email')}</span>
              </th>
              <th className="actionsColumn">
                <span>{t('clinics_management.actions')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {clinics.map((clinic) => (
              <ClinicItem key={clinic.id} clinic={clinic} />
            ))}
            {!total && <NoDataElement />}
          </tbody>
        </table>
      </TableStyled>

      {!!total && (
        <Pagination
          pages={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          itemCount={total}
        />
      )}
    </Wrapper>
  );
};

export default ClinicsTable;
