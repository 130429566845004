import React from 'react';
import { FooterContentStyled, FooterWrapper } from './styles';
import { useTranslation } from 'react-i18next';

const AuthFooter = () => {
  const { t } = useTranslation();
  return (
    <FooterWrapper>
      <FooterContentStyled variant="body2">
        {t('auth.footer.copyright')}
      </FooterContentStyled>
    </FooterWrapper>
  );
};

export default AuthFooter;
