import React, { FC } from 'react';

import { SideMenu, ClinicProfile } from 'features';

import { Wrapper } from './styles';

const ClinicPage: FC = () => (
  <Wrapper>
    <SideMenu />
    <ClinicProfile />
  </Wrapper>
);

export default ClinicPage;
