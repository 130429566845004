import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PathNames } from 'application/routes/constants';
import { ReactComponent as Logo } from 'application/assets/logo.svg';
import { ReactComponent as MainLogoIcon } from 'application/assets/main_logo.svg';
import { ReactComponent as LanguageIcon } from 'application/assets/language.svg';
import { ReactComponent as LogOutIcon } from 'application/assets/logout.svg';
import { ReactComponent as ArrowLeftIcon } from 'application/assets/arrow-narrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'application/assets/arrow-narrow-right.svg';
import { MenuItem } from './components';
import { Wrapper, ButtonStyled, Box } from './styles';
import { Divider } from '@mui/material';
import i18next from 'i18next';
import {
  Profile,
  getProfilesFromSessionStorage,
} from 'application/sessionStorage/auth';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'common/hooks/redux';
import {
  signOut,
  usersRefreshToken,
} from 'application/store/reducers/Users/ActionCreators';
import {
  getMenuState,
  setMenuState,
} from 'application/sessionStorage/asideMenu';
import { useMenuList } from './hooks/useMenuList';
import { logout } from 'common/auth/authService';
import Avatar from './components/Avatar';

const SideMenu: FC = () => {
  const { t } = useTranslation();
  const isMenuOpened = getMenuState();
  const [isExpanded, setIsExpanded] = useState(isMenuOpened);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentLanguage =
    localStorage.getItem('i18nextLng')?.slice(0, 2).toUpperCase() || 'PT';

  const changeLanguage = async () => {
    const profile: Profile[] | null = getProfilesFromSessionStorage();
    i18next.changeLanguage(currentLanguage === 'EN' ? 'pt' : 'en');

    if (profile) {
      await dispatch(
        usersRefreshToken({
          refreshToken: profile[0].refreshToken,
          email: profile[0].email,
        }),
      );
    }
  };

  const handleLogOut = async () => {
    const response = await dispatch(signOut());
    if (response.meta.requestStatus === 'fulfilled') {
      logout();
      i18next.changeLanguage(currentLanguage === 'EN' ? 'en' : 'pt');
      navigate(PathNames.login);
    }
  };

  const handelChangeMenuState = (open: boolean) => {
    setMenuState(open);
    setIsExpanded(open);
  };

  const menuItems = useMenuList();

  return (
    <Wrapper className={isExpanded ? 'active' : ''}>
      <Box className={isExpanded ? 'expanded' : ''}>
        <Logo className={'logo'} />
        <MainLogoIcon className={'logo-text'} />
        {isExpanded && (
          <ButtonStyled
            type="button"
            onClick={() => handelChangeMenuState(false)}
          >
            <ArrowLeftIcon />
          </ButtonStyled>
        )}
      </Box>
      {!isExpanded && (
        <ButtonStyled type="button" onClick={() => handelChangeMenuState(true)}>
          <ArrowRightIcon />
        </ButtonStyled>
      )}
      <Avatar />

      <Divider />
      <div className={'item-list'}>
        <div>
          {menuItems.map((item) => (
            <MenuItem
              key={item.name}
              path={item.path}
              name={item.name}
              Image={item.image}
            />
          ))}
        </div>
        <div>
          <div className={'language'}>
            <LanguageIcon />
            <p
              onClick={() => {
                !isExpanded && handelChangeMenuState(true);
              }}
            >
              {currentLanguage}
            </p>
            <Divider orientation="vertical" />
            <span onClick={() => changeLanguage()}>
              {currentLanguage === 'EN' ? 'PT' : 'EN'}
            </span>
          </div>
        </div>
      </div>
      <Divider />
      <div className={'logout'} onClick={handleLogOut}>
        <span>{t('aside_menu.logout')}</span>
        <LogOutIcon />
      </div>
    </Wrapper>
  );
};

export default SideMenu;
