import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderStyled } from './styles';
import { useAppSelector } from 'common/hooks/redux';

const Header: FC = () => {
  const { t } = useTranslation();
  const { total } = useAppSelector((state) => state.clinicsManagement);

  return (
    <HeaderStyled>
      <h1>{t('clinics_management.clinics')}</h1>
      <p>
        {total} {t('clinics_management.clinics_available')}{' '}
      </p>
    </HeaderStyled>
  );
};

export default Header;
