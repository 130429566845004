import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton, PrimaryButton, Input } from 'common/components';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { ButtonWrapper, MainText, StyledModal } from './styles';
import { Modal } from '@mui/material';
import { useAddSpecialization } from '../../hooks/useAddSpecialization';
import { AddSpecializationModalProps } from './models';

const AddSpecializationModal: FC<AddSpecializationModalProps> = ({
  isOpen,
  onClose,
  onAdd,
  onEdit,
  specializationId,
  isEdit,
  initialData,
}) => {
  const { t } = useTranslation();
  const actionPrefix = isEdit ? 'edit' : 'add';

  const { register, errors, handleSubmit, getValues, reset, setValue } =
    useAddSpecialization({
      requiredError: t('errors.required'),
    });

  const handleAddSpecialization = () => {
    onAdd &&
      onAdd(
        {
          name_EN: getValues('name_en'),
          name_PT: getValues('name_pt'),
          name_FR: getValues('name_fr'),
        },
        reset,
      );
  };

  const handleEditSpecialization = () => {
    if (onEdit && specializationId) {
      onEdit(
        {
          name_EN: getValues('name_en'),
          name_PT: getValues('name_pt'),
          name_FR: getValues('name_fr'),
        },
        reset,
      );
    }
  };

  const handleActionOnSubmit = isEdit
    ? handleEditSpecialization
    : handleAddSpecialization;

  if (initialData?.name_EN) setValue('name_en', initialData.name_EN);
  if (initialData?.name_PT) setValue('name_pt', initialData.name_PT);
  if (initialData?.name_FR) setValue('name_fr', initialData.name_FR);

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <MainText>{t(`${actionPrefix}_specialization_modal.title`)}</MainText>
          <Input
            register={register}
            id={'name_en'}
            label={t(`${actionPrefix}_specialization_modal.name_en`)}
            type={'text'}
            onChange={() => {}}
            placeholder={t(
              `${actionPrefix}_specialization_modal.name_placeholder`,
            )}
            errorMessage={errors?.name_en?.message}
          />
          <Input
            register={register}
            id={'name_pt'}
            label={t(`${actionPrefix}_specialization_modal.name_pt`)}
            type={'text'}
            onChange={() => {}}
            placeholder={t(
              `${actionPrefix}_specialization_modal.name_placeholder`,
            )}
            errorMessage={errors?.name_pt?.message}
          />
          <Input
            register={register}
            id={'name_fr'}
            label={t(`${actionPrefix}_specialization_modal.name_fr`)}
            type={'text'}
            onChange={() => {}}
            placeholder={t(
              `${actionPrefix}_specialization_modal.name_placeholder`,
            )}
            errorMessage={errors?.name_fr?.message}
          />
          <ButtonWrapper>
            <SecondaryButton onClick={onClose}>
              {t('global.cancel')}
            </SecondaryButton>
            <PrimaryButton
              type="button"
              onClick={handleSubmit(handleActionOnSubmit)}
            >
              {t('global.save')}
            </PrimaryButton>
          </ButtonWrapper>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default AddSpecializationModal;
