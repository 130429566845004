export enum StorageFields {
  fbToken = 'FB_TOKEN',
  email = 'EMAIL',
  verificationCode = 'VERIFICATION_CODE',
  timer = 'TIMER',
  userRole = 'USER_ROLE',
  userPermissions = 'USER_PERMISSIONS',
  userProPermissions = 'USER_PRO_PERMISSIONS',
  userId = 'USER_ID',
}
