import React, { FC } from 'react';

import { ClinicsList, SideMenu } from 'features';

import { Wrapper } from './styles';

const ClinicsPage: FC = () => (
  <Wrapper>
    <SideMenu />
    <ClinicsList />
  </Wrapper>
);

export default ClinicsPage;
