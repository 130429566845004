import axiosInstance from './axiosInstance';
import {
  FiltersCitiesQueryParamsDto,
  FiltersClinicsQueryParamsDto,
  StatsPerCityQueryParamsDto,
  StatsPerClinicsQueryParamsDto,
  StatsPerProfessionalsQueryParamsDto,
  Specialization,
  PlatformStatisticsQueryParamsDto,
  PlatformStatisticResponseDto,
} from '@docbay/schemas';

export const StatisticsApi = {
  fetchCities: (params: FiltersCitiesQueryParamsDto) =>
    axiosInstance.post<any>('/statistics/filters/cities', params),
  fetchClinics: (params: FiltersClinicsQueryParamsDto) =>
    axiosInstance.post<any>('/statistics/filters/clinics', params),
  fetchPerCityMetrics: (params: StatsPerCityQueryParamsDto) =>
    axiosInstance.post<any>('/statistics/per-city', params),
  fetchPerClinicsMetrics: (params: StatsPerClinicsQueryParamsDto) =>
    axiosInstance.post<any>('/statistics/per-clinic', params),
  fetchPerDoctorsMetrics: (params: StatsPerProfessionalsQueryParamsDto) =>
    axiosInstance.post<any>('/statistics/per-professional', params),
  fetchSpecializations: () =>
    axiosInstance.get<Specialization[]>('/specializations'),
  fetchPlatformStatistics: (params: PlatformStatisticsQueryParamsDto) =>
    axiosInstance.post<PlatformStatisticResponseDto>(
      '/statistics/platform',
      params,
    ),
};
