import {
  UserLoginResDto,
  UserLoginDto,
  UserRefreshTokenDto,
  UserRefreshTokenResDto,
  UserChangePasswordDto,
  SaasAdminSetPasswordDto,
  SaasAdminSetPasswordResDto,
} from '@docbay/schemas';
import axiosInstance from './axiosInstance';
import axiosTokenInstance from './axiosTokenInstance';

export const UsersAPI = {
  usersLogin: (data: UserLoginDto) =>
    axiosTokenInstance().post<UserLoginResDto>('/users/login', data),
  usersRefreshToken: (data: UserRefreshTokenDto) =>
    axiosTokenInstance().post<UserRefreshTokenResDto>(
      '/users/refresh-token',
      data,
    ),
  signOut: () => axiosInstance.post('/users/logout'),
  changePasswordSaas: (data: UserChangePasswordDto) =>
    axiosInstance.post('/saas/change-password', data),
  createPassword: (data: SaasAdminSetPasswordDto) =>
    axiosInstance.post<SaasAdminSetPasswordResDto>('/saas/password', data),
};
