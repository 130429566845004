import styled from 'styled-components';

export const Wrapper = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20px;

  > h2 {
    color: ${({ theme }) => theme.color.greyDark800};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
  }
`;
