export enum PathNames {
  home = '/',
  login = '/login',
  registration = '/registration',
  error = '/error',
  forgotPassword = '/forgot-password',
  support = '/support',
  statistics = '/statistics',
  usersManagement = '/users-management',
  databases = '/databases',
  managingSubscriptions = '/managing-subscriptions',
  billing = '/billing',
  customerSupport = '/customer-support',
  password = '/password',
  createPassword = '/create-password',
  clinics = '/clinics',
  specializationMetrics = '/specialization-metrics',
}
