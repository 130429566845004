import React, { FC } from 'react';
import { Database, SideMenu } from 'features';
import { Wrapper } from './styles';

const DatabasesPage: FC = () => (
  <Wrapper>
    <SideMenu />
    <Database />
  </Wrapper>
);

export default DatabasesPage;
