import React, { FC, useState } from 'react';
import { ReactComponent as PersonIcon } from 'application/assets/person.svg';
import { ProfessionalNameStyled, StyledTr, AvatarStyled } from './styles';
import { Box } from '@mui/system';
import ResetPasswordAction from 'features/feature-users-managment/components/ResetPasswordAction';
import { ProfessionalItemProps } from './modules';
import { useTranslation } from 'react-i18next';
import LoginAsUserModal from '../LoginAsUserModal';
import { useAppDispatch } from 'common/hooks/redux';
import {
  setLoginAsUserError,
  setUserForDelete,
} from 'application/store/reducers/Professionals/ProfessionalsSlice';
import { getUserRole } from 'application/sessionStorage/auth';
import { UserRoles } from 'application/constants/userRoles';
import { resetPassword } from 'application/store/reducers/UserResetPassword/ActionCreators';
import DeleteProfileAction from '../DeleteProfileAction';

const ProfessionalItem: FC<ProfessionalItemProps> = ({ professional }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isPasswordSent, setIsPasswordSent] = useState(false);
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false);
  const userType = getUserRole();
  const isAdminOwner = userType === UserRoles.adminOwner;

  const handleResetPassword = () => {
    if (!isPasswordSent) {
      dispatch(resetPassword({ email: professional.user?.email })).then(
        (data) => {
          if (data.meta.requestStatus === 'fulfilled') {
            setIsPasswordSent(true);
          }
        },
      );
    }
  };
  //TODO: change when will have types
  //@ts-ignore
  const userTypes = professional?.user?.userType
    ?.map((item: string) => String(item))
    .join(', ');

  const handleModalClose = () => {
    dispatch(setLoginAsUserError(''));
    setIsShowLoginPopup(false);
  };

  const handleDeleteProfessional = () => {
    dispatch(setUserForDelete(professional));
  };

  return (
    <StyledTr>
      <td>
        <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
          <ProfessionalNameStyled>
            {professional.mainPhoto ? (
              <img
                src={professional.mainPhoto.thumbnailUrl}
                alt={professional.mainPhoto.id}
              />
            ) : (
              <AvatarStyled>
                <PersonIcon />
              </AvatarStyled>
            )}
            <div>
              <p>{professional.firstName + ' ' + professional.lastName}</p>
              <span>{professional.user?.email}</span>{' '}
            </div>{' '}
          </ProfessionalNameStyled>
        </Box>
      </td>
      <td>{userTypes}</td>
      <td className={'actions'}>
        <div>
          <ResetPasswordAction
            handleResetPassword={handleResetPassword}
            isPasswordReseted={isPasswordSent}
          />
          {isAdminOwner && userTypes?.includes('ClinicOwner') && (
            <a
              className={'linkToLogin'}
              onClick={() => setIsShowLoginPopup(true)}
            >
              {t('users_managment.lists.login_as_user')}
            </a>
          )}
          <DeleteProfileAction handleDelete={handleDeleteProfessional} />
        </div>
      </td>
      {isShowLoginPopup && (
        <LoginAsUserModal
          isOpen={isShowLoginPopup}
          onClose={handleModalClose}
          userId={professional.id}
          entityUserType={professional.entity}
        />
      )}
    </StyledTr>
  );
};

export default ProfessionalItem;
