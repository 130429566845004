import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DocBaySupportLink } from 'application/constants/externalLinks';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { ButtonWrapper, MainText, StyledModal } from './styles';
import { Modal } from '@mui/material';
import { ReactComponent as CommonErrorIcon } from 'application/assets/common_error.svg';
import PrimaryButton from '../PrimaryButton';
import { setIsErrorModalVisible } from '../../errorService/errorStore';
import { useAppDispatch } from 'common/hooks/redux';

interface Props {
  isOpen: boolean;
}
const CommonErrorModal: FC<Props> = ({ isOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={() => dispatch(setIsErrorModalVisible())} />
        <div>
          <CommonErrorIcon />
          <MainText>{t('global_error.text_title')}</MainText>
          <p>{t('global_error.text_description')}</p>
          <ButtonWrapper>
            <SecondaryButton onClick={() => dispatch(setIsErrorModalVisible())}>
              {t('global_error.try_again')}
            </SecondaryButton>
            <Link to={DocBaySupportLink} target="_blank">
              <PrimaryButton type="button">
                {t('global_error.support')}
              </PrimaryButton>
            </Link>
          </ButtonWrapper>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default CommonErrorModal;
