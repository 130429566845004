import React, { FC } from 'react';

import { AuthPageWrapper } from 'common/auth/AuthPageLayout';
import { LoginPage } from 'features';

export const Login: FC = () => {
  return (
    <AuthPageWrapper>
      <LoginPage />
    </AuthPageWrapper>
  );
};
