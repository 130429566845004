import styled from 'styled-components';
import { ButtonStyledProps } from './modules';

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const TabsSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > h1 {
    flex: 1 0 0;
    color: ${({ theme }) => theme.color.grey700};
    font-family: 'AcidGrotesk-Medium';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.1px;
  }

  > div {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.color.grey200}`};
  }
`;

export const ButtonStyled = styled.button<ButtonStyledProps>`
  display: flex;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: unset;
  background: unset;
  border-bottom: ${({ isActive, theme }) =>
    isActive ? `2px solid ${theme.color.blue700}` : '1px solid transparent'};

  &:hover {
    cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
    p {
      color: ${({ isActive, theme }) =>
        isActive ? theme.color.blue700 : theme.color.blue600};
    }
  }

  p {
    color: ${({ isActive, theme }) =>
      isActive ? theme.color.blue700 : theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
`;

export const SearchStyled = styled.div`
  display: flex;
  height: 84px;
  padding: 20px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;

  > div {
    width: 320px;
  }

  .dropdown {
    margin-left: 20px;
    width: 260px;

    h2 {
      margin-right: 0;
    }
  }
`;

export const TableStyled = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  > table {
    width: 100%;
    overflow: scroll;
    thead {
      th {
        border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
        background: ${({ theme }) => theme.color.grey50};
        padding: 12px 24px;
        text-align: left;
        color: ${({ theme }) => theme.color.grey600};
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.07px;
      }

      .actionsColumn {
        width: 200px;
      }
    }
  }
`;
