import styled from 'styled-components';

export const Wrapper = styled.article`
  padding: 32px;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: 640px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  border-top: 0;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);

  > div {
    > h1 {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 33.6px;
      letter-spacing: 0.06px;
      text-transform: capitalize;
      margin-bottom: 8px;
    }
    > h2 {
      color: ${({ theme }) => theme.color.grey800};
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 25.2px;
      letter-spacing: 0.09px;
    }
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.grey200};
  margin: 24px 0;
`;

export const FieldsSection = styled.div`
  height: 100%;
  display: grid;
  gap: 24px;
  grid-template-rows: max-content;

  > div {
    max-width: 464px;
  }
`;

export const ButtonSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > button {
    width: fit-content;
    min-width: 150px;
  }
`;
