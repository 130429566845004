import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PatientsState } from './models';
import { fetchPatients } from './ActionCreators';
import { PatientResponseDto } from '@docbay/schemas';

const initialState: PatientsState = {
  isLoading: false,
  error: '',
  patients: null,
  total: 0,
  page: 0,
  totalPages: 0,
};

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setPatients: (
      state,
      actions: PayloadAction<PatientResponseDto[] | null>,
    ) => {
      state.patients = actions.payload;
    },
  },
  extraReducers: (builder) => {
    //Fetch patients
    builder.addCase(fetchPatients.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPatients.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchPatients.fulfilled, (state, action) => {
      state.patients = action.payload.entities;
      state.total = action.payload.total!;
      state.page = action.payload.page!;
      state.totalPages = action.payload.totalPages!;
      state.isLoading = false;
    });
  },
});

export const { setPatients } = patientsSlice.actions;

export default patientsSlice.reducer;
