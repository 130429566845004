import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderStyled } from './styles';

const Header: FC = () => {
  const { t } = useTranslation();
  return (
    <HeaderStyled>
      <h1>{t('Databases')}</h1>
    </HeaderStyled>
  );
};

export default Header;
