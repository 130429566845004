import styled from 'styled-components';
import { ButtonStyledProps } from 'features/feature-users-managment/components/Main/modules';

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const TabsSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.color.grey200}`};
  }
`;

export const TabsSectionTopStyled = styled.section`
  display: flex;
  justify-content: space-between;

  > h1 {
    flex: 1 0 0;
    color: ${({ theme }) => theme.color.grey700};
    font-family: 'AcidGrotesk-Medium';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.1px;
  }
`;

export const TabsSectionTopButtonStyled = styled.div`
  min-width: 163px;
  height: 48px;
`;

export const ButtonStyled = styled.button<ButtonStyledProps>`
  display: flex;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: unset;
  background: unset;
  border-bottom: ${({ isActive, theme }) =>
    isActive ? `2px solid ${theme.color.blue700}` : '1px solid transparent'};

  &:hover {
    cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
    p {
      color: ${({ isActive, theme }) =>
        isActive ? theme.color.blue700 : theme.color.blue600};
    }
  }

  p {
    color: ${({ isActive, theme }) =>
      isActive ? theme.color.blue700 : theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
`;
