import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { CountriesActions } from 'application/constants/actions';

import { CountriesAPI } from 'integration/api/countries';
import { CountryCreationDto } from '@docbay/schemas';

export const getSupportedCountries = createAsyncThunk(
  CountriesActions.getSupportedCountries,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await CountriesAPI.getSupportedCountries();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getAllCountries = createAsyncThunk(
  CountriesActions.getAllCountries,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await CountriesAPI.getAllCountries();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const addToSupportedCountries = createAsyncThunk(
  CountriesActions.addToSupportedCountries,
  async (params: CountryCreationDto, { rejectWithValue }) => {
    try {
      const { data } = await CountriesAPI.addToSupportedCountries(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteSupportedCountry = createAsyncThunk(
  CountriesActions.deleteSupportedCountry,
  async (isoCode: string, { rejectWithValue }) => {
    try {
      const { data } = await CountriesAPI.deleteSupportedCountry(isoCode);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
