import { createSlice } from '@reduxjs/toolkit';
import { resetPassword } from './ActionCreators';

const initialState = {
  isLoading: false,
  error: '',
};

const userResetPasswordSlice = createSlice({
  name: 'internalUsers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //reset password
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default userResetPasswordSlice.reducer;
