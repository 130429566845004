import React, { FC, useEffect, useState } from 'react';
import { Loader } from 'common/components';
import {
  TableStyled,
  SearchStyled,
} from 'features/feature-users-managment/components/Main/styles';
import { Input } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setPatients } from 'application/store/reducers/Patients/PatientsSlice';
import { fetchPatients } from 'application/store/reducers/Patients/ActionCreators';
import PatientItem from 'features/feature-users-managment/components/PatientItem';
import Pagination from 'common/components/Pagination';
import { useDebounce } from 'common/hooks/useDebounce';
import { Wrapper } from 'features/feature-users-managment/components/PatientsList/styles';
import NoDataElement from 'features/feature-users-managment/components/NoDataElement';

const defaultLimit = 10;

const PatientsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [patientsInputValue, setPatientsInputValue] = useState('');
  const debouncedInput = useDebounce(patientsInputValue, 500);
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, patients, totalPages } = useAppSelector(
    (state) => state.patients,
  );
  const { isLoading: isResetPassword } = useAppSelector(
    (state) => state.userResetPassword,
  );

  const getCurrentData = () => {
    dispatch(
      fetchPatients({
        ...(debouncedInput.length > 0 ? { search: debouncedInput } : {}),
        limit: defaultLimit,
        page: currentPage,
      }),
    );
  };

  const handlePatientsSearch = (value: string) => {
    setPatientsInputValue(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    getCurrentData();
  }, [currentPage, debouncedInput]);

  const clearSearch = () => {
    handlePatientsSearch('');
  };

  useEffect(() => {
    return () => {
      dispatch(setPatients(null));
    };
  }, []);

  return (
    <Wrapper>
      {(isLoading || isResetPassword) && <Loader />}
      <SearchStyled>
        <Input
          id={'patientName'}
          type="search"
          value={patientsInputValue}
          onClear={clearSearch}
          onChange={(e) => handlePatientsSearch(e.target.value)}
          placeholder={t('users_managment.search_by_name_placeholder')}
        />
      </SearchStyled>
      <TableStyled>
        <table>
          <thead>
            <tr>
              <th>
                <span>{t('users_managment.lists.patient_name_title')}</span>
              </th>
              <th className="actionsColumn">
                <span>{t('users_managment.lists.actions_title')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {patients &&
              patients.map((patient) => (
                <PatientItem key={patient.id} patient={patient} />
              ))}
            {!patients?.length && <NoDataElement />}
          </tbody>
        </table>
      </TableStyled>
      {patients && patients.length ? (
        <Pagination
          pages={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          itemCount={patients.length || 0}
        />
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default PatientsList;
