import * as React from 'react';
import { FC, ReactComponentElement } from 'react';
import { Header } from './Header';

type Props = {
  children: ReactComponentElement<any>;
};

export const WithHeader: FC<Props> = ({ children }: Props) => (
  <section>
    <Header />
    {children}
  </section>
);
