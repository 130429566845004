import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton, PrimaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { ButtonWrapper, MainText, StyledModal } from './styles';
import { Modal } from '@mui/material';
import { AddCountryModalProps } from './models';
import Dropdown from 'common/components/Dropdown';
import { getAllCountries } from 'application/store/reducers/Countries/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import { Option } from 'common/components/Dropdown/models';

const AddSupportedCountryModal: FC<AddCountryModalProps> = ({
  isOpen,
  onClose,
  onAdd,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();
  const [selectedCountryCode, setSelectedCountryCode] = useState<Option | null>(
    null,
  );
  const { allCountries } = useAppSelector((state) => state.countries);

  useEffect(() => {
    !allCountries.length && dispatch(getAllCountries());
  }, [allCountries]);

  const handleCancelAddCountry = () => {
    setSelectedCountryCode(null);
    onClose && onClose();
  };

  const handleAddCountry = () => {
    onAdd && onAdd(selectedCountryCode?.value! as string);
    handleCancelAddCountry();
  };

  const countriesOptions = useMemo(() => {
    const formatted = allCountries?.map((item) => {
      return { label: item[localizeNameKey]!, value: item.id };
    });

    return formatted;
  }, [allCountries]);

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={handleCancelAddCountry} />
        <div>
          <MainText>{t('databases_feature.add_geographies')}</MainText>
          <Dropdown
            id={'country'}
            placeholder={t('databases_feature.add_geographies_placeholder')}
            value={(selectedCountryCode?.value as string) || ''}
            label={t('databases_feature.add_geographies_title')}
            onChange={(value) => {
              setSelectedCountryCode(value as Option);
            }}
            options={countriesOptions}
          />
          <ButtonWrapper>
            <SecondaryButton onClick={handleCancelAddCountry}>
              {t('global.cancel')}
            </SecondaryButton>
            <PrimaryButton
              type="button"
              disabled={!selectedCountryCode}
              onClick={handleAddCountry}
            >
              {t('global.save')}
            </PrimaryButton>
          </ButtonWrapper>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default AddSupportedCountryModal;
