import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'application/assets/add.svg';
import { Input, Loader, PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useDebounce } from 'common/hooks/useDebounce';
import {
  addInsurances,
  deleteInsurances,
  editInsurances,
  fetchInsurances,
} from 'application/store/reducers/Insurances/ActionCreators';
import Table from '../Table';
import { FilterSectionStyled, Wrapper } from './styles';
import DeleteModal from 'features/feature-database/components/DeleteModal';
import SuccessModal from 'features/feature-database/components/SuccessModal';
import AddInsuranceModal from 'features/feature-database/components/AddInsuranceModal';
import { InsuranceCreationDto, InsuranceUpdateDto } from '@docbay/schemas';
import { UseFormReset } from 'react-hook-form';
import {
  setCreated,
  setDeleted,
  setEdited,
} from 'application/store/reducers/Insurances/InsurancesSlice';

const defaultLimit = 10;

const InsuranceTable: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { insurances, isLoading, totalPages, created, deleted, edited } =
    useAppSelector((state) => state.insurances);
  const [searchItem, setSearchItem] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [onRemove, setOnRemove] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState<string | null>(null);
  const searchResult = useDebounce(searchItem, 500);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [insuranceToUpdate, setInsuranceToUpdate] =
    useState<InsuranceUpdateDto | null>(null);
  // Get insurance id separately as we don't have it in dto
  const [insuranceToUpdateId, setInsuranceToUpdateId] = useState<string | null>(
    null,
  );

  const columns = useMemo(() => {
    return [
      t('databases_feature.insurances.private_insurances'),
      t('databases_feature.actions'),
    ];
  }, [t]);

  const data = useMemo(() => {
    return insurances.map((item) => ({
      name: item.name,
      id: item.id,
      code: item.code,
    }));
  }, [insurances]);

  const getCurrentData = (page: number) => {
    dispatch(
      fetchInsurances({
        searchItem: searchResult,
        page: page,
        limit: defaultLimit,
      }),
    );

    setCurrentPage(page);
  };

  useEffect(() => {
    getCurrentData(1);
  }, []);

  useEffect(() => {
    getCurrentData(1);
  }, [searchResult]);

  const handleDelete = () => {
    deletedItemId && dispatch(deleteInsurances(deletedItemId));
  };

  const handleRemoveClose = () => {
    setOnRemove(false);
  };

  const handleCloseSuccessDeleteModal = () => {
    setOnRemove(false);
    dispatch(setDeleted(false));

    dispatch(
      fetchInsurances({
        searchItem: searchResult,
        page: 1,
        limit: defaultLimit,
      }),
    );
  };

  useEffect(() => {
    (created || edited) &&
      dispatch(
        fetchInsurances({
          searchItem: searchResult,
          page: 1,
          limit: defaultLimit,
        }),
      );
  }, [created, edited]);

  const handleAddInsurance = (
    data: InsuranceCreationDto,
    reset: UseFormReset<any>,
  ) => {
    dispatch(addInsurances(data)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        reset();
      }
    });
  };

  const handleEditInsurance = (
    data: InsuranceUpdateDto,
    reset: UseFormReset<any>,
  ) => {
    if (insuranceToUpdateId) {
      dispatch(editInsurances({ id: insuranceToUpdateId, data })).then(
        (data) => {
          if (data.meta.requestStatus === 'fulfilled') {
            reset();
          }
        },
      );
    } else {
      console.log('Cant get id for editing insurance');
    }
  };

  const handleCloseSuccessAddModal = () => {
    dispatch(setCreated(false));
    setIsOpenAddModal(false);
  };

  const handleCloseSuccessEditModal = () => {
    dispatch(setEdited(false));
    setIsOpenEditModal(false);
  };

  const handleOpenEditInsuranceModal = (
    id: string,
    isOpen: boolean,
    data: InsuranceUpdateDto,
  ) => {
    setIsOpenEditModal(isOpen);
    setInsuranceToUpdate(data);
    setInsuranceToUpdateId(id);
  };

  return (
    <>
      <Wrapper>
        {isLoading && <Loader />}
        <FilterSectionStyled>
          <h1>{t('databases_feature.insurances.private_insurances')}</h1>
          <div>
            <Input
              id={'search'}
              type="search"
              placeholder={
                t(
                  'databases_feature.insurances.insurances_search_placeholder',
                ) || ''
              }
              value={searchItem}
              onChange={(e) => setSearchItem(e.target.value)}
              onClear={() => setSearchItem('')}
            />
            <PrimaryButton onClick={() => setIsOpenAddModal(true)}>
              <AddIcon />
              {t('databases_feature.add_new')}
            </PrimaryButton>
          </div>
        </FilterSectionStyled>
        <Table
          data={data}
          columns={columns}
          currentPage={currentPage}
          totalPages={totalPages || 0}
          setCurrentPage={(page) => getCurrentData(page)}
          handleDelete={(id) => {
            setDeletedItemId(id);
            setOnRemove(true);
          }}
          handleEditModalOpen={handleOpenEditInsuranceModal}
        />
      </Wrapper>
      <AddInsuranceModal
        isOpen={isOpenAddModal}
        onClose={() => setIsOpenAddModal(false)}
        onAdd={handleAddInsurance}
        isEdit={false}
      />
      <AddInsuranceModal
        isOpen={isOpenEditModal && !!insuranceToUpdate}
        onClose={() => setIsOpenEditModal(false)}
        onEdit={handleEditInsurance}
        isEdit={true}
        initialData={insuranceToUpdate}
        insuranceId={insuranceToUpdateId}
      />
      <DeleteModal
        isOpen={onRemove}
        text={`${t('delete_modal.titlePart1_insurance')} ${t(
          'delete_modal.insurance',
        )}?`}
        onClose={handleRemoveClose}
        onSubmit={handleDelete}
      />
      {deleted && (
        <SuccessModal
          onClose={handleCloseSuccessDeleteModal}
          typeName={`${t('modal_success.title_insurance')} ${t(
            'modal_success.title_part2_deleted_insurance',
          )}`}
        />
      )}
      {created && (
        <SuccessModal
          onClose={handleCloseSuccessAddModal}
          typeName={`${t('modal_success.title_specialization')} ${t(
            'modal_success.title_part2',
          )}`}
        />
      )}
      {edited && (
        <SuccessModal
          onClose={handleCloseSuccessEditModal}
          typeName={`${t('modal_success.title_specialization')} ${t(
            'modal_success.title_part2',
          )}`}
        />
      )}
    </>
  );
};

export default InsuranceTable;
