import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { LoginDataT } from './types';
import { useLoginForm } from './hooks/useLoginForm';
import { Input, PrimaryButton } from 'common/components';
import {
  ForgotPasswordWrapper,
  FormGroupStyled,
  LoginFormWrapper,
  StyledBlueLink,
  StyledError,
  StyledForm,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { PathNames } from 'application/routes';
import { setAuthError } from 'application/store/reducers/Users/UsersSlice';
import { usersLogin } from 'application/store/reducers/Users/ActionCreators';
import Header from './components/Header';
import { login } from 'common/auth/authService';
import { getToken } from 'application/sessionStorage/auth';

const LoginPage: FC = () => {
  const token = getToken();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error } = useAppSelector((state) => state.users);
  const { register, errors, handleSubmit, watch, setError, clearErrors } =
    useLoginForm({
      emailError: t('errors.emailFormat'),
      requiredError: t('errors.required'),
    });
  useEffect(() => {
    if (error) {
      setError('email', { message: ' ' });
      setError('password', { message: ' ' });
    } else {
      clearErrors();
    }
  }, [error]);

  useEffect(() => {
    if (token) {
      return navigate('/');
    }
  }, [token]);

  useEffect(() => {
    if (error) {
      dispatch(setAuthError(false));
    }
  }, [watch('email'), watch('password')]);
  const onSubmit = async (data: LoginDataT) => {
    const response = await dispatch(
      usersLogin({
        email: data.email,
        password: data.password,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      login();
    }
  };
  return (
    <LoginFormWrapper>
      {!token && (
        <>
          <Header />
          {error && (
            <StyledError variant="body1">
              {t('auth.log_in.incorrect_credentials')}
            </StyledError>
          )}
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <FormGroupStyled>
              <Input
                id="email"
                label={t('auth.email') || ''}
                type="email"
                placeholder={t('auth.log_in.email_placeholder') || ''}
                register={register}
                errorMessage={errors.email?.message}
              />
              <Input
                id="password"
                label={t('auth.log_in.password_label') || ''}
                type="password"
                placeholder={t('auth.log_in.password_label') || ''}
                errorMessage={errors.password?.message}
                register={register}
              />
            </FormGroupStyled>
            <ForgotPasswordWrapper>
              <StyledBlueLink to={PathNames.forgotPassword}>
                {t('auth.log_in.forgot_password')}
              </StyledBlueLink>
            </ForgotPasswordWrapper>
            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              {t('auth.log_in.log_in_button')}
            </PrimaryButton>
          </StyledForm>
        </>
      )}
    </LoginFormWrapper>
  );
};

export default LoginPage;
