import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { styledTheme } from 'application/theme';
import { Link } from 'react-router-dom';

export const LoginFormWrapper = styled(Box)`
  padding: 32px 0 0;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 464px;
`;

export const StyledForm = styled('form')`
  padding: 0 22px;
  width: 100%;
`;

export const FormGroupStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 12px;
`;

export const StyledBlueLink = styled(Link)`
  color: ${styledTheme.color.blue800};
  font: 700 16px/22.4px 'Mulish', sans-serif;
  text-decoration: none;
  letter-spacing: 0.08px;
  cursor: pointer;
`;

export const StyledTypography = styled(Typography)`
  color: ${styledTheme.color.grey800};
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;

export const StyledTypographySmall = styled(StyledTypography)`
  color: ${styledTheme.color.grey900};
  line-height: 19.6px;
`;

export const StyledError = styled(StyledTypography)`
  color: ${styledTheme.color.error2};
  line-height: 19.6px;
  text-align: center;
  margin: 0 0 32px 0;
`;

export const ForgotPasswordWrapper = styled(Box)`
  display: flex;
  justify-content: end;
  margin-bottom: 28px;
`;
