import axiosInstance from 'integration/api/axiosInstance';
import {
  AdminAppProfessionalResponseDto,
  CommonEntitiesListResponse,
  LoginAsDto,
  UserLoginResDto,
} from '@docbay/schemas';
import { fetchProfessionalsProps } from 'common/types/professionals';

export const ProfessionalsAPI = {
  fetchProfessionals: ({
    searchItem,
    page,
    limit,
    userTypes,
  }: fetchProfessionalsProps) => {
    const params = {
      includeDeleted: 'false',
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(searchItem ? { search: searchItem } : {}),
      ...(userTypes ? { userTypes: userTypes } : {}),
    };

    return axiosInstance.get<
      CommonEntitiesListResponse<AdminAppProfessionalResponseDto>
    >('/saas/users-management/professionals', {
      params,
    });
  },
  loginAsUser: (data: LoginAsDto) => {
    return axiosInstance.post<UserLoginResDto>('/saas/login-as', data);
  },
  deleteClinicOwner: (id: string) =>
    axiosInstance.delete(`/saas/users-management/clinic-profile/${id}`),
  deleteDoctor: (doctorId: string) =>
    axiosInstance.delete(`/saas/users-management/doctor-profile/${doctorId}`),
  deleteAdmin: (adminId: string) =>
    axiosInstance.delete(`/saas/users-management/admin-profile/${adminId}`),
  deleteSecretary: (secretaryId: string) =>
    axiosInstance.delete(
      `/saas/users-management/secretary-profile/${secretaryId}`,
    ),
};
