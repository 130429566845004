import React, { useState } from 'react';
import { TableStyled, TableWrapper } from './styles';
import { useAppSelector } from 'common/hooks/redux';
import { useTranslation } from 'react-i18next';
import NoDataElement from '../NoDataElement';
import MetricsTableRow from '../MetricsTableRow';
import AddMetricsModal from '../AddMetrics';

const SpecializationMetricsTable = () => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const { metrics } = useAppSelector((state) => state.specializationMetrics);
  const { supportedLanguages } = useAppSelector((state) => state.languages);

  const langEn = supportedLanguages.find((item) => item.isoCode === 'en');

  const restLangs = supportedLanguages.filter((item) => item.isoCode !== 'en');

  const restMetricsNames = restLangs?.map((item) => item.isoCode);

  return (
    <TableWrapper>
      <TableStyled>
        <table>
          <thead>
            <tr>
              <th>{`${t('specialization_metrics.metric_name')} ${
                langEn?.nameNative || ''
              } (${restMetricsNames.join(' / ')})`}</th>
              <th>{`${t('specialization_metrics.unit_of_measurement')} ${
                langEn?.nameNative || ''
              } (${restMetricsNames.join(' / ')})`}</th>
              <th>{t('specialization_metrics.specialization')}</th>
              <th>{t('specialization_metrics.actions_title')}</th>
            </tr>
          </thead>
          <tbody>
            {metrics?.length ? (
              metrics.map((metric) => (
                <MetricsTableRow
                  key={metric.id}
                  row={metric}
                  onEdit={() => setShowEditModal(true)}
                />
              ))
            ) : (
              <NoDataElement />
            )}
          </tbody>
        </table>
      </TableStyled>
      {showEditModal && (
        <AddMetricsModal
          opened={showEditModal}
          onClose={() => setShowEditModal(false)}
          title={t('specialization_metrics.edit_metric')}
          isEdit
        />
      )}
    </TableWrapper>
  );
};

export default SpecializationMetricsTable;
