import * as React from 'react';
import { FC } from 'react';
import Box from '@mui/material/Box';

export const Header: FC = () => {
  return (
    <header>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& > *': {
            m: 1,
          },
        }}
      ></Box>
    </header>
  );
};
